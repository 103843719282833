import http from '../../app-http'
import { debounce } from "lodash";


let __timer;

const getDefaultFilter= () => {
  return {
    dateFrom: null, 
    dateTill: null, 
    keyword: "",
    users: [],
    status: null
  };
};

const getDefaultState = () => {
  return {
    selectedReminder: [],

    loading: false,
    processing: false,
    deleting: null,
    editedReminder: null,
    activeReminders: [],
    remindersByContact:{},
    remindersByProcess:{},
    version: 0,
    list: {
      total: 0,
      items: [],
      perPage: 50,
      page: 1
    },

    details: {
  
    },

    persistent:{ 
      view: 'list',
      filter: getDefaultFilter(),
      orderBy: {
        field: 'Number',
        asc: false
      },
      columnsOrder: [],
      pinnedColumns: [],
      columnsWidth: {

      },
    }
  }
};

export const state = getDefaultState();

export const mutations = {
  SET_COLUMNS_WIDTH(state, newValue) {
    state.persistent.columnsWidth = newValue;
  },
  SET_COLUMNS_ORDER(state, newValue) {
    state.persistent.columnsOrder = newValue;
  },
  SET_PINNED_COLUMNS(state, newValue) {
    state.persistent.pinnedColumns = newValue;
  },
  SET_ACTIVE_REMINDER(state, newValue) {
    state.activeReminders = newValue;
  },
  INCREMENT_VERSION(state) {
    state.version +=1 ;
  },
  SET_FILTER(state, { data }) {
    state.persistent.filter = data;
  },
  RESET_FILTER(state) {
    state.persistent.filter = getDefaultFilter();
  },
  SET_PAGE(state, newValue) {
    state.list.page = newValue;
  },
  SET_ORDER_BY(state, newValue) {
    state.persistent.orderBy = newValue;
  },
  SET_LIST(state, { total, items }) {
    state.list.total = total;
    state.list.items = items;
  },
  SET_PERSISTENT(state, { data }) {
    state.persistent = data;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  SET_REMINDERS_BY_CONTACT(state, { contactId, reminders}) {
    const o = {...this.remindersByContact };
    o[contactId] = reminders;
    state.remindersByContact = o;
  },
  SET_REMINDERS_BY_PROCESS(state, { processId, reminders}) {
    const o = {...this.remindersByProcess };
    o[processId] = reminders;
    state.remindersByProcess = o;
  },
  SET_LOADING(state, newValue) {
    state.loading = newValue;
  },
  SET_PROCESSING(state, newValue) {
    state.processing = newValue;
  },
  SET_DELETING(state, newValue) {
    state.deleting = newValue;
  },
  SET_EDITED_REMINDER(state, { reminder }) {
    state.editedReminder = reminder;
  },
  RESET_EDITED_REMINDER(state) {
    state.editedReminder = null;
  },
  SET_DETAILS(state, {id, value}) {
    const o = {...state.details};
    o[id] = value;
    state.details = o;
  },
  SELECT_REMINDER(state, { reminder}) {
    state.selectedReminder = [...state.selectedReminder, reminder];
  },
  UNSELECT_REMINDER(state) {
    const o = [...state.selectedReminder];
    o.pop();
    state.selectedReminder = o;
  }
}

export const getters = {
  selectedReminder(state){
    return state.selectedReminder;
  },
  columnsWidth (state) {
    return state.persistent.columnsWidth || [];
  },
  pinnedColumns (state) {
    return state.persistent.pinnedColumns || [];
  },
  columnsOrder (state) {
    return state.persistent.columnsOrder || [];
  },
  activeReminders (state) {
    return state.activeReminders || [];
  },
  filterEnabled (state) {
    const def = getDefaultFilter();
    const act = {...state.persistent.filter};
    const defjson =  JSON.stringify(def);
    const actjson =  JSON.stringify(act);
    return defjson !== actjson;
  },
  version (state) {
    return state.version;
  },
  filter (state) {
    return { ...state.persistent.filter };
  },
  perPage (state) {
    return state.list.perPage;
  },
  total (state) {
    return state.list.total;
  },
  page (state) {
    return state.list.page;
  },
  orderBy (state) {
    return state.persistent.orderBy;
  },
  list (state) {
    return state.list;
  },
  items (state) {
    return state.list.items;
  },
  remindersByContact (state) {
    return state.remindersByContact;
  },
  remindersByProcess (state) {
    return state.remindersByProcess;
  },
  editedReminder (state) {
    return state.editedReminder;
  },
  loading(state) {
    return state.loading;
  },
  processing(state) {
    return state.processing;
  },
  deleting(state) {
    return state.deleting;
  },
  detailsItems: (state) => (id) => {
    return state.details[id];
  }
}

export const actions = {
  setColumnsWidth({dispatch, commit}, newValue) {
    commit('SET_COLUMNS_WIDTH', newValue)
    dispatch('debounceSavePersistent');
  },
  setPinnedColumns({dispatch, commit}, newValue) {
    commit('SET_PINNED_COLUMNS', newValue)
    dispatch('debounceSavePersistent');
  },
  setColumnsOrders({dispatch, commit}, newValue) {
    commit('SET_COLUMNS_ORDER', newValue)
    dispatch('debounceSavePersistent');
  },
  async initTimer({ dispatch }) {
    dispatch('loadActiveReminders');
  },
  async loadActiveReminders({ dispatch, commit }) {
   
    const response = await http.get(`reminders/active`);
    
    commit('SET_ACTIVE_REMINDER', response.data.map(x => {
      return {
        ...x.data,
        ...{...x, data: undefined }
      };
    }));

    if(__timer){
      window.clearTimeout(__timer);
    }

    __timer = window.setTimeout(() => {
      dispatch('loadActiveReminders');
    }, 30000);
  },
  async setPage({ dispatch, commit }, newValue ) {
    commit('SET_PAGE', newValue);
    await dispatch('load');
  },
  async setOrderBy({ dispatch, commit }, newValue ) {
    commit('SET_ORDER_BY', newValue);
    await dispatch('debounceSavePersistent');
    await dispatch('debounceLoad');
  },
  async loadPersistent({ commit }) {
    const response = await http.get(`user-storage/reminders`);
    if(response.data) {
      const data = response.data || getDefaultState();
      commit('SET_PERSISTENT', { data });
    } 
  },
  debounceSavePersistent: debounce(async ({ dispatch }) =>  {
    await dispatch('savePersistent');
  }, 1000),
  debounceLoad: debounce(async ({ dispatch }) =>  {
    await dispatch('load');
  }, 1000),
  async savePersistent ({ state }) {
    await http.post(`user-storage/reminders`, state.persistent );
  },
  async createReminder({ dispatch, commit }, { dto } = {}) {
    commit('SET_PROCESSING', true);
    const response = await http.post(`reminders`, dto);
    commit('SET_PROCESSING', false);

    await dispatch('onReminderChanged' , { id: response.data.id, contactId: (response.data.data.contact || {}).id, processId: (response.data.data.process || {}).id });
    
    const respRes = response.data;
    const m = {...respRes.data, ...{...respRes, data: null}};
    return m;
  },
  async updateReminder({ dispatch, commit }, { id, dto } = {}) {
    commit('SET_PROCESSING', true);
    const response = await http.put(`reminders/${id}`, dto);
    commit('SET_PROCESSING', false);
    await dispatch('onReminderChanged' , { id });
    
    return {...response.data.data, ...{...response.data, data: null}};
  },
  async deleteReminder({ dispatch, commit }, { id } = {}) {
    commit('SET_PROCESSING', id);
    await http.delete(`reminders/${id}`);
    commit('SET_PROCESSING', null);
    await dispatch('onReminderChanged' , { id });
  },
  async cancelReminder({ dispatch, commit }, { id } = {}) {
    commit('SET_PROCESSING', id);
    await http.put(`reminders/${id}/cancelled`);
    commit('SET_PROCESSING', null);

    await dispatch('onReminderChanged' , { id });
  },
  async snoozeReminder({ dispatch, commit }, { id, till } = {}) {
    commit('SET_PROCESSING', id);
    await http.put(`reminders/${id}/snooze`, {till});
    commit('SET_PROCESSING', null);
    await dispatch('onReminderChanged' , { id });
  },
  async dismissReminder({ dispatch, commit }, { id, till } = {}) {
    commit('SET_PROCESSING', id);
    await http.put(`reminders/${id}/dismiss`, {till});
    commit('SET_PROCESSING', null);
    await dispatch('onReminderChanged' , { id });
  },
  async load({ commit, state }, { resetPage, silent } = {}) {
    if(resetPage) {
      commit('SET_PAGE', 1);
    }

    if(!silent)
      commit('SET_LOADING', true);
    
    let req = {
      skip: (state.list.page - 1) * state.list.perPage,
      take: state.list.perPage,
      orderByField: state.persistent.orderBy.field,
      orderByAsc: state.persistent.orderBy.asc,
    };

    req.users = state.persistent.filter.users || [];
    req.status = state.persistent.filter.status || null;
    req.keyword = state.persistent.filter.keyword;
    req.dateFrom = state.persistent.filter.dateFrom;
    req.dateTill = state.persistent.filter.dateTill;

    const response = await http.post(`reminders/search`, req);

    if(!silent)
      commit('SET_LOADING', false);

    commit('SET_LIST', { total: response.data.total, items: response.data.items.map(x => {
        return {
          ...x.data,
          ...{...x, data: undefined }
        };
      }) 
    });
  },
  async onReminderChanged({ dispatch, state }, { id, contactId, processId }  = {}) {
    await dispatch('load');
    await dispatch('loadActiveReminders');
    if(state.details[id]) {
      await dispatch('loadReminder' , { id });
    }

    if(contactId) {
      if(state.remindersByContact[contactId]){
        await dispatch('loadContactReminders' , { contactId });
      }
    }

    if(processId) {
      if(state.remindersByProcess[processId]){
        await dispatch('loadProcessReminders' , { processId });
      }
    }
    const remindersByContactKeys = Object.keys(state.remindersByContact);
    remindersByContactKeys.forEach(key => {
      if(state.remindersByContact[key].find(x => x.id === id)){
        dispatch('loadContactReminders' , { contactId: key });
      }
    });
    const remindersByProcessKeys = Object.keys(state.remindersByProcess);
    remindersByProcessKeys.forEach(key => {
      if(state.remindersByProcess[key].find(x => x.id === id)){
        dispatch('loadProcessReminders' , { processId: key });
      }
    });
  },
  async loadContactReminders({ commit }, { contactId, status, orderByField, orderByAsc }) {
    commit('SET_LOADING', true);
    const response = await http.post(`reminders/search`, { contactId, status, orderByField, orderByAsc } );
    commit('SET_LOADING', false);
    commit('SET_REMINDERS_BY_CONTACT', { contactId, reminders: response.data.items.map(x => {
        return {
          ...x.data,
          ...{...x, data: undefined }
        };
      }) 
    });
  },
  async loadProcessReminders({ commit,  }, { processId, status, orderByField, orderByAsc }) {
    commit('SET_LOADING', true);
    const response = await http.post(`reminders/search`, { processId, status, orderByField, orderByAsc } );
    commit('SET_LOADING', false);
    commit('SET_REMINDERS_BY_PROCESS', { processId, reminders: response.data.items.map(x => {
        return {
          ...x.data,
          ...{...x, data: undefined }
        };
      }) 
    });
  },
  loadReminder({ commit }, { id, silent } = {}) {
    return new Promise( (resolve, reject) => {
      if(!silent)
        commit('SET_LOADING', true);
        http.get(`reminders/${id}`).then((response)=>{
        if(!silent)
          {
            commit('SET_LOADING', false);
          }
        commit('SET_DETAILS',{id, value: {...response.data.data, ...{...response.data, data: null}}});
        resolve(response.data);
      }).catch((error) => {
        if(!silent)
          commit('SET_LOADING', false);
        reject(error);
      });
    });
  },
  async setFilter({ dispatch, commit }, { data } ) {
    await commit('SET_FILTER', {data});
    await dispatch('debounceSavePersistent');
    await dispatch('debounceLoad');
  }, 
  async resetFilter({ dispatch, commit }) {
    await commit('RESET_FILTER');
    await dispatch('debounceSavePersistent');
    await dispatch('debounceLoad');
  }, 
}
