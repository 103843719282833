<script>

export default {
  components: {  
   
  },
  props: {
    fields: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      required: false
    },
    saveEnabled: {
      type: Boolean,
      required: false
    },
    hasLinkedColumn: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    requiredFields() {
      let o = {};
      this.fields.filter(x => x.required && !x.hidden).forEach(x => o[x.id] = this.row[x.id] ? false : true);
      return o;
    },
    allowSave() {
      const requiredFields = this.fields.filter(x => x.required && !x.hidden);
      let res = true;
      if(requiredFields.length > 0) {
        requiredFields.forEach(x => {
          if(!this.row[x.id]) {
          res = false;
          }
        });
      } else {
        res = false;
        this.fields.filter(x =>!x.hidden).forEach(x => {
          if(this.row[x.id]) {
            res = true;
          }
        });
      }
      
      return res;
    },
    
  },
  data() {
    return {
      highlightRequiredFields: false,
      row: {

      }
    }
  },
  created() {
    this.populate(this.value);
  },
  watch: {
    value (newValue, oldValue) {
      if(newValue !== oldValue) {
        this.populate(newValue);
      }
    }
  },
  methods: {
    populate(source) {
      const row = {};
      this.fields.filter(x => !x.hidden && x.type !== 'DirectoryRelatedField').forEach(x =>{
        row[x.id] = source ? source[x.id] : null;
      });
      this.row = row;
    },
    onSave() {
      this.highlightRequiredFields = false;
      if(this.allowSave) {
        const o = JSON.parse(JSON.stringify(this.row));
      
        this.populate();
        this.$emit('save', o);
      } else {
        this.highlightRequiredFields = true;
      }
    },
    containerStyle(field) {
      return field.type === 'Number' ? {
        'min-width': '50px'
      } : {
        'min-width': '100px'
      };
    }
  }
};
</script>

<template>
  <tbody class="">
    <tr>
      <td style="max-width:25px; width: 25px; " class="text-center">
        
      </td>
      <td v-if="hasLinkedColumn"></td>
      <td  style="max-width:40px; width: 40px; " class="text-center">
        <button 
          type="button"
          v-if="saveEnabled"
          class="btn btn-secondary btn-sm" 
          
          @click="onSave">
          <font-awesome-icon icon="fa-solid fa-plus" />
        </button>
      </td>
      <td v-for="tableField in fields.filter(x => !x.hidden)" :key="tableField.id" :class="{'req': highlightRequiredFields && requiredFields[tableField.id]}">
        <template v-if="tableField.type !== 'DirectoryRelatedField'">
          <div  :style="containerStyle(tableField)">
            <custom-field-edit 
              small
              :def="tableField" 
              :value="row[tableField.id]"
              @update="(v) => row[tableField.id] = v"
            >
            </custom-field-edit> 
          </div>
        </template>
      </td>
      <td></td>
    </tr>
  </tbody>
</template>
