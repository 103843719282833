
const getDefaultState = () => {
    return {
        dataTransformation: []
    };
};
export const state = getDefaultState();

export const mutations = {
    SET_DATA_TRANSFORMATION(state, newValue) {
        state.dataTransformation = newValue;
    }
}

export const getters = {
    dataTransformation(state) {
        return state.dataTransformation;
    }
}

export const actions = {
  
}
