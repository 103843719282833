import http from '../../app-http'
import { parseErrors} from "@/helpers"

const getDefaultState = () => {
  return {
    loading: false,
    processing: false,
    deleting: null,
    items:[],
  
  }
};

export const state = getDefaultState();

export const mutations = {
 
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  SET_ITEMS(state, newValue) {
    state.items = newValue;
  },
  SET_LOADING(state, newValue) {
    state.loading = newValue;
  },
  SET_PROCESSING(state, newValue) {
    state.processing = newValue;
  },
  SET_DELETING(state, newValue) {
    state.deleting = newValue;
  }
}

export const getters = {
  allCustomFields(state) {
    let arr = [];
    state.items.forEach(a => {
      arr = [...arr,...a.fields];
    })
    let rer = {};
    arr.forEach(a => {
      rer[a.id] = a;
    })
    return rer;
  },
  directoriesOnMap(state) {
    return state.items.filter(x => {
      const addressField = x.fields.find(x => x.type === 'Address' && x.showOnMap)
      || x.fields.find(x => x.type === 'Table' && x.tableFields.find(a => a.type === 'Address' && x.showOnMap));
      if(addressField) return true;
      return false;
    });
  },
  items (state) {
    return state.items;
  },
  loading(state) {
    return state.loading;
  },
  processing(state) {
    return state.processing;
  },
  deleting(state) {
    return state.deleting;
  },
}

export const actions = {
   create({ dispatch, commit }, { dto } = {}) {
    return new Promise( (resolve, reject) => {
      commit('SET_PROCESSING', true);
      http.post(`directories`, dto).then(()=>{
        commit('SET_PROCESSING', false);
        dispatch('load');
        resolve();
      }).catch((error) => {
        commit('SET_PROCESSING', false);
        const errors = parseErrors(error);
        reject(errors);
      });
    });
  },
  async update({ dispatch, commit }, { id, dto } = {}) {
    return new Promise( (resolve, reject) => {
      commit('SET_PROCESSING', true);
      http.put(`directories/` + id, dto).then(()=>{
        commit('SET_PROCESSING', false);
        dispatch('load');
        resolve();
      }).catch((error) => {
        commit('SET_PROCESSING', false);
        const errors = parseErrors(error);
        reject(errors);
      });
    });
  },
  delete({ dispatch, commit }, { id } = {}) {
    return new Promise( (resolve, reject) => {
      commit('SET_DELETING', id);
      http.delete(`directories/` + id).then(()=>{
        commit('SET_DELETING', null);
        dispatch('load');
        resolve();
      }).catch((error) => {
        commit('SET_DELETING', null);
        const errors = parseErrors(error);
        reject(errors);
      });
    });
  },
  load({ commit }) {
    return new Promise( (resolve, reject) => {
      commit('SET_LOADING', true);
      http.get(`directories`).then((response)=>{
        commit('SET_LOADING', false);
        commit('SET_ITEMS', response.data);
        resolve(response.data);
      }).catch((error) => {
        commit('SET_LOADING', false);
        const errors = parseErrors(error);
        reject(errors);
      });
    });
  },
  reorder({ dispatch, commit, state }, { list } = {}) {
    return new Promise( (resolve, reject) => {
      const o = list.map(id => state.items.find(x => x.id == id));
      commit('SET_ITEMS', o);
      http.post(`directories/order`, { list }).then(() => {
        dispatch('load');
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  },
}
