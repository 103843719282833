let _stack = [];
window._stack = _stack;

export function pushModal ({modalInstance, forceClose}) {
    const copy = [..._stack];
  
    if(copy.length > 0) {
        const mi = copy[copy.length-1].modalInstance;
        mi.hide();
    }
    _stack.push({ modalInstance: modalInstance, forceClose });
 
}

export function popModal () {

  
    const w = _stack.pop();
    if(w) {
        if(_stack.length > 0) {
            _stack[_stack.length-1].modalInstance.show();
        }
    }
   
    return w;
}

export function forceClose() {
    const copy = [..._stack];
    _stack = [];
    copy.forEach(x => {
        x.forceClose();
    });
   
}