import http from '../../app-http'
import { debounce } from "lodash";

const getDefaultState = () => {
  return {

    loading: false,
    processing: false,
    deleting: null,
    editedProcess: [],
    tags: [],
    
    processesByContact:{},
    list: {
      total: 0,
      items: [],
      perPage: 50,
      page: 1
    },

    customFields:[],

    details: {
  
    },
    noteItems: {

    },
    historyItems: {

    },
    processTypeId: null,
    persistent:{ 
      view: 'list',
      orderBy: {
        field: 'LastActivityDate',
        asc: false
      },
    
      columnsOrder: [],
      pinnedColumns: [],
      columnsWidth: {

      },
    },
    stat: null
  }
};

export const state = getDefaultState();

export const mutations = {
  SET_STATE(state, newValue) {
    state.stat = newValue;
  },
  SET_PROCESS_TYPE(state, {id}) {
    state.processTypeId = id;
  },
  SET_COLUMNS_WIDTH(state, newValue) {
    state.persistent.columnsWidth = newValue;
  },
  SET_COLUMNS_ORDER(state, newValue) {
    state.persistent.columnsOrder = newValue;
  },
  SET_PINNED_COLUMNS(state, newValue) {
    state.persistent.pinnedColumns = newValue;
  },
  SET_CUSTOM_FIELDS(state, newValue) {
    state.customFields = newValue;
  },

  SET_NOTES(state,  { id, notes}) {
    const o  = {...state.noteItems };
    o[id] = notes;
    state.noteItems = o;
  },
  SET_HISTORY(state,  { id, history}) {
    const o  = {...state.historyItems };
    o[id] = history;
    state.historyItems = o;
  },
  SET_PAGE(state, newValue) {
    state.list.page = newValue;
  },
  SET_ORDER_BY(state, newValue) {
    state.persistent.orderBy = newValue;
  },
  SET_PROCESSES_LIST(state, { total, items }) {
    state.list.total = total;
    state.list.items = items;
  },
  SET_VIEW(state, newValue) {
    state.persistent.view = newValue;
  },
  SET_PERSISTENT(state, { data }) {
    state.persistent = data;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  SET_PROCESSES_BY_CONTACT(state, { contactId, processes}) {
    const o = {...this.processesByContact };
    o[contactId] = processes;
    state.processesByContact = o;
  },
  SET_LOADING(state, newValue) {
    state.loading = newValue;
  },
  SET_PROCESSING(state, newValue) {
    state.processing = newValue;
  },
  SET_TAGS(state, newValue) {
    state.tags = newValue;
  },
  SET_DELETING(state, newValue) {
    state.deleting = newValue;
  },
  SET_EDITED_PROCESS(state, { process }) {
    state.editedProcess = [...state.editedProcess, process];
  },
  RESET_EDITED_PROCESS(state) {
    const o = [...state.editedProcess];
    o.pop();
    state.editedProcess = o;
  },
  SET_DETAILS(state, {id, value}) {
    const o = {...state.details};
    o[id] = value;
    state.details = o;
  },
}

export const getters = {
  processTypeId(state) {
    return state.processTypeId;
  },
  stat (state) {
    return state.stat;
  },
  columnsWidth (state) {
    return state.persistent.columnsWidth || [];
  },
  pinnedColumns (state) {
    return state.persistent.pinnedColumns || [];
  },
  columnsOrder (state) {
    return state.persistent.columnsOrder || [];
  },
  historyItems(state) {
    return state.historyItems;
  },
  noteItems(state) {
    return state.noteItems;
  },
  filterEnabled (/*state*/) {
    return false;
  },
  perPage (state) {
    return state.list.perPage;
  },
  total (state) {
    return state.list.total;
  },
  page (state) {
    return state.list.page;
  },
  orderBy (state) {
    return state.persistent.orderBy;
  },
  list (state) {
    return state.list;
  },
  view (state) {
    return state.persistent.view || 'board';
  },
  processesByContact (state) {
    return state.processesByContact;
  },
  editedProcess (state) {
    return state.editedProcess;
  },
  loading(state) {
    return state.loading;
  },
  processing(state) {
    return state.processing;
  },
  deleting(state) {
    return state.deleting;
  },
  detailsItems: (state) => (id) => {
    return state.details[id];
  },
  tags(state) {
    return state.tags;
  },
}

export const actions = {
  setView({dispatch, commit}, newValue) {
    commit('SET_VIEW', newValue);
    dispatch('debounceSavePersistent');
  },
  setColumnsWidth({dispatch, commit}, newValue) {
    commit('SET_COLUMNS_WIDTH', newValue)
    dispatch('debounceSavePersistent');
  },
  setPinnedColumns({dispatch, commit}, newValue) {
    commit('SET_PINNED_COLUMNS', newValue)
    dispatch('debounceSavePersistent');
  },
  setColumnsOrders({dispatch, commit}, newValue) {
    commit('SET_COLUMNS_ORDER', newValue)
    dispatch('debounceSavePersistent');
  },
  createCustomField({ dispatch, commit }, { dto } = {}) {
    return new Promise( (resolve, reject) => {
      commit('SET_PROCESSING', true);
      http.post(`process-custom-fields`, dto).then(()=>{
        commit('SET_PROCESSING', false);
        dispatch('loadCustomFields');
        resolve();
      }).catch((error) => {
        commit('SET_PROCESSING', false);
        reject(error);
      });
    });
  },
  updateCustomField({ dispatch, commit }, { id, dto } = {}) {
    return new Promise( (resolve, reject) => {
      commit('SET_PROCESSING', true);
      http.put(`process-custom-fields/${id}`, dto).then(()=>{
        commit('SET_PROCESSING', false);
        dispatch('loadCustomFields');
        resolve();
      }).catch((error) => {
        commit('SET_PROCESSING', false);
        reject(error);
      });
    });
  },
  deleteCustomField({ dispatch, commit }, { id } = {}) {
    return new Promise( (resolve, reject) => {
      commit('SET_DELETING', id);
      http.delete(`process-custom-fields/${id}`).then(() => {
        commit('SET_DELETING', null);
        dispatch('loadCustomFields');
        resolve();
      }).catch((error) => {
        commit('SET_DELETING', null);
        reject(error);
      });
    });
  },
  restoreCustomField({ dispatch, commit }, { id } = {}) {
    return new Promise( (resolve, reject) => {
      commit('SET_DELETING', id);
      http.post(`process-custom-fields/${id}/restore`).then(() => {
        commit('SET_DELETING', null);
        dispatch('loadCustomFields');
        resolve();
      }).catch((error) => {
        commit('SET_DELETING', null);
        reject(error);
      });
    });
  },
  reorderCustomFields({ dispatch, commit, state }, { list } = {}) {
    return new Promise( (resolve, reject) => {
      const o = list.map(id => state.customFields.find(x => x.id == id));
      commit('SET_CUSTOM_FIELDS', o);
      http.post(`process-custom-fields/order`, { list }).then(() => {
        dispatch('loadCustomFields');
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  },
  loadCustomField({ commit }, { id } = {}) {
    return new Promise( (resolve, reject) => {
      commit('SET_LOADING', true);
      http.get(`process-custom-fields/${id}`).then((response)=>{
        commit('SET_LOADING', false);
        resolve(response.data);
      }).catch((error) => {
        commit('SET_LOADING', false);
        reject(error);
      });
    });
  },
  loadCustomFields({ commit }) {
    return new Promise( (resolve, reject) => {
      commit('SET_LOADING', true);
      http.get(`process-custom-fields`).then((response)=>{
        commit('SET_LOADING', false);
        commit('SET_CUSTOM_FIELDS', response.data);
        resolve(response.data);
      }).catch((error) => {
        commit('SET_LOADING', false);
        reject(error);
      });
    });
  },
  loadTags({ commit }) {
    return new Promise( (resolve, reject) => {
      commit('SET_LOADING', true);
      http.get(`tags?discriminator=Process`).then((response)=>{
        commit('SET_LOADING', false);
        commit('SET_TAGS', response.data);
        resolve(response.data);
      }).catch((error) => {
        commit('SET_LOADING', false);
        reject(error);
      });
    });
  },
  async setPage({ dispatch, commit }, newValue ) {
    commit('SET_PAGE', newValue);
    await dispatch('load');
  },
  async setOrderBy({ dispatch, commit }, newValue ) {
    commit('SET_ORDER_BY', newValue);
    await dispatch('debounceSavePersistent');
    await dispatch('debounceLoad');
  },
  async loadPersistent({ commit }) {
    const response = await http.get(`user-storage/processes`);
    if(response.data) {
      const data = response.data || getDefaultState();
      commit('SET_PERSISTENT', { data });
    } 
  },
  debounceSavePersistent: debounce(async ({ dispatch }) =>  {
    await dispatch('savePersistent');
  }, 1000),
  debounceLoad: debounce(async ({ dispatch }) =>  {
    await dispatch('load');
  }, 1000),
  async savePersistent ({ state }) {
    await http.post(`user-storage/processes`, state.persistent );
  },
  async createProcess({ dispatch, commit }, { dto } = {}) {
    commit('SET_PROCESSING', true);
    try {      
      const response = await http.post(`processes`, dto);
      await dispatch('load');
      await dispatch('processesBoard/refresh', { silent: true }, { root: true });
      await dispatch('loadTags');
      if(dto.contactId) {
        await dispatch('loadContactProcesses', { contactId: dto.contactId });
        await dispatch('contacts/loadContacts', {}, { root: true });
        await dispatch('contacts/loadContact', { id: dto.contactId }, { root: true });
        await dispatch('contacts/loadContactNotes', { id: dto.contactId }, { root: true});
      }
      await dispatch('loadProcess', { id: response.data.id });
      await dispatch('loadProcessNotes', { id: response.data.id });
      await dispatch('loadProcessHistory', { id: response.data.id });
      commit('SET_PROCESSING', false);
      return response;
    } catch(e) {
      commit('SET_PROCESSING', false);
      throw e;
    }
  },
  async deleteProcess({ dispatch, commit }, { id, contactId } = {}) {
    commit('SET_PROCESSING', id);
    await http.delete(`processes/${id}`);
    commit('SET_PROCESSING', false);
    await dispatch('load');
    await dispatch('processesBoard/refresh', { silent: true }, { root: true });
    if(contactId) {
      await dispatch('contacts/loadContacts', {}, { root: true });
      await dispatch('loadContactProcesses', { contactId: contactId });
      await dispatch('contacts/loadContact', { id: contactId }, { root: true });
    }
  },
  async updateProcess({ dispatch, commit }, { id, dto } = {}) {
    commit('SET_PROCESSING', true);
    try { 
      const response = await http.put(`processes/${id}`, dto);
      await dispatch('load');
      await dispatch('processesBoard/refresh', { silent: true }, { root: true });
      await dispatch('loadTags');
    
      if(dto.contactId) {
        await dispatch('contacts/loadContacts', {}, { root: true });
        await dispatch('loadContactProcesses', { contactId: dto.contactId });
        await dispatch('contacts/loadContact', { id: dto.contactId }, { root: true });
        await dispatch('contacts/loadContactNotes', { id: dto.contactId }, { root: true});
      }
      await dispatch('loadProcess', { id: id });
      await dispatch('loadProcessNotes', { id: id });
      await dispatch('loadProcessHistory', { id: id });
      commit('SET_PROCESSING', false);
      return response;
    } catch(e) {
      commit('SET_PROCESSING', false);
      throw e;
    }
  },
 
  async load({ commit, state, rootGetters }, { resetPage, silent } = {}) {
    if(resetPage) {
      commit('SET_PAGE', 1);
    }

    if(!silent)
      commit('SET_LOADING', true);
    
    let filter = {
      skip: (state.list.page - 1) * state.list.perPage,
      take: state.list.perPage,
      orderByField: state.persistent.orderBy.field,
      orderByAsc: state.persistent.orderBy.asc,
    };

    filter.noManager = rootGetters['processesFilter/filter'].noManager;
    filter.manager = rootGetters['processesFilter/filter'].manager;
    filter.tags = rootGetters['processesFilter/filter'].tags;
    filter.keyword = rootGetters['processesFilter/filter'].keyword;
   
    filter.stateId = rootGetters['processesFilter/filter'].stateId;

    filter.dueDateFilter = rootGetters['processesFilter/filter'].dueDateFilter;
    filter.dueDateTill = rootGetters['processesFilter/filter'].dueDateTill;
    filter.dueDateFrom = rootGetters['processesFilter/filter'].dueDateFrom;

    filter.dateFilter = rootGetters['processesFilter/filter'].dateFilter;
    filter.dateTill = rootGetters['processesFilter/filter'].dateTill;
    filter.dateFrom = rootGetters['processesFilter/filter'].dateFrom;
   
    filter.customFields = rootGetters['processesFilter/filter'].customFields || [];
    filter.processTypeId = state.processTypeId;

    const response = await http.post(`processes/search`, filter);
  
    if(!silent)
      commit('SET_LOADING', false);
    commit('SET_PROCESSES_LIST', { total: response.data.total, items: response.data.items });
  },
  async loadContactProcesses({ commit }, { contactId, orderByField, orderByAsc  }) {
    commit('SET_LOADING', true);
    const response = await http.post(`processes/search`, { contactId, orderByField, orderByAsc } );
    commit('SET_LOADING', false);
    commit('SET_PROCESSES_BY_CONTACT', { contactId, processes: response.data.items });
  },
  loadProcess({ commit }, { id, silent } = {}) {
    return new Promise( (resolve, reject) => {
      if(!silent)
        commit('SET_LOADING', true);
        http.get(`processes/${id}`).then((response)=>{
        if(!silent)
          commit('SET_LOADING', false);
        commit('SET_DETAILS',{id, value: response.data});
        resolve(response.data);
      }).catch((error) => {
        if(!silent)
          commit('SET_LOADING', false);
        reject(error);
      });
    });
  },
  loadProcessNotes({ commit }, { id } = {}) {
    return new Promise( (resolve, reject) => {
      commit('SET_LOADING', true);
      http.get(`processes/${id}/notes`).then((response)=>{
        commit('SET_LOADING', false);
        commit('SET_NOTES', { id, notes: response.data });
        resolve(response.data);
      }).catch((error) => {
        commit('SET_LOADING', false);
        reject(error);
      });
    });
  },
  loadProcessHistory({ commit }, { id } = {}) {
    return new Promise( (resolve, reject) => {
      commit('SET_LOADING', true);
      http.get(`processes/${id}/history`).then((response)=>{
        commit('SET_LOADING', false);
        commit('SET_HISTORY', { id, history: response.data });
        resolve(response.data);
      }).catch((error) => {
        commit('SET_LOADING', false);
        reject(error);
      });
    });
  },
  addProcessNote({ dispatch, commit }, { processId, text } = {}) {
    return new Promise( (resolve, reject) => {
      commit('SET_PROCESSING', processId);
      http.post(`processes/${processId}/notes`, { text }).then(()=>{
        commit('SET_PROCESSING', null);
        dispatch('loadProcessNotes', { id: processId });
        dispatch('load', { silent: true });
        resolve();
      }).catch((error) => {
        commit('SET_PROCESSING', null);
        reject(error);
      });
    });
  },
  updateProcessNote({ dispatch, commit}, { processId, id, text } = {}) {
    return new Promise( (resolve, reject) => {
      commit('SET_PROCESSING', null);
      http.put(`processes/${processId}/notes/${id}`, { text }).then(()=>{
        commit('SET_PROCESSING', null);
        dispatch('loadProcessNotes', { id: processId });
        dispatch('load', { silent: true });
        resolve();
      }).catch((error) => {
        commit('SET_PROCESSING', null);
        reject(error);
      });
    });
  },
  deleteProcessNote({ dispatch, commit }, { processId, id } = {}) {
    return new Promise( (resolve, reject) => {
      commit('SET_DELETEING', id);
      http.delete(`processes/${processId}/notes/${id}`).then(()=>{
        commit('SET_DELETEING', null);
        dispatch('loadProcessNotes', { id: processId });
        dispatch('load', { silent: true });
       
        resolve();
      }).catch((error) => {
        commit('SET_DELETEING', null);
        reject(error);
      });
    });
  },
  async loadStat({ commit }) {
    const response  = await http.get(`processes/stat`)
    commit('SET_STATE', response.data);
  }
}
