import http from '../../app-http'

export const state = {
    currentUser: loadState('VostokSOS CRM.auth.current-user'),
    token: window.localStorage.getItem('VostokSOS CRM.auth.token'),
    processing: false
}

let _i18n;
export const mutations = {
    INIT(_, { i18n }) {
        _i18n = i18n;
        console.log('auth INIT', {i18n});
    },
    SET_PROCESSING(state, newValue) {
        state.processing = newValue;
    },
    SET_TOKEN(state, newValue) {
        state.token = newValue
        if(newValue) {
            localStorage.setItem('VostokSOS CRM.auth.token', newValue);
        }
        else {
            localStorage.removeItem('VostokSOS CRM.auth.token');
        }
    },
    SET_CURRENT_USER(state, newValue) {
        console.log('SET_CURRENT_USER', newValue);
        state.currentUser = newValue
        if(newValue) {
            saveState('VostokSOS CRM.auth.current-user', newValue);
            _i18n.locale = newValue.account.lang || 'en';
        }
        else {
            localStorage.removeItem('VostokSOS CRM.auth.current-user');
        }
    },
}

export const getters = {
    moduleFileStorageEnabled(state) {
        return ((state.currentUser || {}).account || {}).settings.moduleFileStorageEnabled;
    },
    moduleChatBotEnabled(state) {
        return ((state.currentUser || {}).account || {}).settings.moduleChatBotEnabled;
    },
    contactFamilyEnabled(state) {
        return ((state.currentUser || {}).account || {}).settings.contactFamilyEnabled;
    },
    mapEnabled(state) {
        return ((state.currentUser || {}).account || {}).settings.mapEnabled;
    },
    moduleProductsEnabled(state) {
        return ((state.currentUser || {}).account || {}).settings.moduleProductsEnabled;
    },
    moduleShipmentEnabled(state) {
        return ((state.currentUser || {}).account || {}).settings.moduleShipmentEnabled;
    },
    token(state) {
        return state.token;
    },
    processing(state) {
        return state.processing
    },
    loggedIn(state) {
        return !!state.currentUser
    },
    currentUser(state) {
        return state.currentUser || {};
    },
    defaultStateId (state) {
        return (((state.currentUser || {}).account || {}).settings || {}).defaultStateId || null;
    },
    subscription (state) {
        return (((state.currentUser || {}).account || {}).settings || {}).subscription || 'Free';
    },
    userGroupIDs(state) {
        if(state.currentUser && state.currentUser.groups && state.currentUser.groups.length > 0) {
            return state.currentUser.groups.map(x => x.id);
        }
        return [];
    }
}

export const actions = {
    register({ dispatch, commit }, { accountName, password, email, userName, timeZone, demo } = {}) {
        return new Promise( (resolve, reject) => {
            commit('SET_PROCESSING', true);
            http.post('accounts', { accountName, password, email, userName, timeZone, demo }).then((response)=>{
                if(response.data.resultType === 'Success') {
                    commit('SET_PROCESSING', false);
                    commit('SET_CURRENT_USER', response.data.data.user);
                    commit('SET_TOKEN', response.data.data.token);
                    commit('chat/RESET_STATE', null, { root: true });
                    commit('users/RESET_STATE', null, { root: true });
                    commit('bots/RESET_STATE', null, { root: true });
                    commit('usageProfiles/RESET_STATE', null, { root: true });
                    commit('directories/RESET_STATE', null, { root: true });
                    commit('directoryItems/RESET_STATE', null, { root: true });
                    commit('userGroups/RESET_STATE', null, { root: true });
                    dispatch('signalr/connect', null, { root: true });
                    dispatch('boot/reload', null, { root: true });
                    resolve(true);
                } else {
                    commit('SET_PROCESSING', false);
                    resolve(response.data.accounts);
                }
           }).catch((error) => {
                commit('SET_PROCESSING', false);
               reject(error);
           });
       });
    },

    refresh({ dispatch, commit, state }) {
        return new Promise( (resolve, reject) => {
            if(!state.currentUser){
                resolve();
                return;
            }
           commit('SET_PROCESSING', true);
           http.post(`auth/refresh`).then((response)=>{
            commit('SET_PROCESSING', false);
            commit('SET_CURRENT_USER', response.data.data.user);
            commit('SET_TOKEN', response.data.data.token);
            resolve();
           }).catch((error) => {
               commit('SET_PROCESSING', false);
               dispatch('auth/logOut');
               setTimeout(function(){
                location.reload(true);
               }, 1000);
               reject(error);
           });
       });
   },
   setNewPassword({ commit }, { newPassword, token, userId } = {}) {
    return new Promise( (resolve, reject) => {
        commit('SET_PROCESSING', true);
        http.put('recover-password', { newPassword, token, userId }).then(()=>{
            commit('SET_PROCESSING', false);
            resolve();
        }).catch((error) => {
            commit('SET_PROCESSING', false);
            reject(error);
        });
    });
   },
   resetPassword({ commit }, { email } = {}) {
    return new Promise( (resolve, reject) => {
        commit('SET_PROCESSING', true);
        http.post('recover-password', { email }).then(()=>{
            commit('SET_PROCESSING', false);
            resolve();
        }).catch((error) => {
            commit('SET_PROCESSING', false);
            reject(error);
        });
    });
   },
    // Logs in the current user.
    logIn({ dispatch, commit }, { email, password, accountId } = {}) {
         return new Promise( (resolve, reject) => {
            commit('SET_PROCESSING', true);
            http.post('auth', { email, password, accountId }).then((response)=>{
                if(response.data.resultType === 'Success') {
                    commit('SET_PROCESSING', false);
                    commit('SET_CURRENT_USER', response.data.data.user);
                    commit('SET_TOKEN', response.data.data.token);
                    commit('chat/RESET_STATE', null, { root: true });
                    commit('users/RESET_STATE', null, { root: true });
                    commit('bots/RESET_STATE', null, { root: true });
                    commit('usageProfiles/RESET_STATE', null, { root: true });
                    commit('directories/RESET_STATE', null, { root: true });
                    commit('directoryItems/RESET_STATE', null, { root: true });
                    commit('userGroups/RESET_STATE', null, { root: true });
                    dispatch('signalr/connect', null, { root: true });
                    dispatch('boot/reload', null, { root: true });
                    resolve(true);
                } else {
                    commit('SET_PROCESSING', false);
                    resolve(response.data.accounts);
                }
            }).catch((error) => {
                commit('SET_PROCESSING', false);
                reject(error);
            });
        });
    },

    // Logs out the current user.
    logOut({ commit }) {
        // eslint-disable-next-line no-unused-vars
        commit('SET_CURRENT_USER', null);
        commit('SET_TOKEN', null);
        commit('chat/RESET_STATE', null, { root: true });
        commit('usageProfiles/RESET_STATE', null, { root: true });
        commit('directories/RESET_STATE', null, { root: true });
        commit('directoryItems/RESET_STATE', null, { root: true });
        commit('users/RESET_STATE', null, { root: true });
        commit('bots/RESET_STATE', null, { root: true });
        commit('userGroups/RESET_STATE', null, { root: true });
    }
}

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state))
}


function loadState(key) {
    const b =  window.localStorage.getItem(key);
    if (b) {
        return JSON.parse(b);
    }
    return null;
}
