import http from '../../app-http'

export const state = {
  accountUpdating: false,
  profileUpdating: false,
  passwordChanging: false
}

export const mutations = {
  SET_ACCOUNT_UPDATING(state, newValue) {
    state.accountUpdating = newValue;
  },
  SET_PROFILE_UPDATING(state, newValue) {
    state.profileUpdating = newValue;
  },
  SET_PASSWORD_CHANGING(state, newValue) {
    state.passwordChanging = newValue;
  }
}

export const getters = {
  accountUpdating(state) {
    return state.accountUpdating
  },
  profileUpdating(state) {
    return state.profileUpdating
  },
  passwordChanging(state) {
    return state.passwordChanging
  }
}

export const actions = {
  updateAccount({ dispatch, commit }, { id, name, timeZone } = {}) {
    return new Promise( (resolve, reject) => {
      commit('SET_ACCOUNT_UPDATING', true);
      http.put(`accounts/${id}`, { name, timeZone }).then(()=>{
        commit('SET_ACCOUNT_UPDATING', false);
        dispatch('auth/refresh', null, { root: true });
        resolve(true);
      }).catch((error) => {
        commit('SET_ACCOUNT_UPDATING', false);
        reject(error);
      });
    });
  },
  updateProfile({ dispatch, commit }, { name, phoneNumber } = {}) {
    return new Promise( (resolve, reject) => {
      commit('SET_PROFILE_UPDATING', true);
      http.put(`profile`, { name, phoneNumber }).then(()=>{
        commit('SET_PROFILE_UPDATING', false);
        dispatch('auth/refresh', null, { root: true });
        resolve(true);
      }).catch((error) => {
        commit('SET_PROFILE_UPDATING', false);
        reject(error);
      });
    });
  },
  changePassword({  commit }, { oldPassword, newPassword } = {}) {
    return new Promise( (resolve, reject) => {
      commit('SET_PASSWORD_CHANGING', true);
      http.put(`profile/password`, { oldPassword, newPassword }).then(()=>{
        commit('SET_PASSWORD_CHANGING', false);
        resolve(true);
      }).catch((error) => {
        commit('SET_PASSWORD_CHANGING', false);
        reject(error);
      });
    });
  }
}
