<script>
import { mapGetters, mapMutations } from 'vuex';
import http from '@/app-http'
import draggable from 'vuedraggable'
import { uuid } from 'vue-uuid';
import Typeahead from '@/components/typeahead'
import { debounce } from "lodash";
import gmapsInit from '@/gmaps';
import VueTelInput from '@/components/vue-tel-input'
import InlineTableRowEdit from './custom-field-inline-table-row-edit.vue'

export default {
  components: {  
    draggable,
    Typeahead,
    VueTelInput,
    InlineTableRowEdit
  },
  props: {
    def: {
      type: Object,
      required: true
    },
    directoryId: {
      type: String
    },
    value:{
      required: true
    },
    small: {
      type: Boolean,
    }
  },
  async mounted() {
    try {
      if(this.def.type === 'Address') {
       
        const google = await gmapsInit();
        this.google = google;
        const options = {
          language: this.currentUser.account.lang,
         // componentRestrictions: { country: "ua" },
          fields: [ "geometry", "formatted_address"]
        };
        const autocomplete = new google.maps.places.Autocomplete(this.$refs.autocompleteInput, options);
        this.autocomplete = autocomplete;
        this.autocomplete.addListener('place_changed', this.googlePlaceChanged);
      }
    } catch (error) {
      console.error(error);
    }
  },
  beforeUnmount(){
    if(this.google) {
      if(this.autocomplete) {
        this.google.maps.event.clearInstanceListeners(this.$refs.autocompleteInput)
      }
    }
  },
  created(){
    this.updateInternalValue(this.value);
    this.onInit();
  },
  data() {
    return {
      pValue: null,
      directoryItems: [],
      rootDirectoryItems: [],
      rootItemValueId: null,
      directoryItemsLoading: false,
      internalId: uuid.v1(),
      tableRecordValue: null,
      lat: null,
      lng: null,
      phone: null,
      phoneIsNotValid: false,
      editedRows: {},
      suggestions: [],
      suggestionLoading: false
    }
  },
  computed:{
    ...mapGetters('auth', {
      currentUser: 'currentUser'
    }),
     ...mapGetters('directories', {
      directories: 'items',
      allCustomFields: 'allCustomFields'
    }),
    countryCode() {
      return this.currentUser.account.countryCode;
    },  
    tableValue:
    {
      get: function() {
        return this.pValue || [];
      },
      set: function(newValue) {
        this.$emit('update', newValue);
      }
    },
    directoryItemValueId:
    {
      get: function() {
        return this.pValue ? this.pValue.id : null;
      },
      set: function(newValue) {
        console.log('directoryItemValueId', newValue);
        this.raiseDirectoryItemUpdate(newValue);
      }
    },
    rootDirectoryField() {
      if(this.def.rootDirectoryFieldId) {
        const dir = this.directories.find(x => x.id === this.def.directoryId);
        return dir ? dir.fields.find(x => x.id === this.def.rootDirectoryFieldId) : null;
      }
      return null;
    },
    step() {
      if(this.def.precision <= 0)
        return "1";
      let s = "0.";
      for(let i = 0; i < this.def.precision-1; i++) {
        s += '0';
      }
      s += '1';
      return s;
    }
  },
  watch: {
    value (val, oldVal) {
      if (val !== oldVal)  {
        this.updateInternalValue(val);
      }
    },
    def (val, oldVal) {
       if (val !== oldVal)  {
        this.updateInternalValue(this.value);
        this.onInit();
       }
    }
  },
  methods:{
    ...mapMutations('customFields', {
      showAddressForm: 'SHOW_ADDRESS_FORM',
      showTableValueForm: 'SHOW_TABLE_VALUE_FORM'
    }),
    ...mapMutations('directoryItems', {
      openItem: 'OPEN_ITEM'
    }),
    selectSuggestion(obj) {
      this.suggestions = [];
      this.$emit('reload', obj);
    },
    onDebSuggest: debounce(function(e){
      this.suggest(e);
    }, 400),
    async suggest(text) {
      if(!text || text.length < 2) return;

      this.suggestions = [];
      this.suggestionLoading = true;
      const resp = await http.get(`directories/${this.directoryId}/suggestions/${this.def.id}`, {
        params:{
          keywords: text
        }
      });
      this.suggestions = resp.data;
      this.suggestionLoading = false;
    },
    onPhoneInput(_, phoneObject) {
      if(phoneObject) {
      //  console.log('phone', phoneObject);
        this.phoneIsNotValid = !phoneObject.valid && phoneObject.formatted;
        if (phoneObject?.formatted) {
          this.raiseUpdate(phoneObject.number)
        }
      }
      
    },
    googlePlaceChanged() {
      var place = this.autocomplete.getPlace();

      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        // Do anything you like with what was entered in the ac field.
        //console.log('You entered: ', { place, r: this.autocomplete.removeListener });
        return;
      }

      //console.log('You selected: ', { place, r: this.autocomplete.removeListener });
      const newValue = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        address: place.formatted_address
      };
      this.raiseUpdate(newValue);
      //console.log('You selected: ', { newValue });
      //this.$emit('update', val); 
    },
    
    updateInternalValue (v) {
      if(this.def.type === 'Table') {
        this.pValue = v || [];
      } else if(this.def.type === 'Address') {
        this.pValue = v ? v.address : '';
        this.lat =  v ? v.lat : '';
        this.lng =  v ? v.lng : '';
       
      } else {
        this.pValue = v || null;
      }
      if(this.rootDirectoryField) {
        if(this.pValue === null ) {
          this.rootItemValueId = null;
        } 
      }
    },
    updateAddressString(val) {
      if(this.pValue != val) {
        this.raiseUpdate({ address: val});
      }
    },
    raiseNumberUpdate(val) {
      
      val = val || null;
      if(!val){
        this.$emit('update', val); 
        return;
      }
      const n = parseFloat(val);
    
      if(n.isNaN) {
        val = null;
      } else {
        if(this.def.precision <= 0){
          val = Math.round(n);
        } else {
          val = parseFloat(n.toFixed(this.def.precision));
        }
      }
      this.$emit('update', val); 
    },
    raiseDirectoryItemUpdate(val) {
      if(val) {
        const obj = this.directoryItems.find(x => x.id === val);
        this.$emit('update', obj);
      }else {
        this.$emit('update', null);
      }
    },
    raiseUpdate(val){ 
      this.$emit('update', val);
    },
    loadDirectoryItems() {

    },
    newDirectoryItem() {

    },
    saveEditedRow(def) {
     // console.log('onSave 1', this.editedRows[def.id]);
      if(this.inlineTableEditAllowSave(def)) {
        const {value, tableValue, orig} = this.editedRows[def.id];
        //console.log('onSave1', JSON.parse(JSON.stringify(value)));
        tableValue[orig] = value;
       // console.log('onSave1', {orig, v: JSON.parse(JSON.stringify(value)), tv: JSON.parse(JSON.stringify(tableValue))});
       // console.log('onSave2', JSON.parse(JSON.stringify(tableValue)));
        this.cancelEditRow(def.id);
        //console.log('onSave3', JSON.parse(JSON.stringify(tableValue)));
        this.raiseOnFieldEditingEvent();
      }
    },
    cancelEditRow(id) {
      const o = {...this.editedRows};
      o[id] = null;
      this.editedRows = o;
      this.raiseOnFieldEditingEvent();
    },
    editTablRow({index, value, def, tableValue} = {}) {
      const o = {...this.editedRows};
      o[def.id] ={ orig: index, tableValue, value: JSON.parse(JSON.stringify(value))};
      this.editedRows = o;
      this.raiseOnFieldEditingEvent();
    },
    raiseOnFieldEditingEvent() {
      const keys = Object.keys(this.editedRows);
      if(keys.find(x => this.editedRows[x])) {
        this.$emit('fieldEditing', true);
      } else {
        this.$emit('fieldEditing', false);
      }
    },
    onTableRecordUpdate(value, arg) {
      const o = [... this.tableValue];
      o[arg] = value;
      this.tableValue = o;
    },
    onTableRecordAdd(value) {
      this.tableValue = [...this.tableValue, value];
    },
    selectDirectoryItem(item) {
      this.$emit('update', item);
    },
    displayDirectoryItem(item) {
      //const dir = this.getDirectory(this.def.directoryId);
      if(this.def.displayDirectoryFieldId) {
        return '#' +  item.number + ' | ' + item.customFields[this.def.displayDirectoryFieldId];
      } else if(this.def.displayDirectoryFieldId === 'Number') {
          return '#' +  item.number;
      } else {
        return '#' +  item.number + (item.display ? ' | ' + item.display : '');
      }
    },
    getDirectory(dirId) {
      return this.directories.find(x => x.id === dirId);
    },
    clearDirectoryItem() {
      this.rootItemValueId = null;
      this.directoryItemValueId = null;
    },
    async onDirectoryItemSearch(e) {
      
       const req = {
          keyword: (e || {}).input || '',
          skip: 0,
          take: 100,
          orderBy: {
            field: '$$display$$', 
            asc: true
          }
        };
        this.directoryItemsLoading = true;
        const response = await http.post(`directories/${this.def.directoryId}/items/search`, req);
        this.directoryItemsLoading = false;
        this.directoryItems = response.data.items;
      
    },
    onDebDirectoryItemSearch: debounce(function(e){
      this.onDirectoryItemSearch(e);
    }, 1000),
    async onInit() {
      if(this.def.type === 'Directory') {
        if (this.def.directoryLookupControl === 'Typeahead') {
          this.onDirectoryItemSearch();
        } else  if (this.def.directoryLookupControl === 'Dropdown' ) {
          this.rootItemValueId = null;
          this.directoryItemsLoading = true;
          try {
            const response = await http.post(`directories/${this.def.directoryId}/items/search`, {take: 1000, orderBy: { field: '$$display$$', asc: true}});
            this.directoryItems = response.data.items;
            if(this.rootDirectoryField) {
              const hierarchyResponse = await http.post(`directories/${this.rootDirectoryField.directoryId}/items/search`, {take: 1000, orderBy: { field: '$$display$$', asc: true}});
              this.rootDirectoryItems = hierarchyResponse.data.items;
              if(this.pValue) {
                this.rootItemValueId = this.pValue.customFields[this.def.rootDirectoryFieldId]?.id || null;
              }
            }
          } finally {
            this.directoryItemsLoading = false;
          }
        }
      }
    },
    getRelatedFieldDef(directoryFieldId, directoryRelatedFieldId) {
      const refField = this.def.tableFields.find(x => x.id === directoryFieldId);
      if(refField) {
        const directoryId = refField.directoryId;
        const directory = this.directories.find(x => x.id === directoryId);
        if(directory) {
          const directoryRelatedField = (this.allCustomFields[directoryRelatedFieldId] || null);
          return directoryRelatedField;
        }
      }
      return null;
    },
    getRelatedFielValue(tableRecord, directoryFieldId, directoryRelatedFieldId) {
      const refField = this.def.tableFields.find(x => x.id === directoryFieldId);
      if(refField) {
        const directoryId = refField.directoryId;
        const directory = this.directories.find(x => x.id === directoryId);
        if(directory) {
          const dirValue = tableRecord[directoryFieldId];
          if(dirValue) {
            return dirValue.customFields[directoryRelatedFieldId];
          }
        }
      }
      return null;
    },
    inlineTableCellContainerStyle(field) {
      return field.type === 'Number' ? {
        'min-width': '50px'
      } : {
        'min-width': '100px'
      };
    },
    inlineTableEditAllowSave(def) {
      const { value } = this.editedRows[def.id];
      const requiredFields = def.tableFields.filter(x => x.required && !x.hidden);
      let res = true;
      if(requiredFields.length > 0) {
        requiredFields.forEach(x => {
          if(!value[x.id]) {
          res = false;
          }
        });
      } else {
        res = false;
        def.tableFields.filter(x =>!x.hidden).forEach(x => {
          if(value[x.id]) {
            res = true;
          }
        });
      }
      
      return res;
    }
  }
};
</script>

<template>
  <div>
   
    <div v-if="def.type === 'Directory'">
      <div v-if="def.directoryLookupControl === 'Typeahead'">
        <div   v-if="pValue">
          <div class="input-group">
            <div class="form-control text-truncate text-nowrap" :class="{'form-control-sm': small, 'required': def.required}">
              <a href="javascript:void(0)" @click="openItem({ item: pValue, mode: 'view'})">
                <span v-if="def.displayDirectoryFieldId">
                  <span v-if="def.displayDirectoryFieldId !== 'Number'">{{pValue.customFields[def.displayDirectoryFieldId]}}</span>
                  <span v-if="def.displayDirectoryFieldId === 'Number'"><b> #{{pValue.number}}</b></span>
                </span>
                <span v-if="!def.displayDirectoryFieldId && pValue.display">
                  {{pValue.display}}
                </span>
                <b v-if="!def.displayDirectoryFieldId && !pValue.display">
                  #{{pValue.number}}
                </b>
              </a>
            </div>
              <button class="btn btn-outline-secondary" type="button" @click="clearDirectoryItem()" :class="{'btn-sm': small}">
                {{$t('common.change')}}
              </button>
          </div>
        </div>
        <div  v-if="!pValue">
          <typeahead
            :placeholder="$t('common.startTyping')"
            :items="directoryItems"
            :busy="directoryItemsLoading"
            :class="{'required': def.required}"
            :minInputLength="2"
            :itemProjection="displayDirectoryItem"
            @selectItem="(item) => selectDirectoryItem(item)"
            @onInput="onDebDirectoryItemSearch"
            :small="small"
          >
          </typeahead>
        </div>
      </div>
      <div v-if="def.directoryLookupControl === 'Dropdown'" >
        
        <div class="input-group">
          <button class="input-group-text"  v-if="directoryItemsLoading" :class="{'btn-sm': small}">
            <i class="fa fa-spinner fa-spin" ></i>
          </button>
          <div v-if="def.rootDirectoryFieldId && rootDirectoryField">
            <select 
              class="form-control"
              :class="{'form-control-sm': small, 'required': def.required}"
              v-model="rootItemValueId"
            >
              <option :value="null">{{$t('common.nothingSelected')}}</option>
              <option v-for="i in rootDirectoryItems" :key="i.id" :value="i.id">
                <span>
                  {{i.display || '???'}}
                </span>
              </option>
            </select>
          </div>
      
          <select 
            class="form-control"
            :class="{'form-control-sm': small, 'required': def.required}"
            v-model="directoryItemValueId"
          >
            <option :value="null">{{$t('common.nothingSelected')}}</option>
            <option v-for="i in (rootItemValueId ? directoryItems.filter(a => (a.customFields[def.rootDirectoryFieldId] || {}).id === rootItemValueId) : directoryItems)" :key="i.id" :value="i.id">
              <span v-if="def.displayDirectoryFieldId">
                <span v-if="def.displayDirectoryFieldId !== 'Number'"> {{i.customFields[def.displayDirectoryFieldId]}}</span>
                <span v-if="def.displayDirectoryFieldId === 'Number'"><b> #{{i.number}}</b></span>
              </span>
              <span v-if="!def.displayDirectoryFieldId && !i.display">
                {{('#' + i.number)}}
              </span>
             <span v-if="!def.displayDirectoryFieldId && i.display">
                {{i.display}}
              </span>
            </option>
          </select>
          <!-- <button class="btn btn-outline-light" type="button" v-if="allowEditDirectoryItem()" @click="newDirectoryItem">
            <i class="fa fa-plus"></i>
            <div class="text-sm-end d-none d-md-inline-block ms-2">
              {{ $t('common.newRecord') }}
            </div>
          </button> -->
        </div>
      </div>
    </div>
    <div v-if="def.type === 'PhoneNumber'">
  
      <VueTelInput 
        :class="{'invalid': phoneIsNotValid, 'form-control-sm': small, 'required': def.required}"
        :default-country="countryCode"
        :value="pValue" @input="onPhoneInput"
        >
      </VueTelInput>
    </div>
    <div v-if="def.type === 'Number'">
      <input 
        type="text" 
        class="form-control" 
        :class="{'form-control-sm': small, 'required': def.required}"
        :step="step"
        :value="pValue"  
        @input.stop="event => raiseNumberUpdate(event.target.value)" 
        @blur.stop="event => raiseNumberUpdate(event.target.value)"
      />
    </div>
    <div v-if="def.type === 'Date'">
      <input 
        type="date" 
        class="form-control" 
        :class="{'form-control-sm': small, 'required': def.required}"
        :value="value"  
        @input.stop="event => raiseUpdate(event.target.value)" 
        @blur.stop="event => raiseUpdate(event.target.value)"
      />
    </div>
    <div v-if="def.type === 'Dropdown'"> 
      <select class="form-control"  :class="{'form-control-sm': small, 'required': def.required}" 
        v-model="pValue" 
        @change.stop="event => raiseUpdate(pValue)" 
      >
        <option :value="null">{{$t('common.nothingSelected')}}</option>
        <option v-for="(ddItem, $index) in def.dropdownItems" :key="$index" :value="ddItem.value">{{ddItem.text}}</option>
      </select>
    </div>
    <div v-if="def.type === 'String'">
     
      <template v-if="def.suggestionEnabled">
        <div style="position: relative;" >
          <input class="form-control" style="padding-right: 30px"  :class="{'form-control-sm': small, 'required': def.required}"
            :value="value"  
            @input.stop="event => raiseUpdate(event.target.value)" 
            @blur.stop="event => raiseUpdate(event.target.value)"
            @keyup="onDebSuggest($event.target.value)"
          />
          <i class="fa fa-spinner fa-spin"  v-if="suggestionLoading" style="position: absolute; top:10px; right: 10px;"></i>
          <div v-if="suggestions.length > 0" 
            class="bg-white border"  
            style="position: absolute; top:38px; left:0; right: 0; border-width: 5px; z-index: 9999; ;"
           
            >
            <div class="list-group  list-group-flush" style="max-height: 200px; overflow: auto">
              <a 
                @click="selectSuggestion(s)"
                href="javascript:void(0)" 
                class="list-group-item list-group-item-action" 
                v-for="s in suggestions" :key="s.id"
              >
                {{s.display}}
                <span class="float-start me-2">#{{s.number}}</span>
              </a>
            </div>
            <button class="btn btn-secondary btn-sm float-end d-inline-block m-2" @click="suggestions = []">
              {{$t('common.close')}}
            </button>
          </div>
        </div>
      </template>
      <template v-else>
        <input class="form-control"  :class="{'form-control-sm': small, 'required': def.required}"
          :value="value"  
          @input.stop="event => raiseUpdate(event.target.value)" 
          @blur.stop="event => raiseUpdate(event.target.value)"
        />
      </template>
      
    </div>
    <div v-if="def.type === 'Link' ">
      <input class="form-control"  :class="{'form-control-sm': small, 'required': def.required}"
        :value="value"  
        @input.stop="event => raiseUpdate(event.target.value)" 
        @blur.stop="event => raiseUpdate(event.target.value)"
      />
    </div>
    <div v-if="def.type === 'Text'">
      <textarea :rows="def.rows" class="form-control" :class="{'form-control-sm': small, 'required': def.required}"
        :value="value"  
        @input.stop="event => raiseUpdate(event.target.value)" 
        @blur.stop="event => raiseUpdate(event.target.value)"
      >
      </textarea>
    </div>
    <div v-if="def.type === 'Table' && def.tableFields && def.tableFields.length > 0">
      <a href="javascript:void(0)" class="btn btn-light btn-sm mb-2" @click="showTableValueForm( { value: {}, def, onSave: onTableRecordAdd} )" v-if="!def.tableInlineEdit">
        <i class="fa fa-plus me-1"></i> {{ $t("common.newButtonTitle") }}
      </a>
      
      <div class="table-responsive">
        <table class="table mb-0 table-borderless border  table-sm">
          <thead class="table-light">
            <tr>
              <th style="max-width:25px; width: 25px; " class="text-center">
                <i class="mdi mdi-drag-variant"></i>
              </th>
              <th v-if="tableValue.find(x => x['$_META'])">
                <font-awesome-icon icon="fa-solid fa-link" />
              </th>
              <th style="max-width:40px; width: 40px;" class="text-center"></th>
              <th style="" class="col" v-for="tableField in def.tableFields.filter(x => !x.hidden)" :key="tableField.id">
                {{tableField.name}}
              </th>
              <th style="max-width:40px; width: 40px;" class="text-end"></th>
            </tr>
          </thead>

          <inline-table-row-edit 
            :has-linked-column="tableValue.find(x => x['$_META']) ? true : false"
            v-if="def.tableInlineEdit"
            :save-enabled="!editedRows[def.id]" 
            :fields="def.tableFields"
            @save="onTableRecordAdd"
          >
          </inline-table-row-edit>

          <tbody  v-if="(pValue || []).length === 0">
            <tr>
              <td colspan="100">
                <em class="text-muted">
                  {{ $t("common.noRecordsFound") }}
                </em>
              </td>
            </tr>
          </tbody>
          
          <draggable
            tag="tbody"
            v-model="tableValue" 
            :group="'tbl' + internalId" 
            @start="drag=true" 
            @end="drag=false" 
            handle=".drag-area"
            item-key="index"
          >
            <template #item="{element, index}">
               
                <tr>
                  <td class="drag-area">
                    <a href="javascript:void(0)" v-if="!editedRows[def.id] ||editedRows[def.id].orig !== index" >
                      <i class="mdi mdi-drag font-size-18"></i>
                    </a>
                  </td>
                 <td v-if="tableValue.find(x => x['$_META'])">
                    <a href="javascript:void(0)" v-if=" element['$_META']" @click="openItem({item: element['$_META'].linkedTo, mode: 'view'})">
                    #{{element['$_META'].linkedTo.number}}
                    </a>
                  </td>
                  <td  style="max-width:40px; width: 40px; " class="text-center">
                 
                    <div v-if="editedRows[def.id] && editedRows[def.id].orig === index">
                      
                      <button type="button" class="btn btn-light btn-sm"  
                        :disabled="!inlineTableEditAllowSave(def)"
                        @click="saveEditedRow(def)"
                      >
                        <font-awesome-icon icon="fa-solid fa-check" />
                      </button>
                    </div>
                    <div v-if="!editedRows[def.id] ||editedRows[def.id].orig !== index">
                      <button type="button" class="btn btn-light btn-sm"  v-if="def.tableInlineEdit && (!element['$_META'] || element['$_META'].allowEdit)" 
                        @click="editTablRow( { index, value: element, tableValue, def, arg: tableValue.indexOf(element) } )"
                      >
                        <font-awesome-icon icon="fa-solid fa-pen" />
                      </button>
                      <button type="button" class="btn btn-light btn-sm"  v-if="!def.tableInlineEdit && (!element['$_META'] || element['$_META'].allowEdit)" 
                        @click="showTableValueForm( { value: element, def, onSave: onTableRecordUpdate, arg: tableValue.indexOf(element) } )"
                      >
                        <font-awesome-icon icon="fa-solid fa-pen" />
                      </button>
                    </div>
                  </td>
                  <td v-for="tableField in def.tableFields.filter(x => !x.hidden)" :key="tableField.id">
                    <template v-if="editedRows[def.id] && editedRows[def.id].orig === index">
                      <template v-if="tableField.type !== 'DirectoryRelatedField'">
                        <div  :style="inlineTableCellContainerStyle(tableField)">
                          <custom-field-edit 
                            small
                            :def="tableField" 
                            :value="editedRows[def.id].value[tableField.id]"
                            @update="(v) => editedRows[def.id].value[tableField.id] = v"
                          >
                          </custom-field-edit> 
                        </div>
                      </template>
                    </template>
                    <template v-if="!editedRows[def.id] || editedRows[def.id].orig !== index">
                      <template v-if="tableField.type !== 'DirectoryRelatedField'">
                        <custom-field :def="tableField" :value="element[tableField.id]"></custom-field>
                      </template>
                      <template v-if="tableField.type === 'DirectoryRelatedField' && getRelatedFieldDef(tableField.directoryFieldId, tableField.directoryRelatedFieldId) && getRelatedFielValue(element, tableField.directoryFieldId, tableField.directoryRelatedFieldId)">
                          <custom-field :def=" getRelatedFieldDef(tableField.directoryFieldId, tableField.directoryRelatedFieldId)" :value="getRelatedFielValue(element, tableField.directoryFieldId, tableField.directoryRelatedFieldId)"></custom-field>
                      </template>
                    </template>
                    
                  </td>
                  <td style="max-width:40px; width: 40px; " class="text-end">
                    <div v-if="editedRows[def.id]">
                      <button type="button"
                        class="btn btn-light btn-sm"  
                        v-if="editedRows[def.id].orig === index"
                        @click="cancelEditRow(def.id)"
                      >
                        <font-awesome-icon icon="fa-solid fa-xmark" />
                      </button>
                    </div>
                    <div v-if="!editedRows[def.id] && (!element['$_META'] || element['$_META'].allowDelete)">
                      <button type="button"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        class="px-2 text-danger btn btn-light btn-sm"
                        @click="tableValue = [...tableValue.filter(x => x !== element)]"
                        >
                        <font-awesome-icon icon="fa-solid fa-trash-can" />
                      </button>
                    </div>
                  </td>
                </tr>
            </template>
          </draggable>
        </table>
      </div>
    </div>
    <div v-if="def.type === 'Address'" style="position: relative;">
      <input 
        type="text" 
        :class="{'form-control-sm': small, 'required': def.required}"
        style="padding-left: 30px;"
        :value="pValue"  
        @input.stop="event => updateAddressString(event.target.value)" 
        @blur.stop="event => updateAddressString(event.target.value)"
        class="form-control" 
        ref="autocompleteInput"
      />
      <a href="javascript:void(0)" class="px-2" style="position: absolute; top:10px;" @click="showAddressForm({address: { address: pValue, lat, lng }, editable: true, onSave: (v) => raiseUpdate(v)})">
        <font-awesome-icon icon="fa-solid fa-pen" />
      </a>
    </div>
  </div>
</template>
