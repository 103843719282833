import http from '@/app-http'

async function onLoad  ({dir}) {
    console.log('onLoad', dir.code);
    if(dir.code === 'Request') {
        const response = await http.post("directories/9f3b0b9d40b34f4a947195c66b510c6a/items/search",{
            skip: 0,
            take: 10000,
            orderBy:{
                field: '5db51310-3023-11ed-b0ec-39b8b8447153'
            }
        });
        const coofDonors = response.data.items;
      
        return {
            coofDonors
        }
    }
    return {};
  } 
  

export default function factory(rules) {
    return {...rules, onLoad: onLoad};
}
