import http from '../../app-http'
import { debounce } from "lodash";
// import { parseErrors} from "@/helpers"

const BOARD_PACKET_SIZE = 10;

const getDefaultViewState = () => {
  return {
    tags:[],
    keyword: null,
    loading: false,
    orderBy: {
      field: '$$status-pos$$',
      asc: false
    },
    collapsed: {

    },
    data:{

    }
  }
};

const getDefaultState = () => {
  return {
    views:{

    }
  }
};

export const state = getDefaultState();

export const mutations = {

  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  INIT_VIEW(state, { id }) {
    
    const o = {...state.views };
    o[id] = getDefaultViewState();
    state.views = o;
    
  },
  RESTORE_VIEW_STATE(state, { id, data }) {
    const o = {...state.views};
    o[id].keyword = data.keyword || null;
    o[id].tags = data.tags || [];
    o[id].collapsed = data.collapsed || {};
    o[id].orderBy = data.orderBy || {  
      field: '$$status-pos$$',
      asc: false
    };
    state.views = o;
  },
  CHANGE_ITEM_STATUS(state, { id, item, status, prevStatus }) {
    console.log('CHANGE_ITEM_STATUS', { id, item, status, prevStatus });
    const o = {...state.views};
    const newLaneData = o[id].data[status] || { total: 0, items: []};
    const oldLaneData = o[id].data[prevStatus] || { total: 0, items: []};

    oldLaneData.items = oldLaneData.items.filter(x => x.id !== item.id);
    if(oldLaneData.total > 0) {
      oldLaneData.total = oldLaneData.total - 1;
    }
    newLaneData.items = [item,...newLaneData.items];
    newLaneData.total = newLaneData.total+1;
    o[id].data[status] = newLaneData;
    o[id].data[prevStatus] = oldLaneData;
    state.views = o;
  },
  SET_ORDERBY_FIELD(state, {id, value}) {
    const o = {...state.views};
    o[id].orderBy.field = value;
    state.views = o;
  },
  SET_ORDERBY_ASC(state, {id, value}) {
    const o = {...state.views};
    o[id].orderBy.asc = value;
    state.views = o;
  },
  SET_COLLAPSED(state, {id, statusId, value}) {
    const o = {...state.views};
    o[id].collapsed[statusId] = value;
    state.views = o;
  },
  SET_VIEW_LOADING(state, {id, newValue}) {
    const o = {...state.views};
    o[id].loading = newValue;
    state.views = o;
  },
  SET_VIEW_TAGS(state, {id, value}) {
    const o = {...state.views};
    o[id].tags = value;
    state.views = o;
  },
  SET_VIEW_KEYWORD(state, {id, value}) {
    const o = {...state.views};
    o[id].keyword = value;
    state.views = o;
  },
  SET_VIEW_DATA(state, {id, statusId, items, total}) {
    const o = {...state.views};
    o[id].data[statusId] = {
      items,
      total
    };
    state.views = o;
  },
  UPDATE_STATUS_ITEMS(state, { id, status, items, total }) {
    const o = {...state.views};
    o[id].data[status] = {
      items,
      total
    };
    state.views = o;
  },
}

export const getters = {
  views: (state) => (id) => {
    return state.views[id] || getDefaultViewState();
  }
}

export const actions = {
  async init({commit, dispatch},  { id }) {
   
    commit('INIT_VIEW', { id });
    const response = await http.get(`user-storage/board-view-` + id);
    commit('RESTORE_VIEW_STATE', { id, data: response.data });
    await dispatch('reloadBoard',  { id });
  },
  async setOrderByField({dispatch, commit}, { id, value }) {
    commit('SET_ORDERBY_FIELD', {id, value});
    await dispatch('debounceSavePersistent', { id });
    await dispatch('debounceReloadBoard', { id });
  },
  async setOrderByAsc({dispatch, commit}, { id, value }) {
    commit('SET_ORDERBY_ASC', {id, value});
    await dispatch('debounceSavePersistent', { id });
    await dispatch('debounceReloadBoard', { id });
  },
  
  async collapseLane({dispatch, commit}, { id, statusId }) {
    commit('SET_COLLAPSED', {id, statusId, value: true});
    await dispatch('debounceSavePersistent', { id });
  },
  async expandLane({dispatch, commit}, { id, statusId }) {
    commit('SET_COLLAPSED', {id, statusId, value: false});
    await dispatch('debounceSavePersistent', { id });
  },
  async setKeyword({dispatch, commit}, { id, value }) {
    commit('SET_VIEW_KEYWORD', {id, value});
    await dispatch('debounceSavePersistent', { id });
    await dispatch('debounceReloadBoard', { id });
  },
  async setTags({dispatch, commit}, { id, value }) {
    commit('SET_VIEW_TAGS', {id, value});
    await dispatch('debounceSavePersistent', { id });
    await dispatch('reloadBoard', { id });
  },
  debounceSavePersistent: debounce(async ({ dispatch }, { id }) =>  {
    await dispatch('savePersistent', { id });
  }, 1000),
  async savePersistent ({ state }, { id }) {
    const view = state.views[id] || getDefaultViewState();
    const data = {
      keyword: view.keyword,
      tags: view.tags,
      collapsed: view.collapsed,
      orderBy: view.orderBy,
    };
    await http.post(`user-storage/board-view-` + id, data);
  },
  debounceReloadBoard: debounce(async ({ dispatch }, { id }) =>  {
    await dispatch('reloadBoard', { id });
  }, 1000),
  async changeStatus({ dispatch, commit }, { id, directoryId, item, status, prevStatus, note } = {}) {
    commit('CHANGE_ITEM_STATUS', { id, directoryId, item, status, prevStatus });
    const resp = await http.put(`directories/${directoryId}/items/${item.id}/status/`+status, { text: note });
    await dispatch('debounceReloadBoard' , { id });
    return resp.data;
  },
  async move({ dispatch, rootState } =  {}, { id, prevId, itemId, status, ignorePosition, note} = {} ) {
    const board = rootState.boards.items.find(x => x.id === id);
    const directoryId = board.lanes.find(x => x.statusId === status).directoryId;
    await http.post(`directories/${directoryId}/items/board-move`, { prevId, itemId, status, ignorePosition, note });
    await dispatch('debounceReloadBoard', { id });
  },
  async more({commit, getters, rootState},  { id, statusId }) {
    commit('SET_VIEW_LOADING', { id, newValue: true});
    const board = rootState.boards.items.find(x => x.id === id);
    const directoryId = board.lanes.find(x => x.statusId === statusId).directoryId;
    const view = getters.views(id);
    try {
      const statusLaneData = view.data[statusId] || { items: [], total: 0 };
      const req = {
        skip: statusLaneData.items.length,
        tags: view.tags || [],
        tagsLgOp: 'And',
        keyword: view.keyword,
        keywordLgOp: 'And',
        statusList: [statusId],
        statusLgOp: 'And',
        conditions:[],
        take: BOARD_PACKET_SIZE,
        orderBy: {
          field: view.orderBy.field || '$$status-pos$$',
          asc: view.orderBy.asc,
        }
      };
      const resp = await http.post(`directories/${directoryId}/items/search`, req);
      commit('SET_VIEW_DATA', { id, statusId, items: [...statusLaneData.items, ...resp.data.items], total: resp.data.total });
    } finally {
      commit('SET_VIEW_LOADING', {id, newValue: false});
    }
  },
  async reloadBoard({commit, getters, rootState},  { id }) {
    commit('SET_VIEW_LOADING', { id, newValue: true});
    try {
     
      const board = rootState.boards.items.find(x => x.id === id);
      const view = getters.views(id);
      if(board && view) {
        let boardDirectoryIDs = [];
        board.lanes.forEach(lane => {
          boardDirectoryIDs.push(lane.directoryId);
        });
        boardDirectoryIDs = [...new Set(boardDirectoryIDs)];
        for(let d = 0; d < boardDirectoryIDs.length; d++) {
          const dirId = boardDirectoryIDs[d];
          let batchReq = [];
          board.lanes.forEach(lane => {
            if(lane.directoryId === dirId) {
              const statusItem = view.data[lane.statusId] || { items: [], total: 0 };
              const req = {
                skip: 0,
                tags: view.tags || [],
                tagsLgOp: 'And',
                keyword: view.keyword,
                keywordLgOp: 'And',
                statusList: [lane.statusId],
                statusLgOp: 'And',
                conditions:[],
                take: (statusItem.items.length <= BOARD_PACKET_SIZE) ? BOARD_PACKET_SIZE : statusItem.items.length,
                orderBy: {
                  field: view.orderBy.field || '$$status-pos$$',
                  asc: view.orderBy.asc,
                }
              };
              batchReq.push({req, statusId: lane.statusId });
            }
          });
          const resp = await http.post(`directories/${dirId}/items/search-batch`, batchReq.map(x => x.req));
          const batchRes =  resp.data;
          batchRes.forEach((res, i) => {
            const  { statusId } = batchReq[i]
            commit('SET_VIEW_DATA', {id, statusId, items: res.items, total: res.total });
          }); 
        }
      }
    } finally {
      commit('SET_VIEW_LOADING', {id, newValue: false});
    }
  }
}
