import {
  createApp
} from "vue";
import App from "./App.vue";
import * as EvaIcons from "@stefandesu/eva-icons-vue"
import * as Boostrap from 'bootstrap'
import router from "./router";
import ActionCableVue from "actioncable-vue";
import VueApexCharts from "vue3-apexcharts";
import vClickOutside from "click-outside-vue3"
import Maska from 'maska'
import moment from 'moment-timezone'
import 'moment/locale/uk';
import VueClipboard from 'vue3-clipboard'

import 'sweetalert2/dist/sweetalert2.min.css';
import '@vueform/slider/themes/default.css';
import './assets/scss/custom/plugins/mermaid.min.css'

import store from "./state/store";

import "./assets/scss/app.scss";


import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { createI18n } from 'vue-i18n'

import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import * as faSolid from '@fortawesome/free-solid-svg-icons'
import * as faRegular from '@fortawesome/free-regular-svg-icons'


/* add icons to the library */
library.add(
  faSolid.faCaretDown, faSolid.faArrowDownAZ, faSolid.faArrowUpZA, faSolid.faMagnifyingGlass, faSolid.faPlus, faSolid.faFilter, faSolid.faChalkboard, faSolid.faListUl,
  faSolid.faTag, faSolid.faScrewdriverWrench, faSolid.faPen, faSolid.faTableCells, faSolid.faTrashCan, faSolid.faXmark, faSolid.faFloppyDisk, faSolid.faSpinner,
  faSolid.faMap, faSolid.faDatabase, faSolid.faListCheck, faSolid.faList, faSolid.faListUl, faSolid.faUsersGear, faSolid.faCircleNodes, faSolid.faUserGear, faSolid.faGears,
  faSolid.faXmark, faSolid.faSave, faSolid.faCheck, faSolid.faFilePdf, faSolid.faDownload, faSolid.faIdCardClip, faSolid.faGripVertical, faSolid.faChevronLeft, faSolid.faChevronRight,
  faSolid.faWandMagicSparkles, faSolid.faLink, faSolid.faPrint, faSolid.faFileWord, faSolid.faCamera, faSolid.faFileExport, faSolid.faBarChart,
  faRegular.faCheckSquare, faRegular.faSquare)


const websocketOptions = {
  debug: true,
  debugLevel: "error",
  connectionUrl: process.env.VUE_APP_SIGNALR_URL,
  connectImmediately: false
};

const app = createApp(App);

window.boostrap = Boostrap;
let appReadyHandlers = [];
window.subscribeAppEvents = function(handler) {
  appReadyHandlers = [...appReadyHandlers, handler];
};

window.unsubscribeAppEvents = function(handler) {
  appReadyHandlers = [...appReadyHandlers.filter(x => x !== handler)];
};

window.sendAppEvent = function(name, args) {
  appReadyHandlers.forEach(x => x(name, args));
};

app.config.globalProperties.$filters = {
  truncate(text, length, clamp) {
    clamp = clamp || '...';
    var node = document.createElement('div');
    node.innerHTML = text;
    var content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
  },


  dateOnly(val){
    if(val) {
      return moment(val, "YYYY-MM-DD").format("L");
    } 
    return '';
  },
  timeAgo(date) {
    return moment(date).fromNow()
  },
  shortDate(date) {
    return moment(date).format("L")
  },
  timeAndDate(date) {
    return moment(date).format("L LT")
  },
  timeOnly(date) {
    if(!date)
      return '';
    return moment(date).format("LT")
  },
  formatTimeOnly(time) {
    if(!time)
      return '';
    var formatted = moment(time, "HH:mm:ss.ms").format("LT");
    return formatted;
  }
}

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const i18n = createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})

import CustomField from '@/components/custom-fields/custom-field.vue';
import CustomFieldEdit from '@/components/custom-fields/custom-field-edit.vue';
import CustomFieldFilter from '@/components/custom-fields/custom-field-filter.vue';

import { dragscrollNext } from "vue-dragscroll";

import plugins from './plugins/index'

moment.locale('uk');

console.log('moment.locale', moment.locale());

app.use(plugins, {

  })
  .use(store)
  .use(EvaIcons)
  .use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
  })
  .use(i18n)
  .use(router)
  .use(VueApexCharts)
  .use(VueToast)
  .use(vClickOutside)
  .use(Maska)
  .use(ActionCableVue, websocketOptions)
  .directive('dragscroll', dragscrollNext)
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('CustomField', CustomField)
  .component('CustomFieldEdit', CustomFieldEdit)
  .component('CustomFieldFilter', CustomFieldFilter)
  .mount("#app");