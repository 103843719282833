export function queryBuilderValueObject() {
  
    const o = {
        stringValue: '',
        dateValue: null,
        numberValue: null,
        statusList: [],
        dirValue: null
    };
    
    return o;
} 

export function queryBuilderDefaultQueryObj() {
    return {
        keyword: null,
        keywordLgOp: 'And',
        tags: [],
        tagsLgOp: 'And',
        statusList: [],
        statusLgOp: 'And',
        conditions: [],
    };
} 


export function isCustomFieldEmpty(val) {
   
    return !val
        || val.length == 0;
}

export function isCustomFieldDtoEmpty(dto) {
   
    return !dto.stringValue
        && !dto.dateValue
        && !dto.numberValue
        && (!dto.table || dto.table.length === 0)
        && !dto.addressValue;
}

export function convertCustomFieldToDto(customFields, input) {
    const res = {};
    customFields.forEach(customField => {
      const val = input[customField.id];
      if(val !== null && val !== '' && val !== undefined) {
        if(customField.definition.type === 'String' || customField.definition.type === 'Text' || customField.definition.type === 'Dropdown' || customField.definition.type === 'Link') {
            res[customField.id] = { stringValue: val };
        } else if(customField.definition.type === 'Date') {
            res[customField.id] = { dateValue: val };
        } else if(customField.definition.type === 'Number') {
            res[customField.id] = { numberValue: val };
        }else if(customField.definition.type === 'Directory') {
            if(val) {
                res[customField.id] = { stringValue: val.id};
            }
        } else if(customField.definition.type === 'Table') {
            var tableCustomFieldDefs =customField.definition.tableFields.map(x => {
                return {
                    id: x.id,
                    definition: x
                };
            });
            var tableCustomFields = [];
            val.forEach(tableRecord => {
                var tableRecordCustomFields = convertCustomFieldToDto(tableCustomFieldDefs, tableRecord);
                if(tableRecord['$_ID']) {
                    tableRecordCustomFields['$_ID'] = { stringValue: tableRecord['$_ID'] };
                }
                tableCustomFields.push(tableRecordCustomFields);
            });
           
            res[customField.id] = { table: tableCustomFields};
        } else if(customField.definition.type === 'Address') {
            res[customField.id] = { addressValue: val };
        }
      }
    });
    return res;
}

export function convertFieldToDto(customFields, input) {
    
    const res = {};
    customFields.forEach(customField => {
      const val = input[customField.id];
      if(val !== null && val !== '' && val !== undefined) {
        if(customField.type === 'String' || customField.type === 'PhoneNumber' || customField.type === 'Text' || customField.type === 'Dropdown' || customField.type === 'Link') {
            res[customField.id] = { stringValue: val };
        } else if(customField.type === 'Date') {
            res[customField.id] = { dateValue: val };
        } else if(customField.type === 'Number') {
            res[customField.id] = { numberValue: val };
        }else if(customField.type === 'Directory') {
            if(val) {
                res[customField.id] = { stringValue: val.id};
            }
        } else if(customField.type === 'Table') {
            var tableCustomFieldDefs =customField.tableFields;
            var tableCustomFields = [];
            val.forEach(tableRecord => {
                var tableRecordCustomFields = convertFieldToDto(tableCustomFieldDefs, tableRecord);
                if(tableRecord['$_ID']) {
                    tableRecordCustomFields['$_ID'] = { stringValue: tableRecord['$_ID'] };
                }
                tableCustomFields.push(tableRecordCustomFields);
            })
            res[customField.id] = { table: tableCustomFields};
        } else if(customField.type === 'Address') {
            res[customField.id] = { addressValue: val };
        }
      }
    });
   
    return res;
}

export function parseErrors (error) {
    if(error.response.status === 500){
        return [error.response.data.Message || error.response.data.message];
    }
    let a = [];
    const errors =  (error.response.data || {}).errors || error.response.data;
    if(Array.isArray (errors)) {
        a = [...errors];
    } else{
        for (var key in errors) {
            for (var e in errors[key]) {
                a.push(errors[key][e])
            }
        }
    }
    
    return a;
}

export const customFieldTypeName = {
    'String': 'String',
    'Text': 'Text',
    'Date': 'Date',
    'Dropdown': 'Dropdown',
    'Number': 'Number',
    'Link': 'Link',
    'File': 'File',
    'Directory': 'Directory',
    'Table': 'Table',
    'DirectoryRelatedField': 'DirectoryRelatedField',
    'Address': 'Address',
    'PhoneNumber': 'PhoneNumber'
};

export function gmapMarkers() {
    return [
        { path: 'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2_hdpi.png', value: null },
        { path: '/gmapmarkers/blue_MarkerA.png', value: '/gmapmarkers/blue_MarkerA.png' },
        { path: '/gmapmarkers/blue_MarkerB.png', value: '/gmapmarkers/blue_MarkerB.png' },
        { path: '/gmapmarkers/blue_MarkerC.png', value: '/gmapmarkers/blue_MarkerC.png' },
        { path: '/gmapmarkers/blue_MarkerD.png', value: '/gmapmarkers/blue_MarkerD.png' },
        { path: '/gmapmarkers/blue_MarkerE.png', value: '/gmapmarkers/blue_MarkerE.png' },
        { path: '/gmapmarkers/blue_MarkerF.png', value: '/gmapmarkers/blue_MarkerF.png' },
        { path: '/gmapmarkers/blue_MarkerG.png', value: '/gmapmarkers/blue_MarkerG.png' },
        { path: '/gmapmarkers/blue_MarkerH.png', value: '/gmapmarkers/blue_MarkerH.png' },
        { path: '/gmapmarkers/blue_MarkerI.png', value: '/gmapmarkers/blue_MarkerI.png' },
        { path: '/gmapmarkers/blue_MarkerJ.png', value: '/gmapmarkers/blue_MarkerJ.png' },
        { path: '/gmapmarkers/blue_MarkerK.png', value: '/gmapmarkers/blue_MarkerK.png' },
        { path: '/gmapmarkers/blue_MarkerL.png', value: '/gmapmarkers/blue_MarkerL.png' },
        { path: '/gmapmarkers/blue_MarkerM.png', value: '/gmapmarkers/blue_MarkerM.png' },
        { path: '/gmapmarkers/blue_MarkerN.png', value: '/gmapmarkers/blue_MarkerN.png' },
        { path: '/gmapmarkers/blue_MarkerO.png', value: '/gmapmarkers/blue_MarkerO.png' },
        { path: '/gmapmarkers/blue_MarkerP.png', value: '/gmapmarkers/blue_MarkerP.png' },
        { path: '/gmapmarkers/blue_MarkerQ.png', value: '/gmapmarkers/blue_MarkerQ.png' },
        { path: '/gmapmarkers/blue_MarkerS.png', value: '/gmapmarkers/blue_MarkerS.png' },
        { path: '/gmapmarkers/blue_MarkerT.png', value: '/gmapmarkers/blue_MarkerT.png' },
        { path: '/gmapmarkers/blue_MarkerU.png', value: '/gmapmarkers/blue_MarkerU.png' },
        { path: '/gmapmarkers/blue_MarkerW.png', value: '/gmapmarkers/blue_MarkerW.png' },
        { path: '/gmapmarkers/blue_MarkerX.png', value: '/gmapmarkers/blue_MarkerX.png' },
        { path: '/gmapmarkers/blue_MarkerY.png', value: '/gmapmarkers/blue_MarkerY.png' },
        { path: '/gmapmarkers/blue_MarkerZ.png', value: '/gmapmarkers/blue_MarkerZ.png' },

        { path: '/gmapmarkers/brown_MarkerA.png', value: '/gmapmarkers/brown_MarkerA.png' },
        { path: '/gmapmarkers/brown_MarkerB.png', value: '/gmapmarkers/brown_MarkerB.png' },
        { path: '/gmapmarkers/brown_MarkerC.png', value: '/gmapmarkers/brown_MarkerC.png' },
        { path: '/gmapmarkers/brown_MarkerD.png', value: '/gmapmarkers/brown_MarkerD.png' },
        { path: '/gmapmarkers/brown_MarkerE.png', value: '/gmapmarkers/brown_MarkerE.png' },
        { path: '/gmapmarkers/brown_MarkerF.png', value: '/gmapmarkers/brown_MarkerF.png' },
        { path: '/gmapmarkers/brown_MarkerG.png', value: '/gmapmarkers/brown_MarkerG.png' },
        { path: '/gmapmarkers/brown_MarkerH.png', value: '/gmapmarkers/brown_MarkerH.png' },
        { path: '/gmapmarkers/brown_MarkerI.png', value: '/gmapmarkers/brown_MarkerI.png' },
        { path: '/gmapmarkers/brown_MarkerJ.png', value: '/gmapmarkers/brown_MarkerJ.png' },
        { path: '/gmapmarkers/brown_MarkerK.png', value: '/gmapmarkers/brown_MarkerK.png' },
        { path: '/gmapmarkers/brown_MarkerL.png', value: '/gmapmarkers/brown_MarkerL.png' },
        { path: '/gmapmarkers/brown_MarkerM.png', value: '/gmapmarkers/brown_MarkerM.png' },
        { path: '/gmapmarkers/brown_MarkerN.png', value: '/gmapmarkers/brown_MarkerN.png' },
        { path: '/gmapmarkers/brown_MarkerO.png', value: '/gmapmarkers/brown_MarkerO.png' },
        { path: '/gmapmarkers/brown_MarkerP.png', value: '/gmapmarkers/brown_MarkerP.png' },
        { path: '/gmapmarkers/brown_MarkerQ.png', value: '/gmapmarkers/brown_MarkerQ.png' },
        { path: '/gmapmarkers/brown_MarkerS.png', value: '/gmapmarkers/brown_MarkerS.png' },
        { path: '/gmapmarkers/brown_MarkerT.png', value: '/gmapmarkers/brown_MarkerT.png' },
        { path: '/gmapmarkers/brown_MarkerU.png', value: '/gmapmarkers/brown_MarkerU.png' },
        { path: '/gmapmarkers/brown_MarkerW.png', value: '/gmapmarkers/brown_MarkerW.png' },
        { path: '/gmapmarkers/brown_MarkerX.png', value: '/gmapmarkers/brown_MarkerX.png' },
        { path: '/gmapmarkers/brown_MarkerY.png', value: '/gmapmarkers/brown_MarkerY.png' },
        { path: '/gmapmarkers/brown_MarkerZ.png', value: '/gmapmarkers/brown_MarkerZ.png' },

        { path: '/gmapmarkers/darkgreen_MarkerA.png', value: '/gmapmarkers/darkgreen_MarkerA.png' },
        { path: '/gmapmarkers/darkgreen_MarkerB.png', value: '/gmapmarkers/darkgreen_MarkerB.png' },
        { path: '/gmapmarkers/darkgreen_MarkerC.png', value: '/gmapmarkers/darkgreen_MarkerC.png' },
        { path: '/gmapmarkers/darkgreen_MarkerD.png', value: '/gmapmarkers/darkgreen_MarkerD.png' },
        { path: '/gmapmarkers/darkgreen_MarkerE.png', value: '/gmapmarkers/darkgreen_MarkerE.png' },
        { path: '/gmapmarkers/darkgreen_MarkerF.png', value: '/gmapmarkers/darkgreen_MarkerF.png' },
        { path: '/gmapmarkers/darkgreen_MarkerG.png', value: '/gmapmarkers/darkgreen_MarkerG.png' },
        { path: '/gmapmarkers/darkgreen_MarkerH.png', value: '/gmapmarkers/darkgreen_MarkerH.png' },
        { path: '/gmapmarkers/darkgreen_MarkerI.png', value: '/gmapmarkers/darkgreen_MarkerI.png' },
        { path: '/gmapmarkers/darkgreen_MarkerJ.png', value: '/gmapmarkers/darkgreen_MarkerJ.png' },
        { path: '/gmapmarkers/darkgreen_MarkerK.png', value: '/gmapmarkers/darkgreen_MarkerK.png' },
        { path: '/gmapmarkers/darkgreen_MarkerL.png', value: '/gmapmarkers/darkgreen_MarkerL.png' },
        { path: '/gmapmarkers/darkgreen_MarkerM.png', value: '/gmapmarkers/darkgreen_MarkerM.png' },
        { path: '/gmapmarkers/darkgreen_MarkerN.png', value: '/gmapmarkers/darkgreen_MarkerN.png' },
        { path: '/gmapmarkers/darkgreen_MarkerO.png', value: '/gmapmarkers/darkgreen_MarkerO.png' },
        { path: '/gmapmarkers/darkgreen_MarkerP.png', value: '/gmapmarkers/darkgreen_MarkerP.png' },
        { path: '/gmapmarkers/darkgreen_MarkerQ.png', value: '/gmapmarkers/darkgreen_MarkerQ.png' },
        { path: '/gmapmarkers/darkgreen_MarkerS.png', value: '/gmapmarkers/darkgreen_MarkerS.png' },
        { path: '/gmapmarkers/darkgreen_MarkerT.png', value: '/gmapmarkers/darkgreen_MarkerT.png' },
        { path: '/gmapmarkers/darkgreen_MarkerU.png', value: '/gmapmarkers/darkgreen_MarkerU.png' },
        { path: '/gmapmarkers/darkgreen_MarkerW.png', value: '/gmapmarkers/darkgreen_MarkerW.png' },
        { path: '/gmapmarkers/darkgreen_MarkerX.png', value: '/gmapmarkers/darkgreen_MarkerX.png' },
        { path: '/gmapmarkers/darkgreen_MarkerY.png', value: '/gmapmarkers/darkgreen_MarkerY.png' },
        { path: '/gmapmarkers/darkgreen_MarkerZ.png', value: '/gmapmarkers/darkgreen_MarkerZ.png' },

        { path: '/gmapmarkers/green_MarkerA.png', value: '/gmapmarkers/green_MarkerA.png' },
        { path: '/gmapmarkers/green_MarkerB.png', value: '/gmapmarkers/green_MarkerB.png' },
        { path: '/gmapmarkers/green_MarkerC.png', value: '/gmapmarkers/green_MarkerC.png' },
        { path: '/gmapmarkers/green_MarkerD.png', value: '/gmapmarkers/green_MarkerD.png' },
        { path: '/gmapmarkers/green_MarkerE.png', value: '/gmapmarkers/green_MarkerE.png' },
        { path: '/gmapmarkers/green_MarkerF.png', value: '/gmapmarkers/green_MarkerF.png' },
        { path: '/gmapmarkers/green_MarkerG.png', value: '/gmapmarkers/green_MarkerG.png' },
        { path: '/gmapmarkers/green_MarkerH.png', value: '/gmapmarkers/green_MarkerH.png' },
        { path: '/gmapmarkers/green_MarkerI.png', value: '/gmapmarkers/green_MarkerI.png' },
        { path: '/gmapmarkers/green_MarkerJ.png', value: '/gmapmarkers/green_MarkerJ.png' },
        { path: '/gmapmarkers/green_MarkerK.png', value: '/gmapmarkers/green_MarkerK.png' },
        { path: '/gmapmarkers/green_MarkerL.png', value: '/gmapmarkers/green_MarkerL.png' },
        { path: '/gmapmarkers/green_MarkerM.png', value: '/gmapmarkers/green_MarkerM.png' },
        { path: '/gmapmarkers/green_MarkerN.png', value: '/gmapmarkers/green_MarkerN.png' },
        { path: '/gmapmarkers/green_MarkerO.png', value: '/gmapmarkers/green_MarkerO.png' },
        { path: '/gmapmarkers/green_MarkerP.png', value: '/gmapmarkers/green_MarkerP.png' },
        { path: '/gmapmarkers/green_MarkerQ.png', value: '/gmapmarkers/green_MarkerQ.png' },
        { path: '/gmapmarkers/green_MarkerS.png', value: '/gmapmarkers/green_MarkerS.png' },
        { path: '/gmapmarkers/green_MarkerT.png', value: '/gmapmarkers/green_MarkerT.png' },
        { path: '/gmapmarkers/green_MarkerU.png', value: '/gmapmarkers/green_MarkerU.png' },
        { path: '/gmapmarkers/green_MarkerW.png', value: '/gmapmarkers/green_MarkerW.png' },
        { path: '/gmapmarkers/green_MarkerX.png', value: '/gmapmarkers/green_MarkerX.png' },
        { path: '/gmapmarkers/green_MarkerY.png', value: '/gmapmarkers/green_MarkerY.png' },
        { path: '/gmapmarkers/green_MarkerZ.png', value: '/gmapmarkers/green_MarkerZ.png' },

        { path: '/gmapmarkers/orange_MarkerA.png', value: '/gmapmarkers/orange_MarkerA.png' },
        { path: '/gmapmarkers/orange_MarkerB.png', value: '/gmapmarkers/orange_MarkerB.png' },
        { path: '/gmapmarkers/orange_MarkerC.png', value: '/gmapmarkers/orange_MarkerC.png' },
        { path: '/gmapmarkers/orange_MarkerD.png', value: '/gmapmarkers/orange_MarkerD.png' },
        { path: '/gmapmarkers/orange_MarkerE.png', value: '/gmapmarkers/orange_MarkerE.png' },
        { path: '/gmapmarkers/orange_MarkerF.png', value: '/gmapmarkers/orange_MarkerF.png' },
        { path: '/gmapmarkers/orange_MarkerG.png', value: '/gmapmarkers/orange_MarkerG.png' },
        { path: '/gmapmarkers/orange_MarkerH.png', value: '/gmapmarkers/orange_MarkerH.png' },
        { path: '/gmapmarkers/orange_MarkerI.png', value: '/gmapmarkers/orange_MarkerI.png' },
        { path: '/gmapmarkers/orange_MarkerJ.png', value: '/gmapmarkers/orange_MarkerJ.png' },
        { path: '/gmapmarkers/orange_MarkerK.png', value: '/gmapmarkers/orange_MarkerK.png' },
        { path: '/gmapmarkers/orange_MarkerL.png', value: '/gmapmarkers/orange_MarkerL.png' },
        { path: '/gmapmarkers/orange_MarkerM.png', value: '/gmapmarkers/orange_MarkerM.png' },
        { path: '/gmapmarkers/orange_MarkerN.png', value: '/gmapmarkers/orange_MarkerN.png' },
        { path: '/gmapmarkers/orange_MarkerO.png', value: '/gmapmarkers/orange_MarkerO.png' },
        { path: '/gmapmarkers/orange_MarkerP.png', value: '/gmapmarkers/orange_MarkerP.png' },
        { path: '/gmapmarkers/orange_MarkerQ.png', value: '/gmapmarkers/orange_MarkerQ.png' },
        { path: '/gmapmarkers/orange_MarkerS.png', value: '/gmapmarkers/orange_MarkerS.png' },
        { path: '/gmapmarkers/orange_MarkerT.png', value: '/gmapmarkers/orange_MarkerT.png' },
        { path: '/gmapmarkers/orange_MarkerU.png', value: '/gmapmarkers/orange_MarkerU.png' },
        { path: '/gmapmarkers/orange_MarkerW.png', value: '/gmapmarkers/orange_MarkerW.png' },
        { path: '/gmapmarkers/orange_MarkerX.png', value: '/gmapmarkers/orange_MarkerX.png' },
        { path: '/gmapmarkers/orange_MarkerY.png', value: '/gmapmarkers/orange_MarkerY.png' },
        { path: '/gmapmarkers/orange_MarkerZ.png', value: '/gmapmarkers/orange_MarkerZ.png' },

        { path: '/gmapmarkers/paleblue_MarkerA.png', value: '/gmapmarkers/paleblue_MarkerA.png' },
        { path: '/gmapmarkers/paleblue_MarkerB.png', value: '/gmapmarkers/paleblue_MarkerB.png' },
        { path: '/gmapmarkers/paleblue_MarkerC.png', value: '/gmapmarkers/paleblue_MarkerC.png' },
        { path: '/gmapmarkers/paleblue_MarkerD.png', value: '/gmapmarkers/paleblue_MarkerD.png' },
        { path: '/gmapmarkers/paleblue_MarkerE.png', value: '/gmapmarkers/paleblue_MarkerE.png' },
        { path: '/gmapmarkers/paleblue_MarkerF.png', value: '/gmapmarkers/paleblue_MarkerF.png' },
        { path: '/gmapmarkers/paleblue_MarkerG.png', value: '/gmapmarkers/paleblue_MarkerG.png' },
        { path: '/gmapmarkers/paleblue_MarkerH.png', value: '/gmapmarkers/paleblue_MarkerH.png' },
        { path: '/gmapmarkers/paleblue_MarkerI.png', value: '/gmapmarkers/paleblue_MarkerI.png' },
        { path: '/gmapmarkers/paleblue_MarkerJ.png', value: '/gmapmarkers/paleblue_MarkerJ.png' },
        { path: '/gmapmarkers/paleblue_MarkerK.png', value: '/gmapmarkers/paleblue_MarkerK.png' },
        { path: '/gmapmarkers/paleblue_MarkerL.png', value: '/gmapmarkers/paleblue_MarkerL.png' },
        { path: '/gmapmarkers/paleblue_MarkerM.png', value: '/gmapmarkers/paleblue_MarkerM.png' },
        { path: '/gmapmarkers/paleblue_MarkerN.png', value: '/gmapmarkers/paleblue_MarkerN.png' },
        { path: '/gmapmarkers/paleblue_MarkerO.png', value: '/gmapmarkers/paleblue_MarkerO.png' },
        { path: '/gmapmarkers/paleblue_MarkerP.png', value: '/gmapmarkers/paleblue_MarkerP.png' },
        { path: '/gmapmarkers/paleblue_MarkerQ.png', value: '/gmapmarkers/paleblue_MarkerQ.png' },
        { path: '/gmapmarkers/paleblue_MarkerS.png', value: '/gmapmarkers/paleblue_MarkerS.png' },
        { path: '/gmapmarkers/paleblue_MarkerT.png', value: '/gmapmarkers/paleblue_MarkerT.png' },
        { path: '/gmapmarkers/paleblue_MarkerU.png', value: '/gmapmarkers/paleblue_MarkerU.png' },
        { path: '/gmapmarkers/paleblue_MarkerW.png', value: '/gmapmarkers/paleblue_MarkerW.png' },
        { path: '/gmapmarkers/paleblue_MarkerX.png', value: '/gmapmarkers/paleblue_MarkerX.png' },
        { path: '/gmapmarkers/paleblue_MarkerY.png', value: '/gmapmarkers/paleblue_MarkerY.png' },
        { path: '/gmapmarkers/paleblue_MarkerZ.png', value: '/gmapmarkers/paleblue_MarkerZ.png' },

        { path: '/gmapmarkers/pink_MarkerA.png', value: '/gmapmarkers/pink_MarkerA.png' },
        { path: '/gmapmarkers/pink_MarkerB.png', value: '/gmapmarkers/pink_MarkerB.png' },
        { path: '/gmapmarkers/pink_MarkerC.png', value: '/gmapmarkers/pink_MarkerC.png' },
        { path: '/gmapmarkers/pink_MarkerD.png', value: '/gmapmarkers/pink_MarkerD.png' },
        { path: '/gmapmarkers/pink_MarkerE.png', value: '/gmapmarkers/pink_MarkerE.png' },
        { path: '/gmapmarkers/pink_MarkerF.png', value: '/gmapmarkers/pink_MarkerF.png' },
        { path: '/gmapmarkers/pink_MarkerG.png', value: '/gmapmarkers/pink_MarkerG.png' },
        { path: '/gmapmarkers/pink_MarkerH.png', value: '/gmapmarkers/pink_MarkerH.png' },
        { path: '/gmapmarkers/pink_MarkerI.png', value: '/gmapmarkers/pink_MarkerI.png' },
        { path: '/gmapmarkers/pink_MarkerJ.png', value: '/gmapmarkers/pink_MarkerJ.png' },
        { path: '/gmapmarkers/pink_MarkerK.png', value: '/gmapmarkers/pink_MarkerK.png' },
        { path: '/gmapmarkers/pink_MarkerL.png', value: '/gmapmarkers/pink_MarkerL.png' },
        { path: '/gmapmarkers/pink_MarkerM.png', value: '/gmapmarkers/pink_MarkerM.png' },
        { path: '/gmapmarkers/pink_MarkerN.png', value: '/gmapmarkers/pink_MarkerN.png' },
        { path: '/gmapmarkers/pink_MarkerO.png', value: '/gmapmarkers/pink_MarkerO.png' },
        { path: '/gmapmarkers/pink_MarkerP.png', value: '/gmapmarkers/pink_MarkerP.png' },
        { path: '/gmapmarkers/pink_MarkerQ.png', value: '/gmapmarkers/pink_MarkerQ.png' },
        { path: '/gmapmarkers/pink_MarkerS.png', value: '/gmapmarkers/pink_MarkerS.png' },
        { path: '/gmapmarkers/pink_MarkerT.png', value: '/gmapmarkers/pink_MarkerT.png' },
        { path: '/gmapmarkers/pink_MarkerU.png', value: '/gmapmarkers/pink_MarkerU.png' },
        { path: '/gmapmarkers/pink_MarkerW.png', value: '/gmapmarkers/pink_MarkerW.png' },
        { path: '/gmapmarkers/pink_MarkerX.png', value: '/gmapmarkers/pink_MarkerX.png' },
        { path: '/gmapmarkers/pink_MarkerY.png', value: '/gmapmarkers/pink_MarkerY.png' },
        { path: '/gmapmarkers/pink_MarkerZ.png', value: '/gmapmarkers/pink_MarkerZ.png' },

        { path: '/gmapmarkers/purple_MarkerA.png', value: '/gmapmarkers/purple_MarkerA.png' },
        { path: '/gmapmarkers/purple_MarkerB.png', value: '/gmapmarkers/purple_MarkerB.png' },
        { path: '/gmapmarkers/purple_MarkerC.png', value: '/gmapmarkers/purple_MarkerC.png' },
        { path: '/gmapmarkers/purple_MarkerD.png', value: '/gmapmarkers/purple_MarkerD.png' },
        { path: '/gmapmarkers/purple_MarkerE.png', value: '/gmapmarkers/purple_MarkerE.png' },
        { path: '/gmapmarkers/purple_MarkerF.png', value: '/gmapmarkers/purple_MarkerF.png' },
        { path: '/gmapmarkers/purple_MarkerG.png', value: '/gmapmarkers/purple_MarkerG.png' },
        { path: '/gmapmarkers/purple_MarkerH.png', value: '/gmapmarkers/purple_MarkerH.png' },
        { path: '/gmapmarkers/purple_MarkerI.png', value: '/gmapmarkers/purple_MarkerI.png' },
        { path: '/gmapmarkers/purple_MarkerJ.png', value: '/gmapmarkers/purple_MarkerJ.png' },
        { path: '/gmapmarkers/purple_MarkerK.png', value: '/gmapmarkers/purple_MarkerK.png' },
        { path: '/gmapmarkers/purple_MarkerL.png', value: '/gmapmarkers/purple_MarkerL.png' },
        { path: '/gmapmarkers/purple_MarkerM.png', value: '/gmapmarkers/purple_MarkerM.png' },
        { path: '/gmapmarkers/purple_MarkerN.png', value: '/gmapmarkers/purple_MarkerN.png' },
        { path: '/gmapmarkers/purple_MarkerO.png', value: '/gmapmarkers/purple_MarkerO.png' },
        { path: '/gmapmarkers/purple_MarkerP.png', value: '/gmapmarkers/purple_MarkerP.png' },
        { path: '/gmapmarkers/purple_MarkerQ.png', value: '/gmapmarkers/purple_MarkerQ.png' },
        { path: '/gmapmarkers/purple_MarkerS.png', value: '/gmapmarkers/purple_MarkerS.png' },
        { path: '/gmapmarkers/purple_MarkerT.png', value: '/gmapmarkers/purple_MarkerT.png' },
        { path: '/gmapmarkers/purple_MarkerU.png', value: '/gmapmarkers/purple_MarkerU.png' },
        { path: '/gmapmarkers/purple_MarkerW.png', value: '/gmapmarkers/purple_MarkerW.png' },
        { path: '/gmapmarkers/purple_MarkerX.png', value: '/gmapmarkers/purple_MarkerX.png' },
        { path: '/gmapmarkers/purple_MarkerY.png', value: '/gmapmarkers/purple_MarkerY.png' },
        { path: '/gmapmarkers/purple_MarkerZ.png', value: '/gmapmarkers/purple_MarkerZ.png' },

        { path: '/gmapmarkers/red_MarkerA.png', value: '/gmapmarkers/red_MarkerA.png' },
        { path: '/gmapmarkers/red_MarkerB.png', value: '/gmapmarkers/red_MarkerB.png' },
        { path: '/gmapmarkers/red_MarkerC.png', value: '/gmapmarkers/red_MarkerC.png' },
        { path: '/gmapmarkers/red_MarkerD.png', value: '/gmapmarkers/red_MarkerD.png' },
        { path: '/gmapmarkers/red_MarkerE.png', value: '/gmapmarkers/red_MarkerE.png' },
        { path: '/gmapmarkers/red_MarkerF.png', value: '/gmapmarkers/red_MarkerF.png' },
        { path: '/gmapmarkers/red_MarkerG.png', value: '/gmapmarkers/red_MarkerG.png' },
        { path: '/gmapmarkers/red_MarkerH.png', value: '/gmapmarkers/red_MarkerH.png' },
        { path: '/gmapmarkers/red_MarkerI.png', value: '/gmapmarkers/red_MarkerI.png' },
        { path: '/gmapmarkers/red_MarkerJ.png', value: '/gmapmarkers/red_MarkerJ.png' },
        { path: '/gmapmarkers/red_MarkerK.png', value: '/gmapmarkers/red_MarkerK.png' },
        { path: '/gmapmarkers/red_MarkerL.png', value: '/gmapmarkers/red_MarkerL.png' },
        { path: '/gmapmarkers/red_MarkerM.png', value: '/gmapmarkers/red_MarkerM.png' },
        { path: '/gmapmarkers/red_MarkerN.png', value: '/gmapmarkers/red_MarkerN.png' },
        { path: '/gmapmarkers/red_MarkerO.png', value: '/gmapmarkers/red_MarkerO.png' },
        { path: '/gmapmarkers/red_MarkerP.png', value: '/gmapmarkers/red_MarkerP.png' },
        { path: '/gmapmarkers/red_MarkerQ.png', value: '/gmapmarkers/red_MarkerQ.png' },
        { path: '/gmapmarkers/red_MarkerS.png', value: '/gmapmarkers/red_MarkerS.png' },
        { path: '/gmapmarkers/red_MarkerT.png', value: '/gmapmarkers/red_MarkerT.png' },
        { path: '/gmapmarkers/red_MarkerU.png', value: '/gmapmarkers/red_MarkerU.png' },
        { path: '/gmapmarkers/red_MarkerW.png', value: '/gmapmarkers/red_MarkerW.png' },
        { path: '/gmapmarkers/red_MarkerX.png', value: '/gmapmarkers/red_MarkerX.png' },
        { path: '/gmapmarkers/red_MarkerY.png', value: '/gmapmarkers/red_MarkerY.png' },
        { path: '/gmapmarkers/red_MarkerZ.png', value: '/gmapmarkers/red_MarkerZ.png' },

        { path: '/gmapmarkers/yellow_MarkerA.png', value: '/gmapmarkers/yellow_MarkerA.png' },
        { path: '/gmapmarkers/yellow_MarkerB.png', value: '/gmapmarkers/yellow_MarkerB.png' },
        { path: '/gmapmarkers/yellow_MarkerC.png', value: '/gmapmarkers/yellow_MarkerC.png' },
        { path: '/gmapmarkers/yellow_MarkerD.png', value: '/gmapmarkers/yellow_MarkerD.png' },
        { path: '/gmapmarkers/yellow_MarkerE.png', value: '/gmapmarkers/yellow_MarkerE.png' },
        { path: '/gmapmarkers/yellow_MarkerF.png', value: '/gmapmarkers/yellow_MarkerF.png' },
        { path: '/gmapmarkers/yellow_MarkerG.png', value: '/gmapmarkers/yellow_MarkerG.png' },
        { path: '/gmapmarkers/yellow_MarkerH.png', value: '/gmapmarkers/yellow_MarkerH.png' },
        { path: '/gmapmarkers/yellow_MarkerI.png', value: '/gmapmarkers/yellow_MarkerI.png' },
        { path: '/gmapmarkers/yellow_MarkerJ.png', value: '/gmapmarkers/yellow_MarkerJ.png' },
        { path: '/gmapmarkers/yellow_MarkerK.png', value: '/gmapmarkers/yellow_MarkerK.png' },
        { path: '/gmapmarkers/yellow_MarkerL.png', value: '/gmapmarkers/yellow_MarkerL.png' },
        { path: '/gmapmarkers/yellow_MarkerM.png', value: '/gmapmarkers/yellow_MarkerM.png' },
        { path: '/gmapmarkers/yellow_MarkerN.png', value: '/gmapmarkers/yellow_MarkerN.png' },
        { path: '/gmapmarkers/yellow_MarkerO.png', value: '/gmapmarkers/yellow_MarkerO.png' },
        { path: '/gmapmarkers/yellow_MarkerP.png', value: '/gmapmarkers/yellow_MarkerP.png' },
        { path: '/gmapmarkers/yellow_MarkerQ.png', value: '/gmapmarkers/yellow_MarkerQ.png' },
        { path: '/gmapmarkers/yellow_MarkerS.png', value: '/gmapmarkers/yellow_MarkerS.png' },
        { path: '/gmapmarkers/yellow_MarkerT.png', value: '/gmapmarkers/yellow_MarkerT.png' },
        { path: '/gmapmarkers/yellow_MarkerU.png', value: '/gmapmarkers/yellow_MarkerU.png' },
        { path: '/gmapmarkers/yellow_MarkerW.png', value: '/gmapmarkers/yellow_MarkerW.png' },
        { path: '/gmapmarkers/yellow_MarkerX.png', value: '/gmapmarkers/yellow_MarkerX.png' },
        { path: '/gmapmarkers/yellow_MarkerY.png', value: '/gmapmarkers/yellow_MarkerY.png' },
        { path: '/gmapmarkers/yellow_MarkerZ.png', value: '/gmapmarkers/yellow_MarkerZ.png' }
    ];
}

export function countryList() {
    return [ 
        {name: 'Afghanistan', code: 'AF'}, 
        {name: 'Åland Islands', code: 'AX'}, 
        {name: 'Albania', code: 'AL'}, 
        {name: 'Algeria', code: 'DZ'}, 
        {name: 'American Samoa', code: 'AS'}, 
        {name: 'AndorrA', code: 'AD'}, 
        {name: 'Angola', code: 'AO'}, 
        {name: 'Anguilla', code: 'AI'}, 
        {name: 'Antarctica', code: 'AQ'}, 
        {name: 'Antigua and Barbuda', code: 'AG'}, 
        {name: 'Argentina', code: 'AR'}, 
        {name: 'Armenia', code: 'AM'}, 
        {name: 'Aruba', code: 'AW'}, 
        {name: 'Australia', code: 'AU'}, 
        {name: 'Austria', code: 'AT'}, 
        {name: 'Azerbaijan', code: 'AZ'}, 
        {name: 'Bahamas', code: 'BS'}, 
        {name: 'Bahrain', code: 'BH'}, 
        {name: 'Bangladesh', code: 'BD'}, 
        {name: 'Barbados', code: 'BB'}, 
        {name: 'Belarus', code: 'BY'}, 
        {name: 'Belgium', code: 'BE'}, 
        {name: 'Belize', code: 'BZ'}, 
        {name: 'Benin', code: 'BJ'}, 
        {name: 'Bermuda', code: 'BM'}, 
        {name: 'Bhutan', code: 'BT'}, 
        {name: 'Bolivia', code: 'BO'}, 
        {name: 'Bosnia and Herzegovina', code: 'BA'}, 
        {name: 'Botswana', code: 'BW'}, 
        {name: 'Bouvet Island', code: 'BV'}, 
        {name: 'Brazil', code: 'BR'}, 
        {name: 'British Indian Ocean Territory', code: 'IO'}, 
        {name: 'Brunei Darussalam', code: 'BN'}, 
        {name: 'Bulgaria', code: 'BG'}, 
        {name: 'Burkina Faso', code: 'BF'}, 
        {name: 'Burundi', code: 'BI'}, 
        {name: 'Cambodia', code: 'KH'}, 
        {name: 'Cameroon', code: 'CM'}, 
        {name: 'Canada', code: 'CA'}, 
        {name: 'Cape Verde', code: 'CV'}, 
        {name: 'Cayman Islands', code: 'KY'}, 
        {name: 'Central African Republic', code: 'CF'}, 
        {name: 'Chad', code: 'TD'}, 
        {name: 'Chile', code: 'CL'}, 
        {name: 'China', code: 'CN'}, 
        {name: 'Christmas Island', code: 'CX'}, 
        {name: 'Cocos (Keeling) Islands', code: 'CC'}, 
        {name: 'Colombia', code: 'CO'}, 
        {name: 'Comoros', code: 'KM'}, 
        {name: 'Congo', code: 'CG'}, 
        {name: 'Congo, The Democratic Republic of the', code: 'CD'}, 
        {name: 'Cook Islands', code: 'CK'}, 
        {name: 'Costa Rica', code: 'CR'}, 
        {name: 'Cote D\'Ivoire', code: 'CI'}, 
        {name: 'Croatia', code: 'HR'}, 
        {name: 'Cuba', code: 'CU'}, 
        {name: 'Cyprus', code: 'CY'}, 
        {name: 'Czech Republic', code: 'CZ'}, 
        {name: 'Denmark', code: 'DK'}, 
        {name: 'Djibouti', code: 'DJ'}, 
        {name: 'Dominica', code: 'DM'}, 
        {name: 'Dominican Republic', code: 'DO'}, 
        {name: 'Ecuador', code: 'EC'}, 
        {name: 'Egypt', code: 'EG'}, 
        {name: 'El Salvador', code: 'SV'}, 
        {name: 'Equatorial Guinea', code: 'GQ'}, 
        {name: 'Eritrea', code: 'ER'}, 
        {name: 'Estonia', code: 'EE'}, 
        {name: 'Ethiopia', code: 'ET'}, 
        {name: 'Falkland Islands (Malvinas)', code: 'FK'}, 
        {name: 'Faroe Islands', code: 'FO'}, 
        {name: 'Fiji', code: 'FJ'}, 
        {name: 'Finland', code: 'FI'}, 
        {name: 'France', code: 'FR'}, 
        {name: 'French Guiana', code: 'GF'}, 
        {name: 'French Polynesia', code: 'PF'}, 
        {name: 'French Southern Territories', code: 'TF'}, 
        {name: 'Gabon', code: 'GA'}, 
        {name: 'Gambia', code: 'GM'}, 
        {name: 'Georgia', code: 'GE'}, 
        {name: 'Germany', code: 'DE'}, 
        {name: 'Ghana', code: 'GH'}, 
        {name: 'Gibraltar', code: 'GI'}, 
        {name: 'Greece', code: 'GR'}, 
        {name: 'Greenland', code: 'GL'}, 
        {name: 'Grenada', code: 'GD'}, 
        {name: 'Guadeloupe', code: 'GP'}, 
        {name: 'Guam', code: 'GU'}, 
        {name: 'Guatemala', code: 'GT'}, 
        {name: 'Guernsey', code: 'GG'}, 
        {name: 'Guinea', code: 'GN'}, 
        {name: 'Guinea-Bissau', code: 'GW'}, 
        {name: 'Guyana', code: 'GY'}, 
        {name: 'Haiti', code: 'HT'}, 
        {name: 'Heard Island and Mcdonald Islands', code: 'HM'}, 
        {name: 'Holy See (Vatican City State)', code: 'VA'}, 
        {name: 'Honduras', code: 'HN'}, 
        {name: 'Hong Kong', code: 'HK'}, 
        {name: 'Hungary', code: 'HU'}, 
        {name: 'Iceland', code: 'IS'}, 
        {name: 'India', code: 'IN'}, 
        {name: 'Indonesia', code: 'ID'}, 
        {name: 'Iran, Islamic Republic Of', code: 'IR'}, 
        {name: 'Iraq', code: 'IQ'}, 
        {name: 'Ireland', code: 'IE'}, 
        {name: 'Isle of Man', code: 'IM'}, 
        {name: 'Israel', code: 'IL'}, 
        {name: 'Italy', code: 'IT'}, 
        {name: 'Jamaica', code: 'JM'}, 
        {name: 'Japan', code: 'JP'}, 
        {name: 'Jersey', code: 'JE'}, 
        {name: 'Jordan', code: 'JO'}, 
        {name: 'Kazakhstan', code: 'KZ'}, 
        {name: 'Kenya', code: 'KE'}, 
        {name: 'Kiribati', code: 'KI'}, 
        {name: 'Korea, Democratic People\'S Republic of', code: 'KP'}, 
        {name: 'Korea, Republic of', code: 'KR'}, 
        {name: 'Kuwait', code: 'KW'}, 
        {name: 'Kyrgyzstan', code: 'KG'}, 
        {name: 'Lao People\'S Democratic Republic', code: 'LA'}, 
        {name: 'Latvia', code: 'LV'}, 
        {name: 'Lebanon', code: 'LB'}, 
        {name: 'Lesotho', code: 'LS'}, 
        {name: 'Liberia', code: 'LR'}, 
        {name: 'Libyan Arab Jamahiriya', code: 'LY'}, 
        {name: 'Liechtenstein', code: 'LI'}, 
        {name: 'Lithuania', code: 'LT'}, 
        {name: 'Luxembourg', code: 'LU'}, 
        {name: 'Macao', code: 'MO'}, 
        {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'}, 
        {name: 'Madagascar', code: 'MG'}, 
        {name: 'Malawi', code: 'MW'}, 
        {name: 'Malaysia', code: 'MY'}, 
        {name: 'Maldives', code: 'MV'}, 
        {name: 'Mali', code: 'ML'}, 
        {name: 'Malta', code: 'MT'}, 
        {name: 'Marshall Islands', code: 'MH'}, 
        {name: 'Martinique', code: 'MQ'}, 
        {name: 'Mauritania', code: 'MR'}, 
        {name: 'Mauritius', code: 'MU'}, 
        {name: 'Mayotte', code: 'YT'}, 
        {name: 'Mexico', code: 'MX'}, 
        {name: 'Micronesia, Federated States of', code: 'FM'}, 
        {name: 'Moldova, Republic of', code: 'MD'}, 
        {name: 'Monaco', code: 'MC'}, 
        {name: 'Mongolia', code: 'MN'}, 
        {name: 'Montserrat', code: 'MS'}, 
        {name: 'Morocco', code: 'MA'}, 
        {name: 'Mozambique', code: 'MZ'}, 
        {name: 'Myanmar', code: 'MM'}, 
        {name: 'Namibia', code: 'NA'}, 
        {name: 'Nauru', code: 'NR'}, 
        {name: 'Nepal', code: 'NP'}, 
        {name: 'Netherlands', code: 'NL'}, 
        {name: 'Netherlands Antilles', code: 'AN'}, 
        {name: 'New Caledonia', code: 'NC'}, 
        {name: 'New Zealand', code: 'NZ'}, 
        {name: 'Nicaragua', code: 'NI'}, 
        {name: 'Niger', code: 'NE'}, 
        {name: 'Nigeria', code: 'NG'}, 
        {name: 'Niue', code: 'NU'}, 
        {name: 'Norfolk Island', code: 'NF'}, 
        {name: 'Northern Mariana Islands', code: 'MP'}, 
        {name: 'Norway', code: 'NO'}, 
        {name: 'Oman', code: 'OM'}, 
        {name: 'Pakistan', code: 'PK'}, 
        {name: 'Palau', code: 'PW'}, 
        {name: 'Palestinian Territory, Occupied', code: 'PS'}, 
        {name: 'Panama', code: 'PA'}, 
        {name: 'Papua New Guinea', code: 'PG'}, 
        {name: 'Paraguay', code: 'PY'}, 
        {name: 'Peru', code: 'PE'}, 
        {name: 'Philippines', code: 'PH'}, 
        {name: 'Pitcairn', code: 'PN'}, 
        {name: 'Poland', code: 'PL'}, 
        {name: 'Portugal', code: 'PT'}, 
        {name: 'Puerto Rico', code: 'PR'}, 
        {name: 'Qatar', code: 'QA'}, 
        {name: 'Reunion', code: 'RE'}, 
        {name: 'Romania', code: 'RO'}, 
        {name: 'Russian Federation', code: 'RU'}, 
        {name: 'RWANDA', code: 'RW'}, 
        {name: 'Saint Helena', code: 'SH'}, 
        {name: 'Saint Kitts and Nevis', code: 'KN'}, 
        {name: 'Saint Lucia', code: 'LC'}, 
        {name: 'Saint Pierre and Miquelon', code: 'PM'}, 
        {name: 'Saint Vincent and the Grenadines', code: 'VC'}, 
        {name: 'Samoa', code: 'WS'}, 
        {name: 'San Marino', code: 'SM'}, 
        {name: 'Sao Tome and Principe', code: 'ST'}, 
        {name: 'Saudi Arabia', code: 'SA'}, 
        {name: 'Senegal', code: 'SN'}, 
        {name: 'Serbia and Montenegro', code: 'CS'}, 
        {name: 'Seychelles', code: 'SC'}, 
        {name: 'Sierra Leone', code: 'SL'}, 
        {name: 'Singapore', code: 'SG'}, 
        {name: 'Slovakia', code: 'SK'}, 
        {name: 'Slovenia', code: 'SI'}, 
        {name: 'Solomon Islands', code: 'SB'}, 
        {name: 'Somalia', code: 'SO'}, 
        {name: 'South Africa', code: 'ZA'}, 
        {name: 'South Georgia and the South Sandwich Islands', code: 'GS'}, 
        {name: 'Spain', code: 'ES'}, 
        {name: 'Sri Lanka', code: 'LK'}, 
        {name: 'Sudan', code: 'SD'}, 
        {name: 'Suriname', code: 'SR'}, 
        {name: 'Svalbard and Jan Mayen', code: 'SJ'}, 
        {name: 'Swaziland', code: 'SZ'}, 
        {name: 'Sweden', code: 'SE'}, 
        {name: 'Switzerland', code: 'CH'}, 
        {name: 'Syrian Arab Republic', code: 'SY'}, 
        {name: 'Taiwan, Province of China', code: 'TW'}, 
        {name: 'Tajikistan', code: 'TJ'}, 
        {name: 'Tanzania, United Republic of', code: 'TZ'}, 
        {name: 'Thailand', code: 'TH'}, 
        {name: 'Timor-Leste', code: 'TL'}, 
        {name: 'Togo', code: 'TG'}, 
        {name: 'Tokelau', code: 'TK'}, 
        {name: 'Tonga', code: 'TO'}, 
        {name: 'Trinidad and Tobago', code: 'TT'}, 
        {name: 'Tunisia', code: 'TN'}, 
        {name: 'Turkey', code: 'TR'}, 
        {name: 'Turkmenistan', code: 'TM'}, 
        {name: 'Turks and Caicos Islands', code: 'TC'}, 
        {name: 'Tuvalu', code: 'TV'}, 
        {name: 'Uganda', code: 'UG'}, 
        {name: 'Ukraine', code: 'UA'}, 
        {name: 'United Arab Emirates', code: 'AE'}, 
        {name: 'United Kingdom', code: 'GB'}, 
        {name: 'United States', code: 'US'}, 
        {name: 'United States Minor Outlying Islands', code: 'UM'}, 
        {name: 'Uruguay', code: 'UY'}, 
        {name: 'Uzbekistan', code: 'UZ'}, 
        {name: 'Vanuatu', code: 'VU'}, 
        {name: 'Venezuela', code: 'VE'}, 
        {name: 'Viet Nam', code: 'VN'}, 
        {name: 'Virgin Islands, British', code: 'VG'}, 
        {name: 'Virgin Islands, U.S.', code: 'VI'}, 
        {name: 'Wallis and Futuna', code: 'WF'}, 
        {name: 'Western Sahara', code: 'EH'}, 
        {name: 'Yemen', code: 'YE'}, 
        {name: 'Zambia', code: 'ZM'}, 
        {name: 'Zimbabwe', code: 'ZW'} 
      ];
}