import store from "@/state/store";
import rules from './rules';

import vostoksosRules from './vostoksos/rules'
import vostoksosFieldExtenders from './vostoksos/field-extenders'


function createToObject({dir, model} = {}) {
  let res = {
    _orig: model,

    id: model.id,
    status: model.status,
    tags: model.tags,
    number: model.number,
    fields: {

    }
  };
  dir.fields.forEach(fieldDef => {
    res.fields[fieldDef.code || fieldDef.id] = model.customFields[fieldDef.id];
  });
  return res;
}

function createFromObject({dir, obj} = {}) {
  let res = { ...obj._orig };
  res.id = obj.id;
  res.status = obj.status;
  res.tags = obj.tags;
  res.customFields = {};
  dir.fields.forEach(fieldDef => {
    const v = obj.fields[fieldDef.code || fieldDef.id];
    res.customFields[fieldDef.id] = v;
  });
  return res;
}

export default {
    install: (app /*, options */) => {
     // console.log('plugin install', options);

      app.config.globalProperties.$converters = {
        toObject: createToObject,
        fromObject: createFromObject
      };

      app.config.globalProperties.$fieldExtender = (dir, field) => {
        let accountCode = null;
        if(store.state.auth && store.state.auth.currentUser && store.state.auth.currentUser.account) {
          accountCode = store.state.auth.currentUser.account.code;
        }
        let res = { 
          editButtons: [],
          viewButtons: []
        };
        if(accountCode === 'vostoksos') {
          res = vostoksosFieldExtenders(dir, field, res);
        }
        return res;
      };

      app.config.globalProperties.$formRules = () => {
        let accountCode = null;
        if(store.state.auth && store.state.auth.currentUser && store.state.auth.currentUser.account) {
          accountCode = store.state.auth.currentUser.account.code;
        }
        let res = {...rules};
        if(accountCode === 'vostoksos') {
          res = vostoksosRules(res);
        }
       
        return res;
      }
    }
  }