//import store from '@/state/store'


const userManagements = [
 
  {
    path: '/user-groups',
    name: 'user-groups',
    meta: {
      title: 'User Groups',
      authRequired: true,
      isAllow: (auth) => auth.currentUser && auth.currentUser.isAdmin ,
    },
    component: () => import('../views/pages/user-groups/index.vue')
  },
  {
    path: '/users',
    name: 'users',
    meta: {
      title: 'Users',
      authRequired: true,
      isAllow: (auth) => auth.currentUser && auth.currentUser.isAdmin ,
    },
    component: () => import('../views/pages/users/index.vue')
  },
 
  {
    path: '/users/:id/details',
    name: 'user-details',
    meta: {
      title: 'User Details',
      authRequired: true,
      isAllow: (auth) => auth.currentUser && auth.currentUser.isAdmin ,
    },
    component: () => import('../views/pages/users/details.vue'),
    props: true
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/pages/auth/login.vue')
  },
  // {
  //   path: '/register',
  //   name: 'register',
  //   component: () => import('../views/pages/auth/register.vue'),
  //   props: route => Object.assign({}, route.query, route.params)
  // },
  {
    path: '/setnewpassword',
    name: 'setnewpassword',
    component: () => import('../views/pages/auth/setnewpassword.vue'),
    props: route => Object.assign({}, route.query, route.params)
  },
  {
    path: '/recoverpwd',
    name: 'recoverpwd',
    component: () => import('../views/pages/auth/recoverpwd.vue')
  },
  {
    path: '/confirm-mail',
    name: 'confirm-mail',
    component: () => import('../views/pages/auth/confirm-mail.vue')
  },
  {
    path: '/email-verification',
    name: 'email-verification',
    component: () => import('../views/pages/auth/email-verification.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/profile/index.vue')
  }
]

const chat = process.env.VUE_APP_CHAT_ENABLED === 'YES' ? [
  {
    path: '/bot-registration',
    name: 'bot-registration',
    meta: {
      title: 'Bot Registration',
      authRequired: true,
    },
    isAllow: (auth) => auth.currentUser && auth.currentUser.isAdmin && auth.currentUser.account  && auth.currentUser.account.settings.moduleChatBotEnabled,
    component: () => import('../views/pages/chat/bot-registration.vue')
  },
  {
    path: '/bots',
    name: 'bots',
    meta: {
      title: 'Bots',
      authRequired: true,
    },
    isAllow: (auth) => auth.currentUser && auth.currentUser.account  && auth.currentUser.account.settings.moduleChatBotEnabled,
    component: () => import('../views/pages/chat/bots.vue')
  },
 
  {
    path: '/chat',
    name: 'chat',
    meta: {
      title: 'Chat',
      authRequired: true,
    },
    isAllow: (auth) =>  auth.currentUser && auth.currentUser.account && auth.currentUser.account.settings.moduleChatBotEnabled,
    component: () => import('../views/pages/chat/index.vue'),
    props: route => Object.assign({}, route.query, route.params)
  }
] : [];

const automation = [
  {
    path: '/automation',
    name: 'automation',
    meta: {
      title: 'Automation',
      authRequired: true
    },
    component: () => import('../views/pages/automation/index.vue')
  }
]

const reminders = [
  {
    path: '/reminders',
    name: 'reminders',
    meta: {
      title: 'Reminders',
      authRequired: true
    },
    component: () => import('../views/pages/reminders/index.vue')
  }
]

const directories = [
  {
    path: '/directories/:id',
    name: 'directory-edit',
    meta: {
      title: 'Directort Edit',
      authRequired: true,
      isAllow: (auth) => auth && auth.currentUser && auth.currentUser.isAdmin ,
    },
    component: () => import('../views/pages/directories/edit.vue'),
    props: route => Object.assign({}, route.query, route.params)
  },
  {
    path: '/directories',
    name: 'directories',
    meta: {
      title: 'Directories',
      authRequired: true,
      isAllow: (auth) => auth.currentUser && auth.currentUser.isAdmin ,
    },
    component: () => import('../views/pages/directories/index.vue')
  },
  {
    path: '/directories/:type/items',
    name: 'directory-items',
    meta: {
      title: 'Directory Items',
      authRequired: true
    },
    component: () => import('../views/pages/directory-items/index.vue'),
    props: route => Object.assign({}, route.query, route.params)
  }
]

const map = [
  {
    path: '/map',
    name: 'map',
    meta: {
      title: 'Map',
      authRequired: true
    },
    component: () => import('../views/pages/map/index.vue')
  }
]

const files = [
  {
    path: '/files',
    name: 'files',
    meta: {
      title: 'Files',
      authRequired: true
    },
    isAllow: (auth) => auth.currentUser && auth.currentUser.account.settings.moduleFileStorageEnabled,
    component: () => import('../views/pages/files/index.vue')
  }
]

const customFields = [
  {
    path: '/custom-fields/:entity',
    name: 'custom-fields',
    meta: {
      title: 'Custom Fields',
      authRequired: true,
      isAllow: (auth) => auth.currentUser && auth.currentUser.isAdmin,
    },
    component: () => import('../views/pages/custom-fields/index.vue'),
    props: route => Object.assign({}, route.query, route.params)
  },
]

const usageProfiles = [
  {
    path: '/usage-profiles/:id',
    name: 'usage-profile-edit',
    meta: {
      title: 'Usage Profile Edit',
      authRequired: true,
      isAllow: (auth) => auth && auth.currentUser && auth.currentUser.isAdmin ,
    },
    component: () => import('../views/pages/usage-profiles/edit.vue'),
    props: route => Object.assign({}, route.query, route.params)
  },
  {
    path: '/usage-profiles',
    name: 'usage-profiles',
    meta: {
      title: 'Usage Profiles',
      authRequired: true,
      isAllow: (auth) => auth.currentUser && auth.currentUser.isAdmin ,
    },
    component: () => import('../views/pages/usage-profiles/index.vue')
  },
]


const boards = [
  {
    path: '/boards/:id',
    name: 'board-edit',
    meta: {
      title: 'Board Edit',
      authRequired: true,
      isAllow: (auth) => auth && auth.currentUser && auth.currentUser.isAdmin ,
    },
    component: () => import('../views/pages/boards/edit.vue'),
    props: route => Object.assign({}, route.query, route.params)
  },
  {
    path: '/boards',
    name: 'boards',
    meta: {
      title: 'Boards',
      authRequired: true,
      isAllow: (auth) => auth.currentUser && auth.currentUser.isAdmin ,
    },
    component: () => import('../views/pages/boards/index.vue')
  },
  {
    path: '/boards/:id/view',
    name: 'board-view',
    meta: {
      title: 'Board View',
      authRequired: true,
      isAllow: (auth) => auth && auth.currentUser,
    },
    component: () => import('../views/pages/boards/view.vue'),
    props: route => Object.assign({}, route.query, route.params)
  },
]

const settings = [
  {
    path: '/settings',
    name: 'settings',
    meta: {
      title: 'Settings',
      authRequired: true,
      isAllow: (auth) => auth.currentUser && auth.currentUser.isAdmin ,
    },
    component: () => import('../views/pages/settings/index.vue')
  }
]

export default [{
    path: '/',
    name: 'home',
    meta: {
      authRequired: true,
      title: 'Dashboard',
    },
    component: () => import('../views/pages/home/index.vue'),
   // redirect: (/*to*/) => {
    //   const currentUser = (store.state.auth || {}).currentUser;
    //   const account = (currentUser || {}).account;
    //   const settings = (account || {}).settings;
    //   if(settings && settings.subscription === 'Free') {
    //     return { path: '/contacts/board' }
    //   }
    //   return null;
    //   return { path: '/processes-board' };
    //  },
    //component: () => import('../views/pages/home/index.vue')
  },
  {
    path: '/process-reports',
    name: 'process-reports',
    meta: {
      title: 'Process reports',
      authRequired: true,
      isAllow: (auth) => auth.currentUser ,
    },
    component: () => import('../views/pages/reports/process-report-1.vue')
  },
  {
    path: '/request-reports',
    name: 'request-reports',
    meta: {
      title: 'Request reports',
      authRequired: true,
      isAllow: (auth) => auth.currentUser ,
    },
    component: () => import('../views/pages/reports/request-report-1.vue')
  },
  {
    path: '/heat-map-1',
    name: 'heat-map-1',
    meta: {
      title: 'Теплова мапа',
      authRequired: true,
      isAllow: (auth) => auth.currentUser ,
    },
    component: () => import('../views/pages/reports/heat-map-1.vue')
  },
  ...settings,
  ...userManagements,
  ...chat,
  ...automation,
  ...reminders,
  ...directories,
  ...files,
  ...customFields,
  ...map,
  ...usageProfiles,
  ...boards,
  { 
    path: "/:pathMatch(.*)*", 
    redirect: (/*to*/) => {
      return { path: '/' };
    } 
  }
]