import http from '../../app-http'
import { parseErrors} from "@/helpers"

const getDefaultState = () => {
  return {
    loading: false,
    processing: false,
    deleting: null,
    items:[],
  
  }
};

export const state = getDefaultState();

export const mutations = {
 
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  SET_ITEMS(state, newValue) {
    state.items = newValue;
  },
  SET_LOADING(state, newValue) {
    state.loading = newValue;
  },
  SET_PROCESSING(state, newValue) {
    state.processing = newValue;
  },
  SET_DELETING(state, newValue) {
    state.deleting = newValue;
  }
}

export const getters = {
  current(state, getters, rootState, rootGetters) {
    const currentUser = rootGetters['auth/currentUser'];
    const userGroups = currentUser.groups || [];
    return state.items.find(x => x.assignment.find(a => a.target === currentUser.id || userGroups.find(g => g.id === a.target)));
  },
  items (state) {
    return state.items;
  },
  loading(state) {
    return state.loading;
  },
  processing(state) {
    return state.processing;
  },
  deleting(state) {
    return state.deleting;
  },
}

export const actions = {
   create({ dispatch, commit }, { dto } = {}) {
    return new Promise( (resolve, reject) => {
      commit('SET_PROCESSING', true);
      http.post(`usage-profiles`, dto).then(()=>{
        commit('SET_PROCESSING', false);
        dispatch('load');
        resolve();
      }).catch((error) => {
        commit('SET_PROCESSING', false);
        const errors = parseErrors(error);
        reject(errors);
      });
    });
  },
  async update({ dispatch, commit }, {  dto } = {}) {
    return new Promise( (resolve, reject) => {
      commit('SET_PROCESSING', true);
      http.put(`usage-profiles`, dto).then(()=>{
        commit('SET_PROCESSING', false);
        dispatch('load');
        resolve();
      }).catch((error) => {
        commit('SET_PROCESSING', false);
        const errors = parseErrors(error);
        reject(errors);
      });
    });
  },
  delete({ dispatch, commit }, { id } = {}) {
    return new Promise( (resolve, reject) => {
      commit('SET_DELETING', id);
      http.delete(`usage-profiles/` + id).then(()=>{
        commit('SET_DELETING', null);
        dispatch('load');
        resolve();
      }).catch((error) => {
        commit('SET_DELETING', null);
        const errors = parseErrors(error);
        reject(errors);
      });
    });
  },
  load({ commit }) {
    return new Promise( (resolve, reject) => {
      commit('SET_LOADING', true);
      http.get(`usage-profiles`).then((response)=>{
        commit('SET_LOADING', false);
        commit('SET_ITEMS', response.data);
        resolve(response.data);
      }).catch((error) => {
        commit('SET_LOADING', false);
        const errors = parseErrors(error);
        reject(errors);
      });
    });
  },
  reorder({ dispatch, commit, state }, { list } = {}) {
    return new Promise( (resolve, reject) => {
      const o = list.map(id => state.items.find(x => x.id == id));
      commit('SET_ITEMS', o);
      http.post(`usage-profiles/order`, { list }).then(() => {
        dispatch('load');
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  },
}
