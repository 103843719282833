<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import appConfig from "@/app.config";


export default {
  name: "App",
  
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
     // console.log('appConfig', appConfig);
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    },
  },
  created() {
    this.$store.commit('auth/INIT', { i18n: this.$i18n });
    this.$store.dispatch('signalr/connect');
    this.$store.dispatch('boot/refresh', { i18n: this.$i18n });
  }
};
</script>
