import http from '../../app-http'

const getDefaultState = () => {
  return {
    busyIndicators:{
     list:{
      loading: false,
      processing: false
     },
     messages: {
  
     },
     linking: null
    },
    list: [],
    messages: {
      
    },

    unreadMessagesCount: 0,
    
    page: 1,
    total: 0,
    perPage: 100,
    contactId:  null,
    conversationId: null,
    stateIsLoaded: false,
    persistentState: {
      keyword: null,
      managerQuickFilter: 'AllContact',
      selectedId: null,
    }
  };
}

export const state = getDefaultState();

export const mutations = {
  SET_UNREAD_MESSAGES_COUNT(state, { value } = {}) {
    state.unreadMessagesCount = value;
  },
  SET_QUERY_FILTER(state, {contactId, conversationId} = {}) {
    state.contactId = contactId;
    state.conversationId = conversationId;
  },
  SET_LINKING(state, id) {
    state.busyIndicators.linking = id;
  },
  UPDATE_CONVERSATION(state, conversation){
    const l = [conversation, ...state.list.filter(x => x.id !== conversation.id)];
    state.list = l;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  SET_STATE_IS_LOADED(state, newValue) {
    state.stateIsLoaded = newValue;
  },
  SET_PERSISTENT_STATE(state, newValue) {
    state.persistentState = newValue;
  },
  SET_SELECTED(state, newValue) {
    state.persistentState.selectedId = newValue;
  },
  SET_LIST_LOADING(state, newValue) {
    state.busyIndicators.list.loading = newValue;
  },
  SET_LIST_PROCESSING(state, newValue) {
    state.busyIndicators.list.processing = newValue;
  },
  SET_MESSAGES_LOADING(state, {conversationId, newValue} = {}) {
    const o = {...state.busyIndicators.messages };
    o[conversationId] = o[conversationId] || { loading: false, processing: false };
    o[conversationId].loading = newValue;
    state.busyIndicators.messages = o;
  },
  SET_MESSAGES_PROCESSING(state, {conversationId, newValue} = {}) {
    const o = {...state.busyIndicators.messages };
    o[conversationId] = o[conversationId] || { loading: false, processing: false };
    o[conversationId].processing = newValue;
    state.busyIndicators.messages = o;
  },
  ADD_CONVERSATION(state, conv){
    state.list = [...state.list, conv];
  },
  SET_LIST(state, data) {
    state.total = data.total;
    state.list = data.items;
  },
  SET_MESSAGES(state, {conversationId, newValue} = {}) {
    const o = {...this.messages };
    o[conversationId] = newValue;
    state.messages = o;
  },
  SET_PAGE(state, data){
    state.page = data;
  },
  ADD_MESSAGE(state, {conversationId, msg} = {}) {
    const o = [...state.messages[conversationId], msg];
    state.messages[conversationId] = o;
  },
  UPDATE_MESSAGE(state, {conversationId, msg} = {}) {
    const o = [...state.messages[conversationId]];
    const oldMsg = o.find(x => x.id === msg.id);
    if(!oldMsg) return;
    const oldMsgIndex = o.indexOf(oldMsg);
    if(oldMsgIndex < 0) return;
    o[oldMsgIndex] = msg;
    state.messages[conversationId] = o;
  },
  REMOVE_MESSAGE(state, {conversationId, msgId} = {}) {
    const o = [...state.messages[conversationId]];
    state.messages[conversationId] = o.filter(x => x.id !== msgId);
  },
  UPDATE_CONVERSATION_UNREAD_MESSAGES(state) {
    let count = 0;
    state.messages[state.persistentState.selectedId].forEach(msg => {
      if(msg.notRead && msg.direction === 'Incoming') {
        count++;
      }
    });
    const conversation = state.list.find(x => x.id === state.persistentState.selectedId)
    conversation.unreadMessageCount = count;
    conversation.hasUnreadMessages = count > 0;
  },
}

export const getters = {
  unreadMessagesCount (state){
    return state.unreadMessagesCount;
  },
  isLinking (state){
    return state.busyIndicators.linking;
  },
  total (state) {
    return state.total;
  },
  page (state) {
    return state.page;
  },
  perPage (state) {
    return state.perPage;
  },
  ready(state){
    return state.stateIsLoaded;
  },
  listLoading(state) {
    return state.busyIndicators.list.loading;
  },
  messagesLoading(state) {
    return state.persistentState.selectedId ? ((state.busyIndicators.messages[state.persistentState.selectedId] || {}).loading || false) : false;
  },
  messagesProcessing(state) {
    return state.persistentState.selectedId ? ((state.busyIndicators.messages[state.persistentState.selectedId] || {}).processing || false) : false;
  },
  listProcessing(state) {
    return state.busyIndicators.processing;
  },
 
  list(state) {
    return state.list;
  },
  messages(state) {
    return state.persistentState.selectedId ? state.messages[state.persistentState.selectedId] : null;
  },
  selected(state) {
    return state.persistentState.selectedId ? (state.list.find(x => x.id == state.persistentState.selectedId) || null) : null;
  },
  selectedId(state) {
    return state.persistentState.selectedId;
  },
}

export const actions = {
  async getUnreadMessagesCount({ commit }) {
    const response = await http.get(`conversations/unread-messages`);
    commit('SET_UNREAD_MESSAGES_COUNT',  { value: response.data });
  },
  async deleteMessage({ getters, commit }, { id } = {}) {
    const selected = getters.selected;
    const selectedId = selected.id;
    commit('REMOVE_MESSAGE', { conversationId: selectedId, msgId: id });
    await http.delete(`conversations/${selected.platform}/${selectedId}/messages/${id}`);
  },
  async sendMessage({ getters, commit }, dto = {}) {

    const selected = getters.selected;
    const selectedId = selected.id;
    commit('SET_MESSAGES_PROCESSING', { conversationId: selectedId, newValue: true });

    let response;
    if(dto.fileBlob) {
      const formData = new FormData();
      formData.append('file', dto.fileBlob, dto.fileName);
      formData.append('json', JSON.stringify(dto));
      response = await http({
        method: dto.id ? 'put' : 'post',
        url:  dto.id ? `conversations/${selected.platform}/${selectedId}/messages/${dto.id}` : `conversations/${selected.platform}/${selectedId}/messages`,
        data: formData,
        headers: {
            'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
        }
      });
      commit('UPDATE_MESSAGE', { conversationId: selectedId, msg: response.data.msg });
    } else {
      const formData = new FormData();
      formData.append('json', JSON.stringify(dto));
      response = await http({
        method: dto.id ? 'put' : 'post',
        url:  dto.id ? `conversations/${selected.platform}/${selectedId}/messages/${dto.id}` : `conversations/${selected.platform}/${selectedId}/messages`,
        data: formData,
        headers: {
            'Content-Type': `multipart/form-data;`,
        }
      });
      commit('ADD_MESSAGE', { conversationId: selectedId, msg: response.data.msg });
    }
    
    commit('SET_MESSAGES_PROCESSING', { conversationId: selectedId, newValue: false });
    
    commit('UPDATE_CONVERSATION', response.data.conversation);
  },
  async setManager({ commit }, { platform, conversationId, userId } = { }) {
    
    commit('SET_MESSAGES_PROCESSING', { conversationId: conversationId, newValue: true });
    const response = await http.put(`conversations/${platform}/${conversationId}/manager`, { userId });
    commit('UPDATE_CONVERSATION', response.data);
    commit('SET_MESSAGES_PROCESSING', { conversationId: conversationId, newValue: false });
  },
  async makeMeManager({ getters, commit }) {
    const selected = getters.selected;
    const selectedId = selected.id;
    commit('SET_MESSAGES_PROCESSING', { conversationId: selectedId, newValue: true });
    const response = await http.put(`conversations/${selected.platform}/${selectedId}/manager`, {});
    commit('UPDATE_CONVERSATION', response.data);
    commit('SET_MESSAGES_PROCESSING', { conversationId: selectedId, newValue: false });
  },
  async refreshContact ({ state, commit }, { contactId } = {}) {
    if(contactId) {
      const conversation = state.list.find(x => x.contact && x.contact.id === contactId);
      if(conversation) {
        const response = await http.get(`conversations/${conversation.id}`);
        commit('UPDATE_CONVERSATION', response.data);
      }
    }
  },
  loadState({ commit }) {
    return new Promise( (resolve, reject) => {
      commit('SET_LIST_LOADING', true);
      http.get(`user-storage/chat`).then((response)=>{
        commit('SET_LIST_LOADING', false);
        if(response.data) {
          const v = {
            keyword: response.data.keyword || '',
            managerQuickFilter: response.managerQuickFilter || 'AllContact',
            selectedId: response.data.selectedId || null
          }
          commit('SET_PERSISTENT_STATE', v);
        }
        resolve(response.data);
      }).catch((error) => {
        commit('SET_LIST_LOADING', false);
        reject(error);
      });
    });
  },
  async saveState({ state }) {
    await http.post(`user-storage/chat`, state.persistentState );
  },
  async setPage({ dispatch, commit }, page) {
    commit('SET_PAGE', page);
    await dispatch('load');
  },
  async load({ dispatch, commit, state },{contactId, conversationId} = {}) {
   
    if(contactId && conversationId) {
      commit('SET_QUERY_FILTER', {contactId, conversationId});
    }

    if(!state.stateIsLoaded) {
      await dispatch('loadState');
      commit('SET_STATE_IS_LOADED', true);
    }
    const skip = (state.page - 1) * state.perPage;
    const req = {
      skip: skip,
      take: state.perPage,
      keyword: state.persistentState.keyword,
      contactId: state.contactId,
      conversationId: state.conversationId,
      managerQuickFilter: state.persistentState.managerQuickFilter
    }

    commit('SET_LIST_LOADING', true);
    const response = await http.get(`conversations`, { params: req });
    commit('SET_LIST_LOADING', false);
    commit('SET_LIST', response.data);

    if(!state.list.find(x => x.id === state.persistentState.selectedId)) {
      commit('SET_SELECTED', null);
    } else {
      await dispatch('loadConversationMessages');
    }
  },
  async refresh({ dispatch, state }, { id, contactId } = {}) {
    if(contactId) {
      if(state.list.find(x => x.contact && x.contact.id === contactId))
      {
        await dispatch('load');
      }
      if(state.persistentState.selectedId) {
        const chat = state.list.find(x => x.id == state.persistentState.selectedId);
        if(chat && chat.contact && chat.contact.id === contactId) {
          await dispatch('loadConversationMessages');
        }
      }
    } else {
      if(id) {
        if(state.persistentState.selectedId === id) {
          await dispatch('loadConversationMessages');
        }
      } else {
        await dispatch('loadConversationMessages');
      }
      await dispatch('load');
    }
   
  },
  async select({ dispatch, commit, getters }, { id, transient } = {}) {
    commit('SET_SELECTED', id);
    const selected = getters.selected;
    
    if(!selected) {
      await dispatch('loadConversation', { id });
    }
    await dispatch('loadConversationMessages');
   
    if(!transient) {
      await dispatch('saveState');
    }
  },
  async linkConversationToContact({ commit }, { platform, conversationId, contactId }) {
    
    commit('SET_LINKING', conversationId);
    const response = await http.put(`conversations/${platform}/${conversationId}/contact`, { contactId });
    commit('SET_LINKING', null);
    commit('UPDATE_CONVERSATION', response.data);
    
  },
  async loadConversation({ commit }, { id }) {
    commit('SET_LIST_LOADING', true);
    const response = await http.get(`conversations/${id}`);
    commit('SET_LIST_LOADING', false);
    commit('ADD_CONVERSATION', response.data);
  },
  async loadConversationMessages( { getters, commit }) {
    if(getters.selectedId) {
      const selected = getters.selected;
      const selectedId = selected.id;
      commit('SET_MESSAGES_LOADING', { conversationId: selectedId, newValue: true });
      const response = await http.get(`conversations/${selected.platform}/${selectedId}/messages`);
      commit('SET_MESSAGES_LOADING', { conversationId: selectedId, newValue: false });
      commit('SET_MESSAGES', { conversationId: selectedId, newValue: response.data });
    } 
  },
  async markAsRead({ getters, commit }, { msgId } = {}){
    if(getters.selectedId) {
      const selected = getters.selected;
      const selectedId = selected.id;
      const currentMsg = state.messages[selectedId].find(x => x.id === msgId);
      if(currentMsg) {
        const updateMsg = JSON.parse(JSON.stringify(currentMsg));
        updateMsg.readAt = new Date();
        updateMsg.notRead = false;
        commit('UPDATE_MESSAGE', { conversationId: selectedId, msg: updateMsg });
        commit('UPDATE_CONVERSATION_UNREAD_MESSAGES');
      }
      const response = await http.put(`conversations/${selected.platform}/${selectedId}/messages/${msgId}/read`, {});
      commit('UPDATE_MESSAGE', { conversationId: selectedId, msg: response.data.msg });
      commit('UPDATE_CONVERSATION', response.data.conversation);
      commit('SET_UNREAD_MESSAGES_COUNT',  { value: getters.unreadMessagesCount-1 });
    }
  },
  async markAllAsRead({ getters, commit, dispatch }){
    if(getters.selectedId) {
      const selected = getters.selected;
      const selectedId = selected.id;
      state.messages[selectedId].forEach(msg => {
        if(!msg.readAt) {
          const updateMsg = JSON.parse(JSON.stringify(msg));
          updateMsg.readAt = new Date();
          updateMsg.notRead = false;
          commit('UPDATE_MESSAGE', { conversationId: selectedId, msg: updateMsg });
        }
      });
      commit('UPDATE_CONVERSATION_UNREAD_MESSAGES');
      await http.put(`conversations/${selected.platform}/${selectedId}/read`, {});
      await dispatch('loadConversationMessages');
      await dispatch('getUnreadMessagesCount');
    }
  }
}
