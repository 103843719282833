<script>
import http from '@/app-http'
import Typeahead from '@/components/typeahead'
import { debounce } from "lodash";

export default {
  components: {  
    Typeahead
  },
  props: {
    def: {
      type: Object,
      required: true
    },
    value:{
      required: true
    }
  },
  created(){
    this.updateInternalValue(this.value || null);
    this.loadDirectory();
  },
  data() {
    return {
      pValue: null,
      disableEmit: false,
      directoryItems: [],
      directoryItemsLoading: false,
    }
  },
  computed:{
    step() {
      if(this.def.precision <= 0)
        return "1";
      let s = "0.";
      for(let i = 0; i < this.def.precision-1; i++) {
        s += '0';
      }
      s += '1';
      return s;
    },
    directoryItemValueId:
    {
      get: function() {
        return (this.pValue || {}).id || null;
      },
      set: function(newValue) {
        this.$emit('update', { stringValue: newValue });
      }
    },
  },
  watch: {
    value (val, oldVal) {
      if(this.def.type === 'Address' || this.def.type === 'String' || this.def.type === 'PhoneNumber' ||  this.def.type === 'Text'  || this.def.type === 'Link' || this.def.type === 'Dropdown') {
        if((val || {}).stringValue !== (oldVal || {}).stringValue) {
          this.updateInternalValue(val || null);
        }
      } else if(this.def.type === 'Date' ) {
        const from = ((val || {}).dateRangeValue || {}).from || null;
        const till = ((val || {}).dateRangeValue || {}).till || null;

        const oldFrom = ((oldVal || {}).dateRangeValue || {}).from || null;
        const oldTill = ((oldVal || {}).dateRangeValue || {}).till || null;
        
        if(from !== oldFrom || till !== oldTill) {
          this.updateInternalValue(val || null);
        }
      } else if(this.def.type === 'Number' ) {
        const from = ((val || {}).numberRangeValue || {}).from || null;
        const till = ((val || {}).numberRangeValue || {}).till || null;

        const oldFrom = ((oldVal || {}).numberRangeValue || {}).from || null;
        const oldTill = ((oldVal || {}).numberRangeValue || {}).till || null;

        if(from !== oldFrom || till !== oldTill) {
          this.updateInternalValue(val || null);
        }
      } else if(this.def.type === 'Directory') {
        this.updateInternalValue(val || null);
        
      }
    }
  },
  methods:{
    async loadDirectory (){
      if(this.def.type === 'Directory') {
        if(this.def.directoryLookupControl === 'Typeahead') {
          this.onDirectoryItemSearch();
        } else {
          this.directoryItemsLoading = true;
          try {
             console.log('xxx');
            const response = await http.post(`directories/${this.def.directoryId}/items/search`, {take: 1000, orderBy: { field: '$$display$$', asc: true}});
            this.directoryItems = response.data.items;
          } finally {
            this.directoryItemsLoading = false;
          }
        }
      }
    },
    async updateInternalValue(val) {
      this.disableEmit = true;
      if(!val) {
        this.pValue = null;
      } else {
        if(this.def.type === 'Address' || this.def.type === 'String' || this.def.type === 'Link' || this.def.type === 'PhoneNumber' || this.def.type === 'Text' || this.def.type === 'Dropdown') {
          this.pValue = val.stringValue;
        } else if(this.def.type === 'Date') {
          this.pValue = val.dateRangeValue;
        } else if(this.def.type === 'Number') {
          this.pValue = val.numberRangeValue;
        } else if(this.def.type === 'Directory') {
          val = val.stringValue;
          if(!val) {
            this.pValue = null;
          } else {
            let existed = this.directoryItems.find(x => x.id === val);
            if(existed) {
              this.pValue = existed;
            } else {
              this.directoryItemsLoading = true;
              try {
                const response = await http.get(`directories/${this.def.directoryId}/items/${val}`);
                this.pValue = response.data;
              } finally {
                this.directoryItemsLoading = false;
              }
            }
          }
        }
      }
      this.$nextTick(() => {
        this.disableEmit = false;
      });
    },
    getNumber(val) {
      val = val || null;
      if(!val){
        this.$emit('update', val); 
        return;
      }
      const n = parseFloat(val);
    
      if(n.isNaN) {
        val = null;
      } else {
        if(this.def.precision <= 0){
          val = Math.round(n);
        } else {
          val = parseFloat(n.toFixed(this.def.precision));
        }
      }
      return val; 
    },
    raiseFromNumberUpdate(val) { 
      if(this.disableEmit) return;

      const o = {...(this.pValue || {}), from: this.getNumber(val) || null };
      if(o.from || o.till){
        this.$emit('update', { numberRangeValue: o });
      } else {
        this.$emit('update', null);
      }
    },
    raiseTillNumberUpdate(val) { 
      if(this.disableEmit) return;

       const o = {...(this.pValue || {}), till: this.getNumber(val) || null };
      if(o.from || o.till){
        this.$emit('update', { numberRangeValue: o });
      } else {
        this.$emit('update', null);
      }
    },
    raiseFromDateUpdate(val) { 
      if(this.disableEmit) return;

      const o = {...(this.pValue || {}), from: val || null };
      if(o.from || o.till){
        this.$emit('update', { dateRangeValue: o });
      } else {
        this.$emit('update', null);
      }
    },
    raiseTillDateUpdate(val) { 
      if(this.disableEmit) return;

       const o = {...(this.pValue || {}), till: val|| null };
      if(o.from || o.till){
        this.$emit('update', { dateRangeValue: o });
      } else {
        this.$emit('update', null);
      }
    },
    raiseStringUpdate(val) { 
      if(this.disableEmit) return;

      if(val){
        this.$emit('update', { stringValue: val });
      } else {
        this.$emit('update', null);
      }
    },
    selectDirectoryItem(item) {
      this.pValue = item;
      if(item) {
        this.directoryItemValueId = item.id;
      } else {
        this.directoryItemValueId = null;
      }
    },
    displayDirectoryItem(item) {
      if(this.def.displayDirectoryFieldId) {
        return '#' +  item.number + ' | ' + item.customFields[this.def.displayDirectoryFieldId];
      } else if(this.def.displayDirectoryFieldId === 'Number') {
          return '#' +  item.number;
      } else {
        return '#' +  item.number + (item.display ? ' | ' + item.display : '');
      }
    },
    clearDirectoryItem() {
      this.directoryItemValueId = null;
    },
    async onDirectoryItemSearch(e) {
      
       const req = {
          keyword: (e || {}).input || '',
          skip: 0,
          take: 100,
          orderBy: {
            field: '$$display$$', 
            asc: true
          }
        };
        this.directoryItemsLoading = true;
        
        const response = await http.post(`directories/${this.def.directoryId}/items/search`, req);
        this.directoryItemsLoading = false;
        this.directoryItems = response.data.items;
      
    },
    onDebDirectoryItemSearch: debounce(function(e){
    
      this.onDirectoryItemSearch(e);
    }, 1000),
    
  }
};
</script>

<template>
  <div>
    <div v-if="def.type === 'Number'">
      <div class=" border">
        <div class="card-body">
          <div class="row">
            <div class="col-3"> 
              <small><label>{{$t('common.from')}}</label></small>
            </div>
            <div class="col-9">
              <input 
                type="text" 
                class="form-control mb-2" 
                 :placeholder="$t('common.from')"
                :value="(pValue || {}).from || null"  
                @input.stop="event => raiseFromNumberUpdate(event.target.value)" 
                @blur.stop="event => raiseFromNumberUpdate(event.target.value)"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-3"> 
              <small><label>{{$t('common.till')}}</label></small>
            </div>
            <div class="col-9">
              <input 
                type="text" 
                class="form-control" 
                :placeholder="$t('common.till')"
                :value="(pValue || {}).till || null"  
                @input.stop="event => raiseTillNumberUpdate(event.target.value)" 
                @blur.stop="event => raiseTillNumberUpdate(event.target.value)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="def.type === 'Date'">
      <div class="border">
        <div class="card-body">
          <div class="row">
            <div class="col-3"> <small><label>{{$t('common.from')}}</label></small></div>
            <div class="col-9">
              <input 
                type="date" 
                class="form-control mb-2" 
                :placeholder="$t('common.from')"
                :value="(pValue || {}).from || null"  
                @input.stop="event => raiseFromDateUpdate(event.target.value)" 
                @blur.stop="event => raiseFromDateUpdate(event.target.value)"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-3">   <small><label>{{$t('common.till')}}</label></small></div>
            <div class="col-9">
              <input 
                type="date" 
                class="form-control" 
               :placeholder="$t('common.till')"
                :value="(pValue || {}).till || null"  
                @input.stop="event => raiseTillDateUpdate(event.target.value)" 
                @blur.stop="event => raiseTillDateUpdate(event.target.value)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="def.type === 'Dropdown'">
      <select class="form-control" 
        v-model="pValue" 
        @change.stop="event => raiseStringUpdate(pValue)" 
      >
        <option :value="null">{{$t('common.nothingSelected')}}</option>
        <option v-for="(ddItem, $index) in def.dropdownItems" :key="$index" :value="ddItem.value">{{ddItem.text}}</option>
      </select>
    </div>
    <div v-if="def.type === 'Link'">
      <input class="form-control" 
        :value="pValue"  
        @input.stop="event => raiseStringUpdate(event.target.value)" 
        @blur.stop="event => raiseStringUpdate(event.target.value)"
      />
    </div>
    <div v-if="def.type === 'PhoneNumber'">
      <input class="form-control" 
        :value="pValue"  
        @input.stop="event => raiseStringUpdate(event.target.value)" 
        @blur.stop="event => raiseStringUpdate(event.target.value)"
      />
    </div>
    <div v-if="def.type === 'Address'">
      <input class="form-control" 
        :value="pValue"  
        @input.stop="event => raiseStringUpdate(event.target.value)" 
        @blur.stop="event => raiseStringUpdate(event.target.value)"
      />
    </div>
    <div v-if="def.type === 'String'">
      <input class="form-control" 
        :value="pValue"  
        @input.stop="event => raiseStringUpdate(event.target.value)" 
        @blur.stop="event => raiseStringUpdate(event.target.value)"
      />
    </div>
    <div v-if="def.type === 'Text'">
      <input type="text" :rows="def.rows" class="form-control"
        :value="pValue"  
        @input.stop="event => raiseStringUpdate(event.target.value)" 
        @blur.stop="event => raiseStringUpdate(event.target.value)"
      />
    </div>
    <div v-if="def.type === 'Directory'">
      <div v-if="def.directoryLookupControl === 'Typeahead'">
        <div   v-if="pValue">
          <div class="input-group">
            <div class="form-control text-truncate text-nowrap">
              <a href="javascript:void(0)" @click="openItem({ item: pValue, mode: 'view'})">
                <span v-if="def.displayDirectoryFieldId">
                  <span v-if="def.displayDirectoryFieldId !== 'Number'">{{pValue.customFields[def.displayDirectoryFieldId]}}</span>
                  <span v-if="def.displayDirectoryFieldId === 'Number'"><b> #{{pValue.number}}</b></span>
                </span>
                <span v-if="!def.displayDirectoryFieldId && pValue.display">
                  {{pValue.display}}
                </span>
                <b v-if="!def.displayDirectoryFieldId && !pValue.display">
                  #{{pValue.number}}
                </b>
              </a>
            </div>
            <button class="btn btn-outline-secondary" type="button" @click="clearDirectoryItem()">
              {{$t('common.change')}}
            </button>
          </div>
        </div>
        <div  v-if="!pValue">
          <typeahead
            :placeholder="$t('common.startTyping')"
            :items="directoryItems"
            :busy="directoryItemsLoading"
            :minInputLength="2"
            :itemProjection="displayDirectoryItem"
            @selectItem="(item) => selectDirectoryItem(item)"
            @onInput="onDebDirectoryItemSearch"
          >
          </typeahead>
        </div>
      </div>
      <div v-if="def.directoryLookupControl === 'Dropdown'" >
        
        <div class="input-group">
          <button class="input-group-text"  v-if="directoryItemsLoading">
            <i class="fa fa-spinner fa-spin" ></i>
          </button>
       
          <select 
            class="form-control"
            v-model="directoryItemValueId"
          >
            <option :value="null">{{$t('common.nothingSelected')}}</option>
            <option v-for="i in directoryItems" :key="i.id" :value="i.id">
              <span v-if="def.displayDirectoryFieldId">
                <span v-if="def.displayDirectoryFieldId !== 'Number'"> {{i.customFields[def.displayDirectoryFieldId]}}</span>
                <span v-if="def.displayDirectoryFieldId === 'Number'"><b> #{{i.number}}</b></span>
              </span>
              <span v-if="!def.displayDirectoryFieldId && !i.display">
                {{('#' + i.number)}}
              </span>
             <span v-if="!def.displayDirectoryFieldId && i.display">
                {{i.display}}
              </span>
            </option>
          </select>
          <!-- <button class="btn btn-outline-light" type="button" v-if="allowEditDirectoryItem()" @click="newDirectoryItem">
            <i class="fa fa-plus"></i>
            <div class="text-sm-end d-none d-md-inline-block ms-2">
              {{ $t('common.newRecord') }}
            </div>
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>
