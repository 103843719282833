import moment from 'moment-timezone'
import lodash from "lodash";

window.moment = moment;
window.lodash = lodash;

function onItemNew({dir, obj, data} = {}) {
  let sq = null;
  if(dir.onNewScript) {
    sq = new Function('dir', 'data', dir.onNewScript);  
  }
  if(sq)
    sq.call(obj, data, dir);
  return obj;
} 

function onItemChanged({dir, obj, data} = {}) {

 // console.log('_', window._);
  //console.log('lodash', window._.defaults({ 'a': 1 }, { 'a': 3, 'b': 2 }));
  let sq = null;
  if(dir.onChangedScript) {
    sq = new Function('dir', 'data', dir.onChangedScript);  
  }
  if(sq) {
      sq.call(obj, dir, data);
  }
  return obj;
} 

async function onLoad  () {
  return {};
} 

export default {
  onItemNew,
  onItemChanged,
  onLoad
} 