import http from '../../app-http'


const getDefaultState = () => {
  return {
    busyIndicators:{
      loading: false,
      processing: false,
      deleting: null,
      
    },
    items: [],
    settings: {
      mode: 'list'
    }
  }
};

export const state = getDefaultState();

export const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  SET_SETTINGS(state, newValue) {
    state.settings = newValue;
  },
  SET_BUSY_INDICATORS(state, newValue) {
    state.busyIndicators = newValue;
  },
  SET_ITEMS(state, newValue) {
    state.items = newValue;
  }
}

export const getters = {
  loading(state) {
    return state.busyIndicators.loading;
  },
  processing(state) {
    return state.busyIndicators.processing;
  },
  deleting(state) {
    return state.busyIndicators.deleting;
  },
  items(state) {
    return state.items;
  },
  everyone(state) {
    return state.items.find(x => x.everyone);
  },
  itemsOnlyAlive(state) {
    return state.items;
  },
  settings(state) {
    return state.settings
  }
}

export const actions = {
  create({ dispatch, commit, state }, { dto } = {}) {
    return new Promise( (resolve, reject) => {
      commit('SET_BUSY_INDICATORS', {...state.busyIndicators, processing: true });
      http.post(`user-groups`, dto).then(()=>{
        commit('SET_BUSY_INDICATORS', {...state.busyIndicators, processing: false });
        dispatch('loadAll');
        dispatch('users/loadUsers', null, { root: true });
        resolve();
      }).catch((error) => {
        commit('SET_BUSY_INDICATORS', {...state.busyIndicators, processing: false });
        reject(error);
      });
    });
  },
  update({ dispatch, commit, state }, { id, dto } = {}) {
    return new Promise( (resolve, reject) => {
    
      commit('SET_BUSY_INDICATORS', {...state.busyIndicators, processing: true });
      http.put(`user-groups/${id}`, dto).then(()=>{
        commit('SET_BUSY_INDICATORS', {...state.busyIndicators, processing: false });
        dispatch('loadAll');
        dispatch('users/loadUsers', null, { root: true });
        resolve();
      }).catch((error) => {
        commit('SET_BUSY_INDICATORS', {...state.busyIndicators, processing: false });
        reject(error);
      });
    });
  },
  delete({ dispatch, commit, state }, { id } = {}) {
    return new Promise( (resolve, reject) => {
      commit('SET_BUSY_INDICATORS', {...state.busyIndicators, deleting: id });
      http.delete(`user-groups/${id}`).then(()=>{
        commit('SET_BUSY_INDICATORS', {...state.busyIndicators, deleting: null });
        dispatch('loadAll');
        dispatch('users/loadUsers', null, { root: true });
        resolve();
      }).catch((error) => {
        commit('SET_BUSY_INDICATORS', {...state.busyIndicators, deleting: null });
        reject(error);
      });
    });
  },
  loadSettings({ commit, state }) {
    return new Promise( (resolve, reject) => {
      commit('SET_BUSY_INDICATORS', {...state.busyIndicators, loading: true });
      http.get(`user-storage/page-users`).then((response)=>{
        commit('SET_BUSY_INDICATORS', {...state.busyIndicators, loading: false });
        if(response.data) {
          commit('SET_SETTINGS', response.data);
        }
        resolve(response.data);
      }).catch((error) => {
        commit('SET_BUSY_INDICATORS', {...state.busyIndicators, loading: false });
        reject(error);
      });
    });
  },
  writeSettings({ commit },value) {
    return new Promise( (resolve, reject) => {
      commit('SET_SETTINGS', value);
      http.post(`user-storage/page-user-groups`, value).then((response)=>{
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  load({ commit, state }, { id } = {}) {
    return new Promise( (resolve, reject) => {
      commit('SET_BUSY_INDICATORS', {...state.busyIndicators, loading: true });
      http.get(`user-groups/${id}`).then((response)=>{
        commit('SET_BUSY_INDICATORS', {...state.busyIndicators, loading: false });
        resolve(response.data);
      }).catch((error) => {
        commit('SET_BUSY_INDICATORS', {...state.busyIndicators, loading: false });
        reject(error);
      });
    });
  },
  loadAll({ commit, state }) {
    return new Promise( (resolve, reject) => {
      commit('SET_BUSY_INDICATORS', {...state.busyIndicators, loading: true });
      http.get(`user-groups`, { params: { deleted: state.settings.showDeleted }}).then((response)=>{
        commit('SET_BUSY_INDICATORS', {...state.busyIndicators, loading: false });
        commit('SET_ITEMS', response.data);
        resolve(response.data);
      }).catch((error) => {
        commit('SET_BUSY_INDICATORS', {...state.busyIndicators, loading: false });
        reject(error);
      });
    });
  }
}
