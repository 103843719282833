const signalR = require("@microsoft/signalr");
import store from '@/state/store'

let connection = null;

export const state = {
   connectionId: null
}

export const mutations = {
    SET_CONNECTIONID(state, newValue) {
        state.connectionId = newValue;
    },
}

export const actions = {
    connect({ dispatch, commit }) {
        return new Promise( (resolve) => {
            
            if(connection) {
                connection.stop();
            }
    
            if(store.state.auth.token) {
                const uri = process.env.VUE_APP_SIGNALR_URL + "client-notifications";
                console.log('signlaR trying to connect ' + uri)
                connection = new signalR.HubConnectionBuilder()
                  .withUrl(uri, { accessTokenFactory: () => store.state.auth.token })
                  .configureLogging(signalR.LogLevel.Debug)
                  .withAutomaticReconnect()
                  .build();

                connection.logging = true;
            
                connection.on("AccountChanged", data => {
                  console.log('AccountChanged', data);
                  dispatch('auth/refresh', null, { root: true });
                  
                });

                connection.on("UserGroupChanged", data => {
                  console.log('UserGroupChanged', data);
                  dispatch('userGroups/loadAll', null, { root: true });
                  dispatch('users/loadUsers', null, { root: true });
                });

                connection.on("UserCreated", data => {
                  console.log('UserCreated', data);
                  dispatch('users/loadUsers', null, { root: true });
                  dispatch('userGroups/loadAll', null, { root: true });
                });

                connection.on("UserChanged", data => {
                  console.log('UserChanged', data);
                  if(store.state.auth.currentUser.id === data) {
                      dispatch('auth/refresh', null, { root: true });
                      dispatch('userGroups/loadAll', null, { root: true });
                  }
                  dispatch('users/loadUsers', null, { root: true });
                });
                
                connection.on("UserDeleted", data => {
                  console.log('UserDeleted', data);
                  dispatch('users/loadUsers', null, { root: true });
                  dispatch('userGroups/loadAll', null, { root: true });
                });

                connection.on("UserStorageChanged", data => {
                  console.log('UserStorageChanged', data);
                  dispatch('users/loadSettings', null, { root: true });
                });

                connection.on("CustomFieldChanged", () => {
                  dispatch('customFields/load', null, { root: true });
                });

                connection.on("DirectoryItemChanged", data => {
                  console.log('DirectoryItemChanged', data);
                  dispatch('directoryItems/debounceLoad', {}, { root: true });
                });

                connection.on("DirectoryChanged", data => {
                  console.log('DirectoryChanged', data);
                  dispatch('directories/load', null, { root: true });
                });

                connection.on("UserProfileChanged", data => {
                  console.log('UserProfileChanged', data);
                  dispatch('usageProfiles/load', null, { root: true });
                });

                connection.on("BoardChanged", data => {
                  console.log('BoardChanged', data);
                  dispatch('boards/load', null, { root: true });
                });


                connection.on("UpdatedReminder", id => {
                  console.log('UpdatedReminder', id);
                  dispatch('reminders/load', { }, { root: true });
                  dispatch('reminders/loadReminder', { id, silent: true }, { root: true });
                });

                connection.on("CreatedReminder", id => {
                  console.log('CreatedReminder', id);
                    dispatch('reminders/load', { }, { root: true });
                });


                // connection.on("RefreshBoard", data => {
                //   console.log('RefreshBoard', data);
                //   dispatch('contactsBoard/refresh', data, { root: true });
                // });

                connection.on("ConversationUpdated", data => {
                  console.log('ConversationUpdated', data);
                  
                  if(process.env.VUE_APP_CHAT_ENABLED === 'YES') {
                    dispatch('chat/refresh', { id: data}, { root: true });
                    dispatch('chat/getUnreadMessagesCount', null, { root: true });
                  }
                });
                
                connection.on("NewUserNotification", data => {
                  console.log('NewUserNotification', data);
                  dispatch('notifications/loadLatest', null, { root: true });
                });

                connection.start()
                  .then(() => {
                    console.log('signlaR connected!', connection.connection.connectionId)
                    commit('SET_CONNECTIONID', connection.connection.connectionId);
                    
                })
                .catch(console.error);

                connection.onreconnected((connectionId) => {
                    console.log('signlaR onreconnected!', connectionId)
                    commit('SET_CONNECTIONID', connectionId);
                    dispatch('boot/refresh', null, { root: true });
                });

                connection.onclose(async () => {
                   
                });
              
            }
            resolve();
       });
   },
   disconnect() {
        return new Promise( (resolve) => {
            if(connection) {
               connection.stop();
            }
            connection = null;
            resolve();
        });
    },
}
