import axios from 'axios';
import router from './router/index'
import store from '@/state/store'

const httpClient = axios.create({
    baseURL: process.env.VUE_APP_URL,
    headers: {
        "Content-Type": "application/json",
        // anything you want to add to the headers
    }
});

const getAuthToken = () => 'Bearer ' + store.state.auth.token;
const getAccountId = () => (((store.state.auth || {}).currentUser || {}).account || {}).id || '';
const getSignalRConnectionId = () => store.state.signalr.connectionId;

const authInterceptor = (config) => {
    config.headers['Authorization'] = getAuthToken();
    config.headers['SignalRConnId'] = getSignalRConnectionId();
    config.headers['AccountId'] = getAccountId();
    return config;
}

httpClient.interceptors.request.use(authInterceptor);

// interceptor to catch errors
const errorInterceptor = error => {
 
    // check if it's a server error
    if (!error.response) {
      //notify.warn('Network/Server error');
      return Promise.reject(error);
    }

    // all the other error responses
    switch(error.response.status) {
        case 400:
            console.error(error.response.status, error.message);
           // notify.warn('Nothing to display','Data Not Found');
            break;

        case 401: // authentication error, logout the user
         // debugger; // eslint-disable-line no-debugger
            //notify.warn( 'Please login again', 'Session Expired');
    
            store.dispatch('auth/logOut')
            router.push('/login');
            break;

        case 403: // authentication error, logout the user
        // debugger; // eslint-disable-line no-debugger
            //notify.warn( 'Please login again', 'Session Expired');
    
            store.dispatch('auth/logOut')
            router.push('/login');
            break;

               
        default:
            console.error(error.response.status, error.message);
            //notify.error('Server Error');

    }
    return Promise.reject(error);
}

// Interceptor for responses
const responseInterceptor = response => {
    switch(response.status) {
        case 200: 
            // yay!
            break;
        // any other cases
        default:
            // default case
    }

    return response;
}

httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);

export default httpClient;