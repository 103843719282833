import http from '../../app-http'

const getDefaultState = () => {
  return {
    busyIndicators:{
      loading: false,
      processing: false,
      deleting: null
    },
    bots:{
      telegram: [],
      hasBots: false,
    }
  }
};

export const state = getDefaultState();

export const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  SET_BOTS(state, newValue) {
    state.bots = newValue;
  },
  SET_BUSY_INDICATORS(state, newValue) {
    state.busyIndicators = newValue;
  },
}

export const getters = {
  bots (state) {
    return state.bots;
  },
  loading(state) {
    return state.busyIndicators.loading;
  },
  processing(state) {
    return state.busyIndicators.processing;
  },
  deleting(state) {
    return state.busyIndicators.deleting;
  },
}

export const actions = {
  createTelegramBot({ dispatch, commit, state }, { dto } = {}) {
    return new Promise( (resolve, reject) => {
      commit('SET_BUSY_INDICATORS', {...state.busyIndicators, processing: true });
      http.post(`bots/telegram`, dto).then(()=>{
        commit('SET_BUSY_INDICATORS', {...state.busyIndicators, processing: false });
        dispatch('loadBots');
        resolve();
      }).catch((error) => {
        commit('SET_BUSY_INDICATORS', {...state.busyIndicators, processing: false });
        reject(error);
      });
    });
  },
  updateTelegramBot({ dispatch, commit, state }, { id, dto } = {}) {
    return new Promise( (resolve, reject) => {
      commit('SET_BUSY_INDICATORS', {...state.busyIndicators, processing: true });
      http.put(`bots/telegram/${id}`, dto).then(()=>{
        commit('SET_BUSY_INDICATORS', {...state.busyIndicators, processing: false });
        dispatch('loadBots');
        resolve();
      }).catch((error) => {
        commit('SET_BUSY_INDICATORS', {...state.busyIndicators, processing: false });
        reject(error);
      });
    });
  },
  deleteTelegramBot({ dispatch, commit, state }, { id } = {}) {
    return new Promise( (resolve, reject) => {
      commit('SET_BUSY_INDICATORS', {...state.busyIndicators, deleting: id });
      http.delete(`bots/telegram/${id}`).then(() => {
        commit('SET_BUSY_INDICATORS', {...state.busyIndicators, deleting: null });
        dispatch('loadBots');
        resolve();
      }).catch((error) => {
        commit('SET_BUSY_INDICATORS', {...state.busyIndicators, deleting: null });
        reject(error);
      });
    });
  },
  loadTelegramBot({ commit, state }, { id } = {}) {
    return new Promise( (resolve, reject) => {
      commit('SET_BUSY_INDICATORS', {...state.busyIndicators, loading: true });
      http.get(`bots/telegram/${id}`).then((response) => {
        commit('SET_BUSY_INDICATORS', {...state.busyIndicators, loading: false });
        resolve(response.data);
      }).catch((error) => {
        commit('SET_BUSY_INDICATORS', {...state.busyIndicators, loading: false });
        reject(error);
      });
    });
  },
  loadBots({ commit, state }) {
    return new Promise( (resolve, reject) => {
      commit('SET_BUSY_INDICATORS', {...state.busyIndicators, loading: true });
      http.get(`bots`).then((response)=>{
        commit('SET_BUSY_INDICATORS', {...state.busyIndicators, loading: false });
        commit('SET_BOTS', response.data);
        resolve(response.data);
      }).catch((error) => {
        commit('SET_BUSY_INDICATORS', {...state.busyIndicators, loading: false });
        reject(error);
      });
    });
  }
}
