<script>
import { mapGetters, mapMutations } from 'vuex';
import _ from "lodash";

export default {
  components: {  
   
  },
  props: {
    def: {
      type: Object,
      required: true
    },
    value:{
      required: true
    },
    board: {
      type: Boolean
    },
    selectable: {
      type: Boolean
    },
    selected: {
      type: Array
    }
  },
  computed: {
     ...mapGetters('directories', {
      directories: 'items',
      allCustomFields: 'allCustomFields'
    }),
    allRowsSelected: {
      get() {
        if(!this.selected) return false;
       
        if(this.selected.length === this.value.filter(x => !x['$_META']).length) return true; else return false;
        // const res = this.value.filter(x => !x['$_META']).find(x => !this.selected.find(a => a !== x)) ? false : true;
        // return res ;
      },
      set(v) {
        if(v) {
          this.$emit('selectedChanged', this.value);
        } else {
           this.$emit('selectedChanged', []);
        }
      }
    },
  },
  methods: {
     ...mapMutations('directoryItems', {
       openItem: 'OPEN_ITEM'
    }),
    ...mapMutations('customFields', {
      showAddressForm: 'SHOW_ADDRESS_FORM'
    }),
    raiseOnChanged() {
      this.$emit('changed');
    },
    toggleRowSelected(row){
      if(this.selected.indexOf(row) >= 0){
        this.$emit('selectedChanged', this.selected.filter(x => x !== row));
      } else {
        this.$emit('selectedChanged', [...this.selected, row]);
      }
    },
    getRelatedFieldDef(directoryFieldId, directoryRelatedFieldId) {
      const refField = this.def.tableFields.find(x => x.id === directoryFieldId);
      if(refField) {
        const directoryId = refField.directoryId;
        const directory = this.directories.find(x => x.id === directoryId);
        if(directory) {
          const directoryRelatedField = (this.allCustomFields[directoryRelatedFieldId] || null);
          return directoryRelatedField;
        }
      }
      return null;
    },
    getDirectory(dirId) {
      return this.directories.find(x => x.id === dirId);
    },
    getRelatedFielValue(tableRecord, directoryFieldId, directoryRelatedFieldId) {
     
      const refField = this.def.tableFields.find(x => x.id === directoryFieldId);
      if(refField) {
         
        const directoryId = refField.directoryId;
        const directory = this.directories.find(x => x.id === directoryId);
       
        if(directory) {
          const dirValue = tableRecord[directoryFieldId];
          if(dirValue && dirValue.customFields) {
           
            return dirValue.customFields[directoryRelatedFieldId];
          }
        }
      }
      return null;
    },
    groupTableByLinks(source) {
      var map = _.groupBy(source, function (row) {
        const key = ((row['$_META'] || {}).linkedTo || {}).number || null;
        return key;
      });
      let keys = Object.keys(map);
      keys = keys.sort((a, b) => a.localeCompare(b));
     
      const tbl = keys.map(key => ({
        linkedTo: key ? (map[key][0]['$_META'] || {}).linkedTo : null,
        rows: map[key]
      }));
    
      return tbl;
    }
  }
};
</script>

<template>
  <span>
 
    <span v-if="(def.type === 'Directory') && value" class="text-truncate text-nowrap" style="max-width:100%; display:inline-block">
      <a href="javascript:void(0)" @click="openItem({ item: value, mode: 'view', onClose: raiseOnChanged})" style="color: inherit" class="text-decoration-underline">
        <span v-if="def.displayDirectoryFieldId && value && value.customFields">
          <span v-if="def.displayDirectoryFieldId !== 'Number'">{{value.customFields[def.displayDirectoryFieldId]}}</span>
          <span v-if="def.displayDirectoryFieldId === 'Number'"><b>#{{value.number}}</b></span>
        </span>
        <span v-if="!def.displayDirectoryFieldId && value.display">{{value.display}}</span>
        <b v-if="!def.displayDirectoryFieldId && !value.display">{{('#' + value.number)}}</b>
      </a>
    </span>
     <span v-if="(def.type === 'Link') && value">
      <a :href="value">{{value}}</a>
    </span>
    <span v-if="(def.type === 'String' || def.type === 'Text' || def.type === 'PhoneNumber') && value">
      {{value}}
    </span>
    <span v-if="def.type === 'Number' && (value !== undefined && value !== null)">
      {{value.toFixed(def.precision)}}
    </span>
    <span v-if="def.type === 'Date'">
     
      {{ this.$filters.dateOnly(value)}}
    </span>
    <span v-if="def.type === 'Dropdown' && value">
      {{(def.dropdownItems.find(x => x.value === value) || {}).text}}
    </span>
    <span v-if="def.type === 'Address' && value">
      <a href="javascript:void(0)" class="font-size-16 px-2" style="color: inherit" v-if="value.lat && value.lng" @click="showAddressForm({address: value, editable: false})">
        <i class="mdi-map-marker mdi"></i>
      </a>
      {{value.address}}
    </span>
    <div v-if="def.type === 'Table' && def.tableFields && def.tableFields.length > 0 && value && value.length > 0" >
      <div>
        <template  v-if="!selectable">
          <div class="table-responsive" :class="{'mt-3': tblIndex > 0, 'dir-table-on-board': board}" v-for="(tblRow, tblIndex) in groupTableByLinks(value)" :key="tblIndex" >
            <a href="javascript:void(0)" v-if="tblRow.linkedTo && (!board || tblIndex === 0)" @click="openItem({item: tblRow.linkedTo, mode: 'view'})">
              <font-awesome-icon icon="fa-solid fa-link" />
              {{getDirectory(tblRow.linkedTo.directoryId).name}} #{{tblRow.linkedTo.number}}
            </a>
            <table class="table mb-0 table-borderless border table-hover table-sm text-wrap" v-if="(!board || tblIndex === 0)">
              <thead class="table-light">
                <tr>
                  <th style="" class="col text-wrap" v-for="tableField in def.tableFields.filter(x => !x.hidden)" :key="tableField.id" >
                    {{tableField.name}} 
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(element, index) in tblRow.rows" :key="index">
                  <td v-for="tableField in def.tableFields.filter(x => !x.hidden)" :key="tableField.id" >
                  
                    <template v-if="tableField.type !== 'DirectoryRelatedField'">
                      <custom-field :def="tableField" :value="element[tableField.id]"></custom-field>
                    </template>
                    <template v-if="tableField.type === 'DirectoryRelatedField' && getRelatedFieldDef(tableField.directoryFieldId, tableField.directoryRelatedFieldId) && getRelatedFielValue(element, tableField.directoryFieldId, tableField.directoryRelatedFieldId)">
                      <custom-field :def=" getRelatedFieldDef(tableField.directoryFieldId, tableField.directoryRelatedFieldId)" :value="getRelatedFielValue(element, tableField.directoryFieldId, tableField.directoryRelatedFieldId)"></custom-field>
                    </template>
                  </td>
                </tr>
              </tbody> 
            </table>
          </div>
        </template>
        <div class="table-responsive" v-if="selectable">
          <table class="table mb-0 table-borderless border table-hover table-sm text-wrap">
            <thead class="table-light">
              <tr>
                <th style="max-width:30px; width:30px;" v-if="selectable">
                  <input type="checkbox" v-model="allRowsSelected"/>
                </th>
                <th v-if="!selectable && value.find(x => x['$_META'])">
                  <font-awesome-icon icon="fa-solid fa-link" />
                </th>
                <th style="" class="col text-wrap" v-for="tableField in def.tableFields.filter(x => !x.hidden)" :key="tableField.id">
                  {{tableField.name}}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(element, index) in (selectable ? value.filter(x => !x['$_META']) : value)" :key="index">
                <td style="max-width:30px; width:30px;" v-if="selectable">
                  <input type="checkbox" :checked="selected.find(x => x === element)"
                    @change="e => toggleRowSelected(element)"
                  />
                </td>
                <td v-if="!selectable && value.find(x => x['$_META'])">
                  <a href="javascript:void(0)" v-if=" element['$_META']" @click="openItem({item: element['$_META'].linkedTo, mode: 'view'})">
                  #{{element['$_META'].linkedTo.number}}
                  </a>
                </td>
                <td v-for="tableField in def.tableFields.filter(x => !x.hidden)" :key="tableField.id" class="text-wrap">
                  <template v-if="tableField.type !== 'DirectoryRelatedField'">
                    <custom-field :def="tableField" :value="element[tableField.id]"></custom-field>
                  </template>
                  <template v-if="tableField.type === 'DirectoryRelatedField' && getRelatedFieldDef(tableField.directoryFieldId, tableField.directoryRelatedFieldId) && getRelatedFielValue(element, tableField.directoryFieldId, tableField.directoryRelatedFieldId)">
                    <custom-field :def=" getRelatedFieldDef(tableField.directoryFieldId, tableField.directoryRelatedFieldId)" :value="getRelatedFielValue(element, tableField.directoryFieldId, tableField.directoryRelatedFieldId)"></custom-field>
                  </template>
                </td>
              </tr>
            </tbody> 
          </table>
        </div>
      </div>
    </div>
  </span>
</template>

<style scoped>
.dir-table-on-board {
  max-height: 200px;
  overflow-y: hidden;
}
</style>