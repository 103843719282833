import http from '../../app-http'
import { debounce } from "lodash";
import { queryBuilderDefaultQueryObj } from '@/helpers';

const BOARD_PACKET_SIZE = 10;

const getDirDefaultState = () => {
  return {
   
    page: 1,
    total: 0,
    view: '',
    items:[],
    board:{

    }
  };
};

const getDefaultState = () => {
  return {
    changed:0,
    loading: false,
    processing: false,
    deleting: null,
    perPage: 30,
    directories: {},
    selectedItems: [],
    filePreviews: [],
    dataTranformation: [],
    persistent: {},
    comments: {},
    files: {},
    changes: {},
    changedItems: [],
    currentDirectoryId:  null,
    currentFilterKey: null,
    tags: {},
  }
};

export const state = getDefaultState();

const updateDirState = (directoryId, state, action) => {
  var copy = {...state.directories };
  copy[directoryId] = {...(copy[directoryId] ?? getDirDefaultState())};
  action(copy[directoryId]);
  state.directories = copy;
};


const updateDirPersistentState = (directoryId, state, action) => {
  var copy = {...state.persistent };
  copy[directoryId] = {...(copy[directoryId] ?? getDirDefaultState())};
  action(copy[directoryId]);
  state.persistent = copy;
};

export const mutations = {
  INCREMENT_CHANGED(state) {
    state.changed = state.changed+1;
  },
  SET_TAGS(state, newValue) {
    state.tags = newValue;
  },
  CHANGE_ITEM_STATUS(state, { directoryId, item, status, prevStatus }) {
    updateDirState(directoryId, state, (copy) => {
      const boardData = copy.board || {};
      const statusItems = boardData.statusItems || {};
      const prevStatusItem = statusItems[prevStatus] || { total: 0, items: []};
      const newStatusItem = statusItems[status] || { total: 0, items: []};

      prevStatusItem.items = prevStatusItem.items.filter(x => x.id !== item.id);
      if(prevStatusItem.total > 0) {
        prevStatusItem.total = prevStatusItem.total-1;
      }
      newStatusItem.items = [item,...newStatusItem.items];
      newStatusItem.total = newStatusItem.total+1;
      statusItems[prevStatus] = prevStatusItem;
      statusItems[status] = newStatusItem;
      boardData.statusItems = statusItems;

      copy.board = boardData;
    });
  },
  ADD_CHANGED(state, newItem) {
    state.changedItems = [...state.changedItems, JSON.parse(JSON.stringify(newItem))];
  },
  SET_FILES(state, { itemId, files}) {
    const o = {...state.files};
    o[itemId] = files;
    state.files = o;
  },
  SET_CHANGES(state, { itemId, changes}) {
    const o = {...state.changes};
    o[itemId] = changes;
    state.changes = o;
  },
  SET_COMMENTS(state, { itemId, comments}) {
    const o = {...state.comments};
    o[itemId] = comments;
    state.comments = o;
  },
  OPEN_ITEM(state,{ item, mode, template, dataTransformation, closeOnSave, onSave, onClose}) {
    state.selectedItems = [...state.selectedItems, {item, mode, template, dataTransformation, closeOnSave, onSave, onClose}];
  },
 
  HIDE_ITEM(state) {
    const o =  [...state.selectedItems];
    o.pop();
    state.selectedItems = o;
  },
  SHOW_DATA_TRANSFORMATION_FORM(state,{ ruleId, sourceId, onClose }) {
    console.log('SHOW_DATA_TRANSFORMATION_FORM', { ruleId, sourceId, onClose });
    state.dataTranformation = [...state.dataTranformation, {ruleId, sourceId, onClose}];
  },
  HIDE_DATA_TRANSFORMATION_FORM(state) {
    const o =  [...state.dataTranformation];
    o.pop();
    state.dataTranformation = o;
  },
  SHOW_FILE_PREVIEW(state,{ url, fileName }) {
    state.filePreviews = [...state.filePreviews, {url, fileName}];
  },
  HIDE_FILE_PREVIEW_FORM(state) {
    const o =  [...state.filePreviews];
    o.pop();
    state.filePreviews = o;
  },
  SET_PAGE(state, { directoryId, page} ) {
    updateDirState(directoryId, state, (copy) => {
      copy.page = page;
    });
  },
  SET_ITEMS(state, { directoryId, items}) {
    updateDirState(directoryId, state, (copy) => {
      copy.items = items;
    });
  },
  SET_TOTAL(state, { directoryId, total} ) {
    updateDirState(directoryId, state, (copy) => {
      copy.total = total;
    });
  },
  UPDATE_STATUS_ITEMS(state, { directoryId, status, items, total }) {
    
    updateDirState(directoryId, state, (copy) => {
      const board = {...(copy.board || { })};

      if(!board.statusItems)
        board.statusItems = {};

      board.statusItems[status] = {items, total};
      
      copy.board = board;
    });
  },
  SET_STATUS_ITEMS(state, { directoryId, status, items, total}) {
    updateDirState(directoryId, state, (copy) => {
      const board = {...(copy.board || { })};

      if(!board.statusItems)
        board.statusItems = {};

      board.statusItems[status] = {items, total};
      
      copy.board = board;
    });
  },
  SET_COLUMNS_WIDTH(state, { directoryId, columnsWidth} ) {
    updateDirPersistentState(directoryId, state, (copy) => {
      copy.columnsWidth = columnsWidth;
    });
  },
  SET_COLUMNS_ORDER(state, { directoryId, columnsOrder} ) {
    updateDirPersistentState(directoryId, state, (copy) => {
      copy.columnsOrder = columnsOrder;
    });
  },
  SET_PINNED_COLUMNS(state, { directoryId, pinnedColumns}) {
    updateDirPersistentState(directoryId, state, (copy) => {
      copy.pinnedColumns = pinnedColumns;
    });
  },
  SET_BOARD_ORDER_BY(state, { directoryId, field, asc}) {
    updateDirPersistentState(directoryId, state, (copy) => {
      copy.boardOrderBy = {
        field: field,
        asc: asc
      };
    });
  },
  SET_ORDER_BY(state, { directoryId, field, asc}) {
    updateDirPersistentState(directoryId, state, (copy) => {
      copy.orderBy = {
        field: field,
        asc: asc
      };
    });
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  SET_LOADING(state, newValue) {
    state.loading = newValue;
  },
  SET_PROCESSING(state, newValue) {
    state.processing = newValue;
  },
  SET_DELETING(state, newValue) {
    state.deleting = newValue;
  },
  SET_PERSISTENT(state, newValue) {
    state.persistent = newValue;
  },
  SET_VIEW(state, { directoryId, newValue }) {
    updateDirPersistentState(directoryId, state, (copy) => {
      copy.view = newValue;
    });
  },
  SET_FILTER(state, { directoryId, filter}) {
    updateDirPersistentState(directoryId, state, (copy) => {
      copy.filter = filter;
    });
  },
  RESET_FILTER(state, { directoryId}) {
    updateDirPersistentState(directoryId, state, (copy) => {
      copy.filter = {
        keyword: null,
        customFields: []
      };
    });
  },
  INIT(state, {directoryId, defaultView, filterKey}) {
   
    let po = state.persistent[directoryId];
    if(!po) {
      po = getDirDefaultState();
    }
    
    if(!po.pinnedColumns) {
      po.pinnedColumns = [];
    }
    if(!po.columnsWidth) {
      po.columnsWidth = {};
    }
    if(!po.columnsOrder) {
      po.columnsOrder = [];
    }
    if(!po.filter) {
      po.filter = {
        keyword: null,
        customFields: []
      };
    }

    if(!po.orderBy) {
      po.orderBy = {
        field: 'Number',
        asc: false
      };
    }

    if(!po.view) {
      po.view = defaultView;
    }

    state.persistent[directoryId] = po;

    state.currentDirectoryId = directoryId;
    state.currentFilterKey = filterKey;
  }
}

export const getters = {
  changed(state) {
    return state.changed;
  },
  dataTranformation(state) {
    return state.dataTranformation || [];
  },
  filePreviews(state) {
    return state.filePreviews || [];
  },
  tags(state) {
    return state.tags || {};
  },
  changedItems(state) {
    return state.changedItems;
  },
  files(state) {
    return state.files;
  },
  changes(state) {
    return state.changes;
  },
  comments(state) {
    return state.comments;
  },
  selectedItems (state) {
    return state.selectedItems;
  },
  perPage (state) {
    return state.perPage;
  },
  persistentData (state) {
    return state.persistent;
  },
  itemsData (state) {
    return state.directories;
  },
  loading(state) {
    return state.loading;
  },
  processing(state) {
    return state.processing;
  },
  deleting(state) {
    return state.deleting;
  },
}

export const actions = {
  async setView( {dispatch, commit}, { directoryId, newValue }) {
    commit('SET_VIEW', { directoryId, newValue });
    await dispatch('load', { directoryId });
    await dispatch('debounceSavePersistent');
  },
  async init( {dispatch, commit}, { directoryId, defaultView, filterKey }) {
    await dispatch('loadPersistent');
    commit('INIT', { directoryId, defaultView, filterKey });
    await dispatch('load', { directoryId })
  },
  setFilter({dispatch, commit}, { directoryId, filter }) {
    commit('SET_PAGE', { directoryId, page: 1 });
    commit('SET_FILTER', { directoryId, filter });
    dispatch('debounceSavePersistent');
    dispatch('debounceLoad', { directoryId });
  },
  
  setBoardOrderBy({dispatch, commit}, { directoryId, field, asc } = {}) {
    commit('SET_BOARD_ORDER_BY',  { directoryId, field, asc });
    dispatch('debounceSavePersistent');
    dispatch('debounceLoad', {directoryId});
  },
  setOrderBy({dispatch, commit}, { directoryId, field, asc } = {}) {
    commit('SET_ORDER_BY',  { directoryId, field, asc });
    dispatch('debounceSavePersistent');
    dispatch('debounceLoad', {directoryId});
  },
  resetFilter({dispatch, commit}, { directoryId, filter }) {
    commit('SET_PAGE', { directoryId, page: 1 });
    commit('RESET_FILTER', { directoryId, filter });
    dispatch('debounceSavePersistent');
    dispatch('debounceLoad', { directoryId });
  },
  debounceLoad: debounce(async ({ dispatch }, { directoryId}) =>  {
    await dispatch('load',  { directoryId });
  }, 1000),
  setColumnsWidth({dispatch, commit}, { directoryId, columnsWidth} = {}) {
    commit('SET_COLUMNS_WIDTH',  { directoryId, columnsWidth})
    dispatch('debounceSavePersistent');
  },
  setPinnedColumns({dispatch, commit}, { directoryId, pinnedColumns} = {}) {
    commit('SET_PINNED_COLUMNS', { directoryId, pinnedColumns})
    dispatch('debounceSavePersistent');
  },
  setColumnsOrders({dispatch, commit}, { directoryId, columnsOrder} = {}) {
    commit('SET_COLUMNS_ORDER', { directoryId, columnsOrder})
    dispatch('debounceSavePersistent');
  },
  async setPage({ dispatch, commit }, { directoryId, page}) {
    commit('SET_PAGE', { directoryId, page});
    await dispatch('load', { directoryId });
  },
  async create({ dispatch, commit, state }, { directoryId, dto } = {}) {
    commit('SET_PROCESSING', true);
    try {
      const resp = await  http.post(`directories/${directoryId}/items`, dto);
      commit('ADD_CHANGED', { action: 'CREATE', item: resp.data });
      
      await dispatch('loadComments', { directoryId, itemId: resp.data.id });
      dispatch('loadTags'); //don't wait
      
      commit('INCREMENT_CHANGED');
      dispatch('debounceLoad' , { directoryId: state.currentDirectoryId });  //don't wait

      return resp.data;
    } finally{
      commit('SET_PROCESSING', false);
    }
  },
  async update({ dispatch, commit, state }, { directoryId, id, dto } = {}) {
    commit('SET_PROCESSING', true);
    try {
      const resp = await http.put(`directories/${directoryId}/items/${id}`, dto);
      commit('ADD_CHANGED', { action: 'UPDATE', item: resp.data });
      
      await dispatch('loadComments', { directoryId, itemId: id });
      await dispatch('loadChanges', { directoryId, itemId: id }); 

      
      
      dispatch('loadTags'); //don't wait
      dispatch('debounceLoad' , { directoryId: state.currentDirectoryId }); //don't wait

      commit('INCREMENT_CHANGED');
      return resp.data;
    }
    finally{
      commit('SET_PROCESSING', false);
    }
    
  },
  async changeStatus({ dispatch, commit, state }, { directoryId, item, status, prevStatus, note } = {}) {
    commit('SET_PROCESSING', true);
    try {
      commit('CHANGE_ITEM_STATUS', { directoryId, item, status, prevStatus });
      const resp = await http.put(`directories/${directoryId}/items/${item.id}/status/`+status, { text: note });
      commit('ADD_CHANGED', { action: 'UPDATE', item: item });
      await dispatch('debounceLoad' , { directoryId: state.currentDirectoryId });
      commit('INCREMENT_CHANGED');
      return resp.data;
    }
    finally{
      commit('SET_PROCESSING', false);
    }
    
  },
  async delete({ dispatch, commit, state }, { directoryId, id } = {}) {
    commit('SET_DELETING', id);
    try {
      await  http.delete(`directories/${directoryId}/items/${id}`);
      commit('ADD_CHANGED', { action: 'DELETE', item: {directoryId,id} });
      commit('INCREMENT_CHANGED');
    }
    finally{
      commit('SET_DELETING', null);
    }
    await dispatch('load' , { directoryId: state.currentDirectoryId });
  },
  // async reloadItem( {commit, state }, {directoryId, itemId}) {
  //   const resp = await http.post(`directories/${directoryId}/items/${itemId}`);
  //   const obj = resp.data;

  // },
  async move({ dispatch, commit } =  {}, { directoryId, prevId, itemId, status, ignorePosition, note} = {} ) {
    await http.post(`directories/${directoryId}/items/board-move`, { prevId, itemId, status, ignorePosition, note });
    await dispatch('debounceLoad', { directoryId });
    commit('INCREMENT_CHANGED');
  },
  async more ({ dispatch }, { directoryId, status }) {
    await dispatch('load',  { directoryId, status, more: true});
  },
  loadToJson({dispatch}) {
    dispatch('load', { json: true });
  },
  async load({ commit, state, rootGetters }, { directoryId, status, more, json } = {}) {
    if(!directoryId) {
      directoryId = state.currentDirectoryId;
    }
    if(!directoryId) {
      return;
    }

    const filters = rootGetters['filters/filters'][state.currentFilterKey] || queryBuilderDefaultQueryObj();
  //  console.log('filters', filters);

    const dirData = state.directories[directoryId] || getDirDefaultState();
    const dirPerData = state.persistent[directoryId] || {orderBy : { field: 'Number', asc: false }, boardOrderBy: { field: '$$status-pos$$', asc: false }};
    let orderBy = dirPerData.view === 'board' ? (dirPerData.boardOrderBy || { field: '$$status-pos$$', asc: false }) : dirPerData.orderBy || ({ field: 'Number', asc: false });

    if( dirPerData.view === 'board') {
      const directory = rootGetters['directories/items'].find(x => x.id === directoryId);
      if(!directory) return;
     
      let batchReq = [];
      for(let i = 0; i < directory.statusList.length; i++) {
        const statusObj = directory.statusList[i];
        if(status && statusObj.id !== status) continue;

        const boardData = dirData.board || {};
        const statusItems = boardData.statusItems || {};
        const statusItem = statusItems[statusObj.id] || { total: 0, items: []};

        const req = {
          skip: more ? statusItem.items.length : 0,
          tags: filters.tags || [],
          tagsLgOp: filters.tagsLgOp,
          keyword: filters.keyword,
          keywordLgOp: filters.keywordLgOp,
          statusLgOp: filters.statusLgOp,
          statusList: [statusObj.id],
          conditions: filters.conditions || [],
          take: (more || statusItem.items.length <= BOARD_PACKET_SIZE) ? BOARD_PACKET_SIZE : statusItem.items.length,
          status: statusObj.id,
          orderBy: {
            field: orderBy.field || '$$status-pos$$',
            asc: orderBy.asc,
          }
        };
        batchReq.push({req, statusObj, statusItem});
      }

      commit('SET_LOADING', true);
      const resp = await http.post(`directories/${directoryId}/items/search-batch`, batchReq.map(x => x.req));
      const batchRes =  resp.data;
      batchRes.forEach((res, i) => {
        const  { statusObj, statusItem } = batchReq[i]
        if(more) {
          commit('SET_STATUS_ITEMS', {directoryId, status: statusObj.id, items: [...statusItem.items, ...res.items], total: res.total });
        } else {
          commit('SET_STATUS_ITEMS', {directoryId, status: statusObj.id, items: res.items, total: res.total });
        }
      }); 
      commit('SET_LOADING', false);
    } else {
      const skip = (dirData.page - 1) * state.perPage;
      const req = {
        skip: skip,
        tags: filters.tags || [],
        tagsLgOp: filters.tagsLgOp,
        keyword: filters.keyword,
        keywordLgOp: filters.keywordLgOp,
        statusList: filters.statusList,
        statusLgOp: filters.statusLgOp,
        conditions: filters.conditions || [],
        take: state.perPage,
    
        orderBy: {
          field: orderBy.field || 'Number',
          asc: orderBy.asc,
        }
      }
      commit('SET_LOADING', true);
      let url = `directories/${directoryId}/items/search`;
      if(json) {
        url += '?json=true';
      }
    
      if(json) {
        const resp = await http.post(url, req, { responseType: 'blob' });
        commit('SET_LOADING', false);
        const objUrl = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement('a');
        link.href = objUrl;
        link.setAttribute('download', 'data.json'); //or any other extension
        document.body.appendChild(link);
        link.click();
      } else {
        const resp = await http.post(url, req);
        commit('SET_LOADING', false);
        commit('SET_TOTAL', {directoryId, total: resp.data.total });
        commit('SET_ITEMS', {directoryId, items: resp.data.items });
      }
    }
    
  },
  debounceSavePersistent: debounce(async ({ state }) =>  {
    await http.post(`user-storage/directory-items`, state.persistent );
  }, 1000),
  async loadByNumber(_, { directoryId, number }) {
  
    const skip = 0;
    const req = {
      skip: skip,
      keyword: '#' + number,
      take: 1,
      orderBy: {
        field: 'Number',
        asc: true,
      }
    }
     
    const resp = await http.post(`directories/${directoryId}/items/search?fullLoad=true`, req);
   
    return resp.data.items[0] || null;
  },
  async loadById({ commit }, { directoryId, id }) {
    commit('SET_LOADING', true);
    const resp = await http.get(`directories/${directoryId}/items/${id}`);
    commit('SET_LOADING', false);
    return resp.data;
  },
  async loadPersistent({ commit }) {
    const resp = await http.get(`user-storage/directory-items`);
    if(resp.data)
      commit('SET_PERSISTENT', resp.data);
  },
  loadComments({ commit }, { directoryId, itemId } = {}) {
    
    return new Promise( (resolve, reject) => {
     
      http.get(`directories/${directoryId}/items/${itemId}/notes`).then((response)=>{
      
        commit('SET_COMMENTS', {itemId, comments: response.data })

        resolve(response.data);
      }).catch((error) => {
      
        reject(error);
      });
    });
  },
  addComment({ dispatch, state }, { directoryId, itemId, text } = {}) {
    return new Promise( (resolve, reject) => {
     
      http.post(`directories/${directoryId}/items/${itemId}/notes`, { text }).then(()=>{
      
        dispatch('loadComments', { directoryId, itemId });
        dispatch('load', { directoryId: state.currentDirectoryId });

        resolve();
      }).catch((error) => {
      
        reject(error);
      });
    });
  },
  updateComment({ dispatch,state  }, { directoryId, itemId, id, text } = {}) {
    return new Promise( (resolve, reject) => {
     
      http.put(`directories/${directoryId}/items/${itemId}/notes/${id}`, { text }).then(()=>{
      
        dispatch('loadComments', { directoryId, itemId });
        dispatch('load', { directoryId: state.currentDirectoryId });

        resolve();
      }).catch((error) => {
       
        reject(error);
      });
    });
  },
  deleteComment({ dispatch, state }, { directoryId, itemId, id } = {}) {
    return new Promise( (resolve, reject) => {
      
      http.delete(`directories/${directoryId}/items/${itemId}/notes/${id}`).then(()=>{
       
        dispatch('loadComments', { directoryId, itemId });
        dispatch('load', { directoryId: state.currentDirectoryId });

        resolve();
      }).catch((error) => {
       
        reject(error);
      });
    });
  },
  loadFiles({ commit }, { directoryId, itemId } = {}) {
    return new Promise( (resolve, reject) => {
     
      http.get(`directories/${directoryId}/items/${itemId}/files`).then((response)=>{
      
        commit('SET_FILES', {itemId, files: response.data })

        resolve(response.data);
      }).catch((error) => {
      
        reject(error);
      });
    });
  },
  loadChanges({ commit }, { directoryId, itemId } = {}) {
    return new Promise( (resolve, reject) => {
     
      http.get(`directories/${directoryId}/items/${itemId}/changes`).then((response)=>{
      
        commit('SET_CHANGES', {itemId, changes: response.data })

        resolve(response.data);
      }).catch((error) => {
      
        reject(error);
      });
    });
  },
  async addFile({ dispatch, state }, { directoryId, itemId, file } = {}) {
    const formData = new FormData();
    formData.append('file', file.fileBlob, file.fileName);
    formData.append('json', JSON.stringify({...file, fileBlob: null}));
    await http({
      method: 'post',
      url:  `directories/${directoryId}/items/${itemId}/files`,
      data: formData,
      headers: {
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      }
    });

    await dispatch('loadFiles', { directoryId, itemId });
    await dispatch('load', { directoryId: state.currentDirectoryId });
    await dispatch('loadChanges', { directoryId, itemId }); 
  },
  updateFile({ dispatch, state }, { directoryId, itemId, id, text } = {}) {
    return new Promise( (resolve, reject) => {
     
      http.put(`directories/${directoryId}/items/${itemId}/files/${id}`, { text }).then(()=>{
      
        dispatch('loadFiles', { directoryId, itemId });
        dispatch('load', { directoryId: state.currentDirectoryId });

      
        resolve();
      }).catch((error) => {
       
        reject(error);
      });
    });
  },
  deleteFile({ dispatch, state }, { directoryId, itemId, id } = {}) {
    return new Promise( (resolve, reject) => {
      
      http.delete(`directories/${directoryId}/items/${itemId}/files/${id}`).then(async ()=>{
       
        await dispatch('loadFiles', { directoryId, itemId });
        await dispatch('load', { directoryId: state.currentDirectoryId });
        await dispatch('loadChanges', { directoryId, itemId }); 

        resolve();
      }).catch((error) => {
       
        reject(error);
      });
    });
  },
  loadTags({ commit }) {
    return new Promise( (resolve, reject) => {
      http.get(`tags`).then((response)=>{
        commit('SET_TAGS', response.data);
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  async updateTag({ dispatch }, input) {
    await http.put(`tags`, input);
    await dispatch('loadTags'); 
  },
  async deleteTag({ dispatch }, input) {
    await http.post(`tags/deleted`, input);
    await dispatch('loadTags'); 
  },
}
