import http from '../../app-http'

// const LOAD_BATCH_SIZE= 50;

const getDefaultState = () => {
    return {
        latestUserNotifications: [],
        userNotifications: [],
        totalUserNotifications: 0,
        loadingUserNotifications: false
    };
};
export const state = getDefaultState();

export const mutations = {
    MARK_ALL_AS_READ(state) {
        state.latestUserNotifications.filter(x => !x.readAt).forEach(element => {
            element.readAt = new Date();
        });
        state.userNotifications.filter(x => !x.readAt).forEach(element => {
            element.readAt = new Date();
        });
    },
    MARK_AS_READ(state, { id } = {}) {
        const ln = state.latestUserNotifications.find(x => x.id == id);
        if(ln) {
            ln.readAt = new Date();
        }
        const an = state.userNotifications.find(x => x.id == id);
        if(an) {
            an.readAt = new Date();
        }
    },
    SET_LATEST_USER_NOTIFICATIONS(state, newValue) {
        state.latestUserNotifications = newValue;
    },
    SET_USER_NOTIFICATIONS(state, { items, total} = {}) {
        state.userNotifications = items;
        state.totalUserNotifications = total;
    },
    SET_LOADING_USER_NOTIFICATIONS(state, newValue) {
        state.loadingUserNotifications = newValue;
    }
}

export const getters = {
    latestUserNotifications(state) {
        return state.latestUserNotifications;
    },
    userNotifications(state) {
        return state.userNotifications;
    },
    allUserNotificationsLoaded(state) {
        return state.allUserNotificationsLoaded;
    },
    loadingUserNotifications(state) {
        return state.loadingUserNotifications;
    }
}

export const actions = {
    async loadLatest({ commit }) {
        const resp = await http.get(`user-notifications?latest=true`);
        commit('SET_LATEST_USER_NOTIFICATIONS', resp.data);
    },
    // async refresh({ commit, state }) {
    //     const skip = 0;
    //     const take = state.userNotifications.length ?? LOAD_BATCH_SIZE;
    //     const resp = await http.get(`user-notifications?skip=${skip}&take=${take}`);
    //     commit('SET_USER_NOTIFICATIONS', resp.data);
    // },
    // async load({ commit, state }, { reset } = {}) {
    //     commit('SET_LOADING_USER_NOTIFICATIONS', true);
    //     const skip = reset ? 0 : state.userNotifications.length;
    //     const take = LOAD_BATCH_SIZE;
    //     const resp = await http.get(`user-notifications?skip=${skip}&take=${take}`);
    //     const { items, total } = resp.data;
    //     if(reset) {
    //         commit('SET_USER_NOTIFICATIONS', { items, total });
    //     } else {
    //         commit('SET_USER_NOTIFICATIONS', { items: [...state.userNotifications, ...items], total });
    //     }
       
    //     commit('SET_LOADING_USER_NOTIFICATIONS', false);
    // },
    async markAllAsRead({ commit, dispatch }) {
        commit('MARK_ALL_AS_READ');
        await http.put(`user-notifications/read`);
        dispatch('loadLatest');
        //dispatch('refresh');
    },
    async markAsRead({ commit, dispatch }, { id } = {}) {
        commit('MARK_AS_READ', { id });
        await http.put(`user-notifications/read?notificationId=${id}`);
        dispatch('loadLatest');
        //dispatch('refresh');
    }
}
