import http from '../../app-http'
import { parseErrors} from "@/helpers"

const getDefaultState = () => {
  return {
    loading: false,
    processing: false,
    deleting: null,
    items:{},
    tableForms: [],
    addressForms: []
  }
};

export const state = getDefaultState();

export const mutations = {
  SHOW_TABLE_VALUE_FORM(state,{ value, def, onSave, arg}) {
    state.tableForms = [...state.tableForms, {value, def, onSave, arg}];
  },
  HIDE_TABLE_VALUE_FORM(state) {
    const o =  [...state.tableForms];
    o.pop();
    state.tableForms = o;
  },
  SHOW_ADDRESS_FORM(state,{ address, editable, onSave, arg}) {
    state.addressForms = [...state.addressForms, {address, editable, onSave, arg}];
  },
  HIDE_ADDRESS_FORM(state) {
    const o =  [...state.addressForms];
    o.pop();
    state.addressForms = o;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  SET_ITEMS(state, newValue) {
    state.items = newValue;
  },
  SET_LOADING(state, newValue) {
    state.loading = newValue;
  },
  SET_PROCESSING(state, newValue) {
    state.processing = newValue;
  },
  SET_DELETING(state, newValue) {
    state.deleting = newValue;
  }
}

export const getters = {
  addressForms (state) {
    return state.addressForms || [];
  },
  tableForms (state) {
    return state.tableForms || [];
  },
  items (state) {
    return state.items || {};
  },
  loading(state) {
    return state.loading;
  },
  processing(state) {
    return state.processing;
  },
  deleting(state) {
    return state.deleting;
  },
}

export const actions = {
  async create({ dispatch, commit }, { entity, dto } = {}) {
    commit('SET_PROCESSING', true);
    await  http.post(`custom-fields/${entity}`, dto);
    commit('SET_PROCESSING', false);
    await dispatch('load');
  },
  update({ dispatch, commit }, { entity,id, dto } = {}) {
    return new Promise( (resolve, reject) => {
      commit('SET_PROCESSING', true);
      http.put(`custom-fields/${entity}/` + id, dto).then(()=>{
        commit('SET_PROCESSING', false);
        dispatch('load');
        resolve();
      }).catch((error) => {
        commit('SET_PROCESSING', false);
        const errors = parseErrors(error);
        reject(errors);
      });
    });
  },
  delete({ dispatch, commit }, { entity,id } = {}) {
    return new Promise( (resolve, reject) => {
      commit('SET_DELETING', id);
      http.delete(`custom-fields/${entity}/` + id).then(()=>{
        commit('SET_DELETING', null);
        dispatch('load');
        resolve();
      }).catch((error) => {
        commit('SET_DELETING', null);
        const errors = parseErrors(error);
        reject(errors);
      });
    });
  },
  restore({ dispatch, commit }, { entity,id } = {}) {
    return new Promise( (resolve, reject) => {
      commit('SET_DELETING', id);
      http.post(`custom-fields/${entity}/restore` + id).then(()=>{
        commit('SET_PROCESSING', null);
        dispatch('load');
        resolve();
      }).catch((error) => {
        commit('SET_DELETING', null);
        const errors = parseErrors(error);
        reject(errors);
      });
    });
  },
  load({ commit }) {
    return new Promise( (resolve, reject) => {
      commit('SET_LOADING', true);
      http.get(`custom-fields`).then((response)=>{
        commit('SET_LOADING', false);
        commit('SET_ITEMS', response.data);
        resolve(response.data);
      }).catch((error) => {
        commit('SET_LOADING', false);
        const errors = parseErrors(error);
        reject(errors);
      });
    });
  },
  reorder({ dispatch, commit, state }, { entity, list } = {}) {
    return new Promise( (resolve, reject) => {
      const a = {...state.items};
      const o = a[entity];
      const v = list.map(id => o.find(x => x.id == id));
      a[entity] = v;
      commit('SET_ITEMS', a);
      http.post(`custom-fields/${entity}/order`, { list }).then(() => {
        dispatch('load');
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  },
}
