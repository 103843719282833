import http from '../../app-http'

export const state = {
    ready: false,
    loading: false
}

export const mutations = {
    SET_LOADING(state, newValue) {
        state.loading = newValue;
    },
    SET_READY(state, newValue) {
        state.ready = newValue;
    },
}

export const getters = {
    loading(state) {
        return state.loading;
    },
    ready(state) {
        return state.ready;
    }
}

export const actions = {
    reload({commit, dispatch}) {
        commit('SET_READY', false);
        dispatch('refresh');
    },
    async refresh({ commit, dispatch, rootGetters }) {
        if(rootGetters['auth/token'] && rootGetters['auth/currentUser']) {
            commit('SET_LOADING', true);
            const response = await http.get(`boot`);
            const { data } = response;

            commit('auth/SET_CURRENT_USER', data.auth.user, { root: true });
            commit('auth/SET_TOKEN', data.auth.token, { root: true });

            commit('users/SET_ITEMS', data.users, { root: true });
            commit('userGroups/SET_ITEMS', data.userGroups, { root: true });

            commit('usageProfiles/SET_ITEMS', data.usageProfiles, { root: true });
            commit('boards/SET_ITEMS', data.boards, { root: true });

            commit('rules/SET_DATA_TRANSFORMATION', data.dataTransformation, { root: true });

            commit('directories/SET_ITEMS', data.directories, { root: true });
            commit('directoryItems/SET_TAGS', data.tags, { root: true });

            commit('chat/SET_UNREAD_MESSAGES_COUNT',  { value: data.unreadMessagesCount }, { root: true });

            commit('notifications/SET_LATEST_USER_NOTIFICATIONS', data.latestUserNotifications, { root: true });
            
            commit('SET_LOADING', false);
    
            window.appIsReady = true;
            window.sendAppEvent('appReady');
            window.setTimeout(() => {
                commit('SET_READY', true);
                dispatch('reminders/initTimer', null, { root: true });
            }, 500);
                
            // const url = new URL(location.href);
            // const processId = url.searchParams.get('_process_id');
            // let process = null;
            // if(processId) {
            //     const resp = await http.get('processes/' + processId);
            //     if(resp.data) {
            //         process = resp.data;
            //     }
            // }
            // window.setTimeout(() => {
            //     commit('SET_READY', true);
            //     if(process) {
            //         commit('processes/SET_EDITED_PROCESS',  { process }, { root: true });
                  
            //     }
            // }, 500);
            
        }
    }
}
