// import Vue from "vue";
// import VueRouter from "vue-router";
import http from '@/app-http'

import { createWebHistory, createRouter } from "vue-router";
import {forceClose } from "@/modals";

import routes from "./routes";
// import VueMeta from "vue-meta";
import store from "@/state/store";

// Vue.use(VueRouter);

// Vue.use(VueMeta, {
//   // The component option name that vue-meta looks for meta info on.
//   keyName: "page",
// });
const router = createRouter({
  history: createWebHistory(),
  routes,
  // mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.afterEach((to) => {

  const title = (to.meta || {}).title;
  document.title = title ? (title + " - " + process.env.VUE_APP_TITLE): process.env.VUE_APP_TITLE;

  const userId = store.state.auth && store.state.auth.currentUser ? store.state.auth.currentUser.id : null;
  http.post('us_', { userId,  url: to.fullPath});
})

// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
 
  forceClose();
  
  // Check if auth is required on this route
  // (including nested routes).
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired)
  const isAllow = routeTo.meta.isAllow;

  // If auth isn't required for the route, just continue.
  if (!authRequired) {
   
    return next();
  }

   // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line no-inner-declarations
  function redirectToLogin() {
   
    // Pass the original route to the login component
    next({ name: 'login', query: { redirectFrom: routeTo.fullPath } })
  }

  // If auth is required and the user is logged in...
  if (store.state.auth.token) {
    if(isAllow) {
      if(store.state.auth && store.state.auth.currentUser) {
        if(isAllow(store.state.auth)) {
          return next();
        }
      }

      store.dispatch('auth/logOut')
      redirectToLogin();
    }
    return next();
  }

  // If auth is required and the user is NOT currently logged in,
  // redirect to login.
  redirectToLogin()
})

router.beforeResolve(async (routeTo, routeFrom, next) => {

// Create a `beforeResolve` hook, which fires whenever
// `beforeRouteEnter` and `beforeRouteUpdate` would. This
// allows us to ensure data is fetched even when params change,
// but the resolved route does not. We put it in `meta` to
// indicate that it's a hook we created, rather than part of
// Vue Router (yet?).
try {
  // For each matched route...
  for (const route of routeTo.matched) {
    await new Promise((resolve, reject) => {
      // If a `beforeResolve` hook is defined, call it with
      // the same arguments as the `beforeEnter` hook.
      if (route.meta && route.meta.beforeResolve) {
      
        route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
          // If the user chose to redirect...
          if (args.length) {
            // If redirecting to the same route we're coming from...
            // Complete the redirect.
            next(...args)
            reject(new Error('Redirected'))
          } else {
            resolve()
          }
        })
      } else {
        // Otherwise, continue resolving the route.
        resolve()
      }
    })
  }
  // If a `beforeResolve` hook chose to redirect, just return.
} catch (error) {
  return
}

    // If we reach this point, continue resolving the route.
  next()
})


export default router;
