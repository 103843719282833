import http from '../../app-http'
import { debounce } from "lodash";

const getDefaultState = () => {
  return {
    filters:{

    }
  }
};

export const state = getDefaultState();

export const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  SET_FILTERS(state, newValue) {
    state.filters = newValue;
  },
  SET_FILTER(state, {key, value} = {}) {
    const o = {...state.filters};
    o[key] = value;
    state.filters = o;
  }
}

export const getters = {
  filters(state) {
    return state.filters;
  }
}

export const actions = {
  setFilter({ dispatch, commit }, {key, value} = {}) {
    commit('SET_FILTER', {key, value})
    dispatch('debounceSaveUserSettings');
  },
  debounceSaveUserSettings: debounce(async ({ dispatch }) =>  {
    await dispatch('saveUserSettings');
  }, 1000),
  loadUserSettings({ commit }) {
    return new Promise( (resolve, reject) => {
      http.get(`user-storage/filters`).then((response)=>{
        if(response.data) {
          commit('SET_FILTERS', response.data);
        }
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  saveUserSettings({ state }) {
    return new Promise( (resolve, reject) => {
      http.post(`user-storage/filters`, state.filters).then((response)=>{
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
  }
}
