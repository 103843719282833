import http from '../../app-http'
import store from "@/state/store";

import * as XLSX from "xlsx";

export default function factory(dir, field, extenders) {

    if(dir.code === 'Request' && field.code === 'Products') {
        extenders.editButtons = [
            ...extenders.editButtons,
            { label: 'Импорт (Excel)', click: importProducts, editMode: true, cls: 'btn btn-sm btn-secondary', id: field.id + '_btn1' }
        ]
    }
    
    return extenders;
}

async function importProducts(obj, { updater, progress }) {
    progress(true);
    let file = null;
    try {
        const fileHandles = await window.showOpenFilePicker({
            types: [
                {
                  description: 'Excel',
                  accept: {
                    'excel/*': ['.xlsx']
                  }
                },
              ],
        });
        if(fileHandles.length > 0) {
            const fileHandle = fileHandles[0];
            file = await fileHandle.getFile();
        } else {
            progress(false);
            return;
        }
    } catch {
        progress(false);
        return;
    }
  
    const fileReader = new FileReader();
    fileReader.onload = async () => {
        
        const excelData = XLSX.read(fileReader.result);
        //console.log('excelData', {excelData});
        const res = Object.keys(excelData.Sheets).map(name => ({
            name,
            orig: excelData.Sheets[name],
            data: XLSX.utils.sheet_to_json(excelData.Sheets[name], {  
                raw: true,
                rawNumbers: true,
                header: 1
            }),
            })
        );
        if(res.length === 0) {
            progress(false);
            return;
        }
        try {
            await importJsonProducts(obj, res[0]);
            updater(obj);
        }
        finally {
            progress(false);
        }
    };

    fileReader.readAsArrayBuffer(file);    
}

async function importJsonProducts(obj, json) {
    const unitsMeasurementDirectory = store.state.directories.items.find(x => x.code === 'unitsMeasurement');
    const unitsMeasurementNameField = unitsMeasurementDirectory.fields.find(x => x.code === 'Name');
    let unitsMeasurementItems = await loadAllDirectoryItems(unitsMeasurementDirectory);

    const productSourceDirectory = store.state.directories.items.find(x => x.code === 'ProductSource');
    const productSourceNameField = productSourceDirectory.fields.find(x => x.code === 'Name');
    let productSourceItems = await loadAllDirectoryItems(productSourceDirectory);    

    const productDirectory = store.state.directories.items.find(x => x.code === 'Product');
    const productArticulField = productDirectory.fields.find(x => x.code === 'Articul');
    const productNameField = productDirectory.fields.find(x => x.code === 'Name');
    const productMeasureUnitField = productDirectory.fields.find(x => x.code === 'MeasureUnit');
    const productSourceField = productDirectory.fields.find(x => x.code === 'Source');
    let productItems = await loadAllDirectoryItems(productDirectory);    

    const requestDirectory = store.state.directories.items.find(x => x.code === 'Request');
    const productsTableDef = requestDirectory.fields.find(x => x.code === 'Products');
    const productsTableQtyField =  productsTableDef.tableFields.find(x => x.code === 'Qty');
    const productsTablePriceField =  productsTableDef.tableFields.find(x => x.code === 'price');
    const productsTableSumField =  productsTableDef.tableFields.find(x => x.code === 'sum');
    const productsTableProductField =  productsTableDef.tableFields.find(x => x.code === 'Product');
    
    let res = []; 
    let naklRow = (json.data[0] || {})[3]; // nakladna
    if(naklRow) {
        console.log('naklRow', naklRow);
        if(!obj.fields.NumNakl) {
            obj.fields.NumNakl = naklRow.toString();
        } else {
            var arr = obj.fields.NumNakl.toString().split(',');
            arr.push(naklRow);
            obj.fields.NumNakl = arr.join(',');
        }
    }
    
    for(let i = 2; i < json.data.length; i++) {
        const row = json.data[i];

        let articulText = row[1];
        
        let productText = row[2];
        let unitMeasureText = row[3];
        let qty = row[4] || 0;
        let price = row[5] || 0;
        let sum = row[6] || 0;
        let productSourceText = row[7];
        
        if(!unitMeasureText || !productSourceText || !articulText || !productText) continue;

        let articulTextOrig = articulText.toString().trim();
        let productTextOrig = productText.trim();
        let unitMeasureTextOrig = unitMeasureText.trim();
        let productSourceTextOrig = productSourceText.trim().substring(0, productSourceText.indexOf('\\')) || productSourceText.trim();

        articulText = articulTextOrig.toLowerCase();
        productText = productTextOrig.toLowerCase();
        unitMeasureText = unitMeasureTextOrig.toLowerCase();
        productSourceText = productSourceTextOrig.toLowerCase();
       
        let unitsMeasurementItem = unitsMeasurementItems.find(x => x.customFields[unitsMeasurementNameField.id].trim().toLowerCase() === unitMeasureText);
        if(!unitsMeasurementItem) {
            const newItem = {
                customFields: {}
            };
            newItem.customFields[unitsMeasurementNameField.id] = { stringValue: unitMeasureTextOrig };
            unitsMeasurementItem = (await http.post(`directories/${unitsMeasurementDirectory.id}/items`, newItem)).data;
            unitsMeasurementItems = [...unitsMeasurementItems, unitsMeasurementItem];
        } 
       
        let productSourceItem = productSourceItems.find(x => x.customFields[productSourceNameField.id].trim().toLowerCase() === productSourceText);
        if(!productSourceItem) {
            const newItem = {
                customFields: {}
            };
            newItem.customFields[productSourceNameField.id] = { stringValue: productSourceTextOrig };
            productSourceItem = (await http.post(`directories/${productSourceDirectory.id}/items`, newItem)).data;
            productSourceItems = [...productSourceItems, productSourceItem];
        } 
       
        let productItem = productItems.find(x => (x.customFields[productArticulField.id] || '').trim().toLowerCase() === articulText);
        if(!productItem) {
            const newItem = {
                customFields: {}
            };
            newItem.customFields[productArticulField.id] = { stringValue: articulTextOrig };
            newItem.customFields[productNameField.id] = { stringValue: productTextOrig };
            newItem.customFields[productMeasureUnitField.id] = { stringValue: unitsMeasurementItem.id };
            newItem.customFields[productSourceField.id] = { stringValue: productSourceItem.id };
            productItem = (await http.post(`directories/${productDirectory.id}/items`, newItem)).data;
            productItems = [...productItems, productItem];
        } 

       
        const o = {};
        o[productsTableQtyField.id] = qty;
        o[productsTablePriceField.id] = price;
        o[productsTableSumField.id] = sum;
        o[productsTableProductField.id] = productItem;
        res.push(o);
    }
    obj.fields.Products = [...(obj.fields.Products || []), ...(res || [])];
}

async function loadAllDirectoryItems(dir) {
    const resp = await http.post(`directories/${dir.id}/items/search`, {
        skip:0, 
        take: 100000,
        orderBy:{
            field: 'number',
            asc: true
        }
    });
    return resp.data.items;
}
